import React from "react";
import { Route, Routes } from "react-router-dom";
import QuotationsDashboard from "../../pages/Quotations";
import ConvertPolicy from "../../pages/Quotations/ConvertPolicy";
import CreateQuotation from "../../pages/Quotations/Create";
import EditQuotation from "../../pages/Quotations/Edit";
import NewQuotation from "../../pages/Quotations/New";
import ViewQuotation from "../../pages/Quotations/View";

const QuotationRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={ <QuotationsDashboard /> } />
      <Route path="/create" element={ <CreateQuotation /> } />
      <Route path="/new" element={ <NewQuotation /> } />
      <Route path="/:id" element={ <ViewQuotation /> } />
      <Route path="/:id/edit" element={ <EditQuotation /> } />
      <Route path="/:id/convert-to-policy" element={ <ConvertPolicy/> } />
    </Routes>
  );
};

export default QuotationRoutes;
