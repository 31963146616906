import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import s from "./index.module.scss";
import InputLayout from "../Layout";

const SearchInput = ({ label = "", placeholder="", inline = true, readOnly = false, value="" , setValue=()=>{}, searchList = [] }) => {
  const [filteredList, setFilteredList] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [showList, setShowList] = useState(false);
  
  useEffect(()=>{
    const filter = searchList.filter((item)=>{
      return (
        item?.code?.toLowerCase().includes(filterInput.toLowerCase())) 
            || 
          item?.name?.toLowerCase().includes(filterInput.toLowerCase()
          );
    });
    setFilteredList(filter);
  }, [filterInput]);

  return (
    <InputLayout inline = { inline } className={ s.search_input } label={ label }>
      <div>
        <div className="d-flex align-items-center flex-wrap">
          <input 
            type="search" 
            name={ label } 
            id={ label } 
            placeholder={ placeholder } 
            readOnly = { readOnly }
            value = { filterInput }
            onChange = { e => {
              setFilterInput(e.target.value);
              setShowList(true);
            } }
          />
          <span>
            <BiSearch/>
          </span>
        </div>
        {
          filterInput && <div className={ `w-100 ${s.filtered_list} ${ showList ? s.show_list : s.hide_list }` }>
            <ul>
              { 
                filteredList.length === 0 ? 
                  <li className={ s.no_items }>
                    No items match your search
                  </li>
                  :
                  filteredList?.map((item)=>{
                    return (
                      <li 
                        key={ item?.id }
                        onClick={ ()=>{
                          setShowList(false);
                          setValue(item?.id.toString());
                          setFilterInput(item?.name);
                        } }
                        role="presentation"
                      >
                        { item?.name }
                      </li>
                    );
                  })
              }
            </ul>
          </div>

        }
      </div>
    </InputLayout>
  );
};

export default SearchInput;
