import React from "react";
import InputLayout from "../Layout";

const RadioInput = ({ options = [], label = "", placeholder="", inline = true, readOnly = false }) => {
  return (
    <InputLayout inline={ inline } label={ label }>
      <div className="d-flex">
        {
          options.map((option, idx)=>{
            return (
              <div key={ idx } className="me-3 d-flex">
                <label htmlFor={ option.key } className="pe-2">{ option.value }</label>
                <input 
                  type="radio" 
                  id={ option.key }
                  name={ label }
                  value={ option.value }
                  readOnly={ readOnly }
                />
              </div>
            );
          })
        }

      </div>
    </InputLayout>
  );
};

export default RadioInput;
