import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import s from "./index.module.scss";
import { tabs, team } from "./utils/data";
import Button from "../../../components/Button";
import SearchInput from "../../../components/Inputs/SearchInput";
import TextInput from "../../../components/Inputs/TextInput";
import PageSubNav from "../../../components/PageSubNav";
import PageTitle from "../../../components/PageTitle";
import TableComponent from "../../../components/Table";
import ReactTable from "../../../components/TableComponent";

const TeamPage = () => {
  const [create, setCreate] = useState(false);
  const [type] = useQueryParam("type", StringParam);

  useEffect(()=>{
    if(type === "all" || type === undefined){
      setCreate(false);
    }else{
      setCreate(true);
    }
  }, [type]);

  return (
    <div className="body-two">
      <div className="mb-4">
        <PageTitle title="Team"/>
      </div>

      <div className="mb-4">
        <PageSubNav list={ tabs.map(item => ({ label: item.tabTitle, value: item.tab })) }/>
      </div>

      { !create && <div>
        { /* TABLE FILTER */ }
        <div className="mb-4 row align-items-end justify-content-between">
          <div className="col-4">
            <SearchInput placeholder="Search" inline={ false }/>
          </div>
        </div>
        <ReactTable columns={ team.headings } data={ team.body } newTab={ false } edit={ true }/>
      </div> }

      {
        create && <div className="pt-4">
          <form action="">
            <div className="mb-2">
              <TextInput type="text" label="full name" placeholder="Please enter" inline={ true }/>
            </div>
            <div className="mb-2">
              <TextInput type="email" label="full name" placeholder="Please enter" inline={ true }/>
            </div>
            <div className="mb-2">
              <SearchInput label="manager" placeholder="Please Select" inline={ true }/>
            </div>
            <div className="pt-3">
              <Button text="Create Team Member" type="submit" className="btn-primary ms-auto"/>
            </div>
          </form>
        </div>
      }
    </div>
  );
};

export default TeamPage;
