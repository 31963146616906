import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "./Header";
import MainMenu from "./MainMenu";
import PageBody from "./PageBody";

const MemberLayout = ({ children }) => {
  return (
    <div className={ "d-none d-lg-block" }>
      <Header />
      <MainMenu />
      <PageBody>
        { children }
      </PageBody>
    </div>
  );
};

export default MemberLayout;
