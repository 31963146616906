import React, { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsCheck2Circle } from "react-icons/bs";
import { FiAlertTriangle, FiUserPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import s from "./index.module.scss";
import Button from "../../../components/Button";
import AttachmentInput from "../../../components/Inputs/AttachmentInput";
import DateInput from "../../../components/Inputs/DateInput";
import SearchInput from "../../../components/Inputs/SearchInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import ModalComponent from "../../../components/Modal";
import PageSubTitle from "../../../components/PageSubTitle";

const ViewEndorsement = () => {
  const [draft, setDraft] = useState(true);
  const [shared, setShared] = useState(false);
  const [modalApproved, setModalApproved] = useState(false);
  const [modalApprovedMessage, setModalApprovedMessage] = useState(false);
  const [modalClaimShared, setModalClaimShared] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [settled, setSettled] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [sendToClient, setSendToClient] = useState(false);
  const [confirmSend, setConfirmSend] = useState(false);
  const [sentToClient, setSentToClient] = useState(false);
  const [markSettled, setMarkSettled] = useState(false);
  const [settledConfirm, setSettledConfirm] = useState(false);
  const navigate = useNavigate();

  const closePopup = () => {
    setModalShow(false);
    setModalClaimShared(false);
    setModalApproved(false);
    setModalApprovedMessage(false);
  };

  const sendToIsurer = (e) => {
    e.preventDefault();
    setModalShow(false);
    setModalClaimShared(true);
    setShared(true);
  };

  const markAsApproved = (e) => {
    e.preventDefault();
    setModalApproved(false);
    setModalApprovedMessage(true);
  };

  const handleSendToClient = (e) => {
    e.preventDefault();
    setSendToClient(false);
    setConfirmSend(true);
  };

  const handleMarkSettled  = (e)=>{
    e.preventDefault();
    setMarkSettled(false);
    setSettledConfirm(true);
  };

  const options = [
    {
      key: "",
      value: "Please select"
    },
    {
      key: "one",
      value: "One"
    },
    {
      key: "two",
      value: "Two"
    }
  ];

  return (
    <>
      <div className="ps-4 pe-4 pb-4">
        <div className="mb-4 mt-4 d-flex justify-content-between align-items-center">
          { !draft && <PageSubTitle title="Create endorsement request"/> }
          { draft && <PageSubTitle title={ `Endorsement Request: ${123456789}` }/> }

          { draft && !shared && <p className="mb-0 pending">New</p> }
          { draft && shared && !settled && !confirmed &&<p className="mb-0 sent">sent to insurer</p> }
          { draft && shared && !settled && confirmed && <p className="mb-0 sent">confirmed</p> }
          { settled && <p className="mb-0 received">settled</p> }

          <div className="d-flex align-items-center">
            { !settled && <Button text="back"  icon="back" className="btn-secondary me-2" handler={ ()=>navigate(-1) }/> }
            { 
              draft && !settled && 
                <Button
                  text="edit"
                  icon="edit"
                  className="btn-secondary me-2"
                  handler={ ()=>navigate(`/endorsements/${"yourIDGoesHere"}/edit`) }
                /> 
            }
            { 
              draft && !shared && !settled && 
                <Button 
                  text="send to insurer"  
                  icon="edit" 
                  className="btn-secondary me-2" 
                  handler={ ()=>setModalShow(true) }
                /> 
            }
            { 
              draft && shared && !settled && !confirmed && !confirmSend &&
                <Button 
                  text="confirm endorsement"  
                  icon="check" 
                  className="btn-primary" 
                  handler={ ()=>setModalApproved(true) }
                /> 
            }
            { 
              draft && shared && !settled && confirmed && !sentToClient &&
                <Button 
                  text="send to client"  
                  icon="check" 
                  className="btn-primary" 
                  handler={ ()=>setSendToClient(true) }
                /> 
            }
            { 
              draft && shared && !settled && confirmed && sentToClient &&
                <Button 
                  text="Mark as settled"  
                  icon="check" 
                  className="btn-primary" 
                  handler={ ()=>setMarkSettled(true) }
                /> 
            }
            { 
              settled && 
                <Button 
                  text="settled"  
                  icon="check-circ" 
                  className="btn-success" 
                  disabled={ true }
                /> 
            }
          </div>
        </div>
        <div className="pt-4">
          <div>
            <div className="mb-2">
              <TextInput label="reference number" inline={ true } type="text" readOnly={ draft }/>
            </div>
            <div className="mb-2">
              <SearchInput label="policy number" placeholder="Please select" readOnly={ draft }/>
            </div>
            <div className="mb-2">
              <SearchInput label="insured" placeholder="Please select" readOnly={ draft }/>
            </div>
            <div className="mb-2">
              <SelectInput label="insurer" options={ options } readOnly={ draft }/>
            </div>
            <div className="mb-2">
              <SelectInput label="main class" options={ options } readOnly={ draft }/>
            </div>
            <div className="mb-2">
              <SelectInput label="product" options={ options } readOnly={ draft }/>
            </div>
            <div className="mb-2">
              <DateInput label="request date" placeholder="Please select" readOnly={ draft }/>
            </div>
            <div className="mb-2">
              <TextInput type="textarea" label="remarks" placeholder="Enter remarks" readOnly={ draft }/>
            </div>
          </div>
          <div className={ `${s.seperator} container mt-4 mb-4` }>
          </div>
          <div>
            <div className="row justify-content-between align-items-center mb-4">
              <p className="mb-0 col-3 label">
                period of insurance
              </p>
              <div className="col-1">:</div>
              <div className="d-flex justify-content-between align-items-center col-8">
                <DateInput inline={ false } label="Start Date" noLabel={ true } placeholder="Start Date" readOnly={ draft }/>
                <DateInput inline={ false } label="Start Date" noLabel={ true } placeholder="End Date" readOnly={ draft }/>
                <TextInput type="checkbox" inline={ false } label="Both days inclusive" readOnly={ draft }/>
              </div>
            </div>
            <div className="mb-2">
              <DateInput placeholder="DD-MM-YYY" label="Effective date" readOnly={ draft }/>
            </div>
            <div className="mb-2">
              <SelectInput label="endorsement type" options={ options } readOnly={ draft }/>
            </div>
            <div className="mb-2">
              <AttachmentInput label="endorsement request letter (request letter to insurer)" readOnly={ draft }/>
            </div>
            <div className="mb-2">
              <AttachmentInput label="supporting documents (Other supporting documents)" readOnly={ draft }/>
            </div>
          </div>
          <div className="pt-2">
            <Button text="save"  icon="save" className="btn-primary ms-auto mt-4"/>
          </div>
        </div>
      </div>

      { /* MODALS */ }
      <>
        { /* MODAL INSURER */ }
        <Insurer show={ modalShow } onHide={ closePopup } handle={ sendToIsurer }/>

        { /* MODAL CLAIM SHARED */ }
        <ClaimShared 
          show={ modalClaimShared } 
          onHide={ closePopup } 
          title="Endorsement Request shared successfully" 
          subTitle={ `Endorsement request has been shared successfully with ${"HNB General"}` } 
          handle={ () => {
            closePopup();
          } }
        />
      
        { /* MODAL CLAIM SHARED */ }
        <Approved show={ modalApproved } onHide={ closePopup } handle={ markAsApproved }/>

        { /* MODAL CLAIM Approved */ }
        <ClaimShared 
          show={ modalApprovedMessage } 
          onHide={ closePopup } 
          title="Endorsement confirmed successfully" 
          handle = { ()=>{
            setModalApprovedMessage(false);
            setConfirmed(true);
          } }
        />

        { /* MODAL SEND TO CLIENT */ }
        <SendToClient show={ sendToClient } onHide={ () => setSendToClient(false) } handle={ handleSendToClient }/>

        { /* MODAL CLAIM Approved */ }
        <ClaimShared 
          show={ confirmSend } 
          onHide={ () => setConfirmSend(false) } 
          title={ `Endorsement sent to ${"HNB General"} successfully` } 
          subTitle={ `Claim request has been shared successfully with ${"HNB General"}` } 
          handle = { ()=>{
            setConfirmSend(false);
            setSentToClient(true);
          } }
        />
        
        { /* MODAL MARK AS SETTLED */ }
        <MarkSettled show={ markSettled } onHide={ () => setMarkSettled(false) } handle={ handleMarkSettled }/>

        { /* MODAL CLAIM Approved */ }
        <ClaimShared 
          show={ settledConfirm } 
          onHide={ () => setSettledConfirm(false) } 
          title={ "Endorsement successfully settled" } 
          subTitle={ "" } 
          handle = { ()=>{
            setSettledConfirm(false);
            setSettled(true);
          } }
        />
      </>
    </>
  );
};

const Insurer = ({
  show,
  onHide,
  handle
})=>{
  const [emails, setEmails] = useState([]);
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <span className="modal-icon mb-4">
        <FiUserPlus/>
      </span>
      <div className="mb-4">
        <h4>
          { `Send endorsement request to ${"HNB General"}` }
        </h4>
        <p>
          Please add the email address below to share
        </p>
      </div>
      <form action="" method="post" onSubmit={ (e) => handle(e) }>
        <div className="mb-4">
          <TextInput type = "email" label = "Email addresses" placeholder="you@untitledui.com" inline={ false }/>
          <div className="my-2">
            <TextInput type = "email" label = "Email addresses" placeholder="you@untitledui.com" inline={ false } noLabel = { true }/>
          </div>
          {
            emails.map((email , idx)=>{
              return <div className="my-2" key={ idx }> { email } </div>; 
            })
          }
          <p 
            role="presentation"
            onClick={ ()=>setEmails([...emails, <TextInput key="" type = "email" label = "Email addresses" placeholder="you@untitledui.com" inline={ false } noLabel = { true }/>]) }
            className="add-email"
          >
            + Add another email address
          </p>
        </div>
        <div className="mb-4">
          <DateInput label = "Deadline to process endorsement" inline={ false } placeholder="Please select deadline"/>
        </div>
        <div className="mb-4">
          <TextInput 
            label = "Message" 
            type="richtext"  
            inline={ false } 
            rows={ 4 } 
            placeholder="Please enter your message"
          />
        </div>
        <div className="d-flex justify-content-between">
          <Button 
            handler={ onHide } 
            text="cancel" 
            className="btn-secondary me-2 w-100 pt-3 pb-3"
          />
          <Button 
            text="Send" 
            className="btn-primary w-100 pt-3 pb-3"
            type="submit"
          />
        </div>
      </form>
    </ModalComponent>
  );
};

const ClaimShared = ({ show, onHide, title = "", subTitle = "", handle = () => {} })=>{
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div className="confirmation-popup">
        <span className="modal-icon success mb-4">
          <BsCheck2Circle/>
        </span>
        <div className="mb-4">
          <h4>
            { title }
          </h4>
          <p>
            { subTitle }
          </p>
        </div>
        <Button 
          text="ok" 
          className="btn-primary w-100 pt-3 pb-3"
          handler={ handle }
        />
      </div>
    </ModalComponent>
  );
};

const Approved = ({ show, onHide, handle }) => {
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <span className={ "modal-icon mb-4" }>
        <AiOutlineCheck/>
      </span>
      <div className="mb-4">
        <h4>
          Confirm Endorsement
        </h4>
        <p>
          Please enter the information below to confirm the endorsement
        </p>
      </div>
      <form action="" method="post" onSubmit={ (e)=>handle(e) }>
        <div className="mb-4">
          <TextInput type = "number" label = "Approved Amount (LKR)" placeholder="220,000.00" inline={ false }/>
        </div>
        <div className="mb-4">
          <DateInput label = "Approved Date" inline={ false } placeholder="DD-MM-YYYY"/>
        </div>
        <div className="mb-4">
          <AttachmentInput label = "Claim Approval Letter" inline={ false }/>
        </div>
        <div className="d-flex justify-content-between">
          <Button 
            handler={ onHide } 
            text="cancel" 
            className="btn-secondary me-2 w-100 pt-3 pb-3"
          />
          <Button 
            text="Mark as Approved"
            className="btn-primary w-100 pt-3 pb-3"
            type="submit"
          />
        </div>
      </form>
    </ModalComponent>
  );
};

const SendToClient = ({
  show,
  onHide,
  handle
})=>{
  const [emails, setEmails] = useState([]);
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <span className="modal-icon mb-4">
        <FiUserPlus/>
      </span>
      <div className="mb-4">
        <h4>
          { `Send endorsement confirmation to ${"HNB General"}` }
        </h4>
        <p>
          Please add the email address below to share
        </p>
      </div>
      <form action="" method="post" onSubmit={ (e) => handle(e) }>
        <div className="mb-4">
          <TextInput type = "email" label = "Email addresses" placeholder="you@untitledui.com" inline={ false }/>
          <div className="my-2">
            <TextInput type = "email" label = "Email addresses" placeholder="you@untitledui.com" inline={ false } noLabel = { true }/>
          </div>
          {
            emails.map((email , idx)=>{
              return <div className="my-2" key={ idx }> { email } </div>; 
            })
          }
          <p 
            role="presentation"
            onClick={ ()=>setEmails([...emails, <TextInput key="" type = "email" label = "Email addresses" placeholder="you@untitledui.com" inline={ false } noLabel = { true }/>]) }
            className="add-email"
          >
            + Add another email address
          </p>
        </div>
        <div className="mb-4">
          <TextInput 
            label = "Message" 
            type="richtext"  
            inline={ false } 
            rows={ 4 } 
            placeholder="Please enter your message"
          />
        </div>
        <div className="d-flex justify-content-between">
          <Button 
            handler={ onHide } 
            text="cancel" 
            className="btn-secondary me-2 w-100 pt-3 pb-3"
          />
          <Button 
            text="Send" 
            className="btn-primary w-100 pt-3 pb-3"
            type="submit"
          />
        </div>
      </form>
    </ModalComponent>
  );
};

const MarkSettled = ({
  show,
  onHide,
  handle
})=>{
  const [emails, setEmails] = useState([]);
  const options = [
    {
      key: "",
      value: "Please Select"
    },
    {
      key: "One",
      value: "One"
    }
  ]; 
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <span className="modal-icon mb-4">
        <FiUserPlus/>
      </span>
      <div className="mb-4">
        <h4>
          { `Send endorsement confirmation to ${"HNB General"}` }
        </h4>
        <p>
          Please add the email address below to share
        </p>
      </div>
      <form action="" method="post" onSubmit={ (e) => handle(e) }>
        <div className="mb-4">
          <div className="my-2">
            <DateInput  label = "Email addresses" placeholder="DD-MM-YYYY" inline={ false }/>
          </div>
          <div className="my-2">
            <SelectInput options={ options } label = "Payment Method" placeholder="Please Select" inline={ false }/>
          </div>
          <div className="my-2">
            <TextInput type = "text" label = "Payment reference" placeholder="Please enter payment reference" inline={ false }/>
          </div>
          <div className="my-2">
            <AttachmentInput label="Supporting documents (Optional)" inline={ false }/>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <Button 
            handler={ onHide } 
            text="cancel" 
            className="btn-secondary me-2 w-100 pt-3 pb-3"
          />
          <Button 
            text="Send" 
            className="btn-primary w-100 pt-3 pb-3"
            type="submit"
          />
        </div>
      </form>
    </ModalComponent>
  );
};

export default ViewEndorsement;
