import React from "react";
import s from "./index.module.scss";

const PageSubTitle = ({ title = "" }) => {
  return (
    <div>
      <h2 className={ s.page_title }>{ title }</h2>
    </div>
  );
};

export default PageSubTitle;
