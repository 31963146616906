import TextInput from "../../../../components/Inputs/TextInput";

let total = "220,000"; 

export const premium = {
  title: "PREMIUM",
  total: total,
  headings: [
    {
      Header: "Blank",
      accessor: "type"
    },
    {
      Header: "Premium %",
      accessor: "premium"
    },
    {
      Header: <div className="text-center">
        Premium (LKR)
      </div>,
      accessor: "premiumLkr"
    }
  ],
  body: [
    {
      type: "Basic Premium", 
      premium :"95%",
      premiumLkr: <div className="table-input">
        <TextInput label="Basic Premium" inline={ false } noLabel={ true } readOnly={ false } value={ "190, 000" }/>
      </div> 
    },
    {
      type: "Riot and Strike Premium", 
      premium :"3%",
      premiumLkr: <div className="table-input">
        <TextInput label="Riot and Strike Premium" inline={ false } noLabel={ true } readOnly={ false } value={ "6, 000" }/>
      </div> 
    },
    {
      type: "Terrorism Premium", 
      premium :"2%",
      premiumLkr: <div className="table-input">
        <TextInput label="Terrorism Premium" inline={ false } noLabel={ true } readOnly={ false } value={ "4, 000" }/>
      </div> 
    },
    {
      type: "Total premium before Tax/Surcharges", 
      premium :"",
      premiumLkr: <div className="table-input">
        <TextInput label="Total premium before Tax/Surcharges" inline={ false } noLabel={ true } readOnly={ false } value={ "200, 000" }/>
      </div> 
    },
    {
      type: "Total of Taxes/Surcharges", 
      premium :"10%",
      premiumLkr: <div className="table-input">
        <TextInput label="Total of Taxes/Surcharges" inline={ false } noLabel={ true } readOnly={ false } value={ "20, 000" }/>
      </div> 
    },
    {
      type: <div className="fw-bold">
        Total premium after Taxes/Surcharges
      </div>,
      premium :"",
      premiumLkr: <div className="fw-bold text-center">
        { total }
      </div> 
    }
  ]
};

// export const premium = {
//   title: "PREMIUM",
//   total: total,
//   headings: [
//     {
//       Header: "Blank",
//       accessor: "type"
//     },
//     {
//       Header: "Premium %",
//       accessor: "premium"
//     },
//     {
//       Header: "Premium (LKR)",
//       accessor: "premiumLkr"
//     }
//   ],
//   body: [
//     {
//       type: "Basic Premium", 
//       premium :"",
//       premiumLkr: "190,000"
//     },
//     {
//       type: "Riot and Strike Premium", 
//       premium :"",
//       premiumLkr: "6,000"
//     },
//     {
//       type: "Terrorism Premium", 
//       premium :"",
//       premiumLkr: "4,000"
//     },
//     {
//       type: "Total premium before Tax/Surcharges", 
//       premium :"",
//       premiumLkr: "200,000"
//     },
//     {
//       type: "Total of Taxes/Surcharges", 
//       premium :"",
//       premiumLkr: "20,000"
//     },
//     {
//       type: <div className="fw-bold">
//         Total premium after Taxes/Surcharges
//       </div>,
//       premium :"",
//       premiumLkr: <div className="fw-bold">
//         { total }
//       </div> 
//     }
//   ]
// }
