import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import SelectInput from "../../../components/Inputs/SelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import PageSubNav from "../../../components/PageSubNav";
import PageSubTitle from "../../../components/PageSubTitle";
import PageTitle from "../../../components/PageTitle";
import { useAuth } from "../../../contexts/Auth";
import { tabs } from "../List/utils/data";

const NewProduct = () => {
  const navigate = useNavigate();
  const [mainClass, setMainClass] = useState("");
  const [mainClassId, setMainClassId] = useState(1);
  const [subClassPlaceholder, setSubClassPlaceholder] = useState("");
  const [productName, setProductName] = useState("");
  const [subClass, setSubClass] = useState("");
  const [subClassData, setSubClassData] = useState([]);

  const mainData = useQuery({
    queryFn: () =>
      api("product/main-class"),
    queryKey: ["productsMainClass"]
  });

  // const subClassData = useQuery({
  //   queryFn: () =>
  //     api(`product/sub-class-by-main-class?mainClassId=${mainClassId}`),
  //   queryKey: ["productsSubClass"]
  // });

  const { api } = useAuth();

  useEffect(()=>{
    const getSubClass = async (mainClass) =>{
      const res = await api(`product/sub-class-by-main-class?mainClassId=${mainClass}`);
      console.log(...res);
      setSubClassData(res || []);
    };
    if(mainClass === ""){
      setSubClassPlaceholder("Please select main class");
      getSubClass(1);
    }else{
      setSubClassPlaceholder("Please select");
      getSubClass(mainClass);
    }
  },[mainClass]);

  const handleSubmit = (e) => {
    e.preventDefault();
    api("product/create", 
      { 
        main_class_id : parseInt(mainClass),
        fk_sub_class_id : parseInt(subClass),
        product_name : productName,
        description : "Lorem ipsum",
        created_user : "0"
      }, 
      "POST");
  };


  return(
    <section className="body">
      <div className="mb-4">
        <PageTitle title="Products"/>
      </div>
      <div className="mb-4">
        <PageSubNav list={ tabs.map(item => ({ label: item.tabTitle, value: item.tab })) }/>
      </div>
      <form className="form" onSubmit={ (e)=>{handleSubmit(e);} }>
        <div className="d-flex justify-content-between align-items-end mb-5">
          <div>
            <PageSubTitle title="Create new product"/>
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <Button icon="back" text="Back" className="btn-secondary me-2" handler={ ()=>navigate(-1) }/>
            <Button type="submit" icon="save" text="Save" className="btn-primary"/>
          </div>
        </div>
        <div className="mb-2">
          <SelectInput 
            label="Main class" 
            options={ [
              { key: "", value: "Please select" }, 
              ...get(mainData, "data", []).map(({ main_class_id: key, main_class_name: value })=>({ key, value }))
            ] } 
            inline={ true }
            value={ mainClass } 
            setValue={ setMainClass }
            setState = { setMainClassId }
          />
        </div>
        <div className="mb-2">
          <TextInput 
            label="Product name" 
            placeholder="Please enter" 
            inline={ true } 
            value={ productName } 
            setValue={ setProductName }
          />
        </div>
        <div>
          <SelectInput 
            label="Sub class" 
            options={ [
              { key: "", value: subClassPlaceholder },
              ...subClassData
            ] } 
            inline={ true }
            value={ subClass } 
            setValue={ setSubClass }
            readOnly={ mainClass === "" ? true : false }
          />
        </div>
      </form>
    </section>
  );
};

export default NewProduct;
