import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import s from "./index.module.scss";
import Button from "../../../components/Button";
import AttachmentInput from "../../../components/Inputs/AttachmentInput";
import DateInput from "../../../components/Inputs/DateInput";
import SearchInput from "../../../components/Inputs/SearchInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import PageSubTitle from "../../../components/PageSubTitle";

const EditEndorsement = () => {
  const [edit, setEdit] = useState(true);
  const navigate = useNavigate();

  const options = [
    {
      key: "",
      value: "Please select"
    },
    {
      key: "one",
      value: "One"
    },
    {
      key: "two",
      value: "Two"
    }
  ];



  return (
    <>
      <div className="ps-4 pe-4 pb-4">
        <div className="mb-4 mt-4 d-flex justify-content-between align-items-center">
          <PageSubTitle title="Create endorsement request"/>

          <div className="d-flex align-items-center">
            <Button text="back"  icon="back" className="btn-secondary me-2" handler={ ()=>navigate(-1) }/>
            <Button text="save"  icon="save" className="btn-primary"/>
          </div>
        </div>
        <div className="pt-4">
          <div>
            <div className="mb-2">
              <TextInput label="reference number" inline={ true } type="text" readOnly={ edit }/>
            </div>
            <div className="mb-2">
              <SearchInput label="policy number" placeholder="Please select"/>
            </div>
            <div className="mb-2">
              <SearchInput label="insured" placeholder="Please select"/>
            </div>
            <div className="mb-2">
              <SelectInput label="insurer" options={ options }/>
            </div>
            <div className="mb-2">
              <SelectInput label="main class" options={ options }/>
            </div>
            <div className="mb-2">
              <SelectInput label="product" options={ options }/>
            </div>
            <div className="mb-2">
              <DateInput label="request date" placeholder="Please select"/>
            </div>
            <div className="mb-2">
              <TextInput type="textarea" label="remarks" placeholder="Enter remarks"/>
            </div>
          </div>
          <div className={ `${s.seperator} container mt-4 mb-4` }>
          </div>
          <div>
            <div className="row justify-content-between align-items-center mb-4">
              <p className="mb-0 col-3 label">
                period of insurance
              </p>
              <div className="col-1">:</div>
              <div className="d-flex justify-content-between align-items-center col-8">
                <DateInput inline={ false } label="Start Date" noLabel={ true } placeholder="Start Date"/>
                <DateInput inline={ false } label="Start Date" noLabel={ true } placeholder="End Date"/>
                <TextInput type="checkbox" inline={ false } label="Both days inclusive"/>
              </div>
            </div>
            <div className="mb-2">
              <DateInput placeholder="DD-MM-YYY" label="Effective date"/>
            </div>
            <div className="mb-2">
              <SelectInput label="endorsement type" options={ options }/>
            </div>
            <div className="mb-2">
              <AttachmentInput label="endorsement request letter (request letter to insurer)"/>
            </div>
            <div className="mb-2">
              <AttachmentInput label="supporting documents (Other supporting documents)"/>
            </div>
          </div>
          <div className="pt-2">
            <Button text="save"  icon="save" className="btn-primary ms-auto mt-4"/>
          </div>
        </div>
      </div>
    </>
  );
};


export default EditEndorsement;
