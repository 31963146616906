import React, { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsCheck2Circle } from "react-icons/bs";
import { FiAlertTriangle, FiUserPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import s from "./index.module.scss";
import Button from "../../../components/Button";
import AttachmentInput from "../../../components/Inputs/AttachmentInput";
import DateInput from "../../../components/Inputs/DateInput";
import InputLayout from "../../../components/Inputs/Layout";
import SearchInput from "../../../components/Inputs/SearchInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import ModalComponent from "../../../components/Modal";
import PageSubTitle from "../../../components/PageSubTitle";
import PageTitle from "../../../components/PageTitle";
import { useAuth } from "../../../contexts/Auth";

const ViewClaim = () => {
  const [saved, setSaved] = useState(true);
  const [claimRef] = useState("C2022122000005");
  const [shared, setShared] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [modalApproved, setModalApproved] = useState(false);
  const [modalApprovedMessage, setModalApprovedMessage] = useState(false);
  const [modalClaimShared, setModalClaimShared] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalRejectShow, setModalRejectShow] = useState(false);
  const [settled, setSettled] = useState(false);
  const [modalSettled, setModalSettled] = useState(false);
  const [approved, setApproved] = useState(false);
  const [modalSettledSuccess, setModalSettledSuccess] = useState(false);

  // SEND TO INSURER
  const [emailsPassed, setEmailsPassed] = useState({});
  const [deadline, setDeadline] = useState("");
  const [message, setMessage] = useState("");

  // MARK AS APPROVED
  const [finalDoc, setFinalDoc] = useState({});
  const [approvedAmount, setApprovedAmount] = useState("");
  const [approvedDate, setApprovedDate] = useState("");

  // MARK AS SETTLED
  const [supportingDoc, setSupportingDoc] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paidDate, setPaidDate] = useState("");
  const [paymentReference, setPaymentReference] = useState("");

  const navigate = useNavigate();
  const emails = [];

  const { api, docApi } = useAuth();

  const closePopup = () => {
    setModalShow(false);
    setModalRejectShow(false);
    setModalClaimShared(false);
    setModalApproved(false);
    setModalApprovedMessage(false);
  };

  const sendToIsurer = async(e) => {
    e.preventDefault();
    Object.values(emailsPassed).map((email)=>{
      emails.push(email);
    });
    const sendToInsurer = await api(
      "claim/sent-to-insurer", 
      { to: emails, claimReferenceNumber: claimRef, deadline, message  }, 
      "POST"
    );

    if (sendToInsurer) {
      setModalShow(false);
      setModalClaimShared(true);
    }
  };

  const markAsApproved = async() => {
    const approve = await docApi(
      "claim/approval",
      {
        file: finalDoc,
        docId: 6,
        claimReferenceNumber: claimRef,
        approveAmount: approvedAmount,
        approveDate: approvedDate
      },
      "POST"
    );
    if(approve){
      setModalApproved(false);
      setModalApprovedMessage(true);
    }
  };

  const handleReject = (e) => {
    e.preventDefault();
    setRejected(true);
    setModalRejectShow(false);
  };

  const handleSettle = async()=>{
    const settle = await docApi(
      "claim/settle",
      {
        file: supportingDoc,
        docId: 6,
        claimReferenceNumber: claimRef,
        paymentMethod,
        paidDate,
        paymentReference
      },
      "POST"
    );
    if (settle) {
      setModalSettledSuccess(true);
      setModalSettled(false);
    }
  }; 

  const options = [
    {
      key: "",
      value: "Please select"
    },
    {
      key: "one",
      value: "One"
    },
    {
      key: "two",
      value: "Two"
    }
  ];
  
  const team = [
    {
      key: "",
      value: "Select Assignee"
    },
    {
      key: "John Smith",
      value: "John Smith"
    },
    {
      key: "Duneeka",
      value: "Duneeka"
    }
  ];
  
  const status = [
    {
      key: "",
      value: "Status"
    },
    {
      key: "Sent to Insurer",
      value: "Sent to Insurer"
    },
    {
      key: "Approved",
      value: "Approved"
    }
  ];

  return (
    <>
      <div className="ps-4 pe-4 pb-4">
        {
          saved && <div className="mb-4">
            <div className="d-flex justify-content-between align-items-center pb-4 pt-2">
              <div>
                <PageTitle title={ `Claim Request: ${"REF1234567890"}` }/>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <Button text="back" icon="back" handler={ ()=>navigate(-1) }/>
                <p className="mb-0 mx-4">
                  { `Last updated on: ${"2022-03-23 10:45 AM"}` }
                </p>
              
                <SelectInput inline={ false } options={ status }/>
              </div>
            </div>
            <hr/>
          </div>
        }
        <div className="mb-4 mt-4 d-flex justify-content-between align-items-center">
          { 
            saved && <div className="desc me-4">
              <p className="mb-2">Created by: Duneeka</p>
              <p>Created at: 12-09-2022 at 10:00 AM</p>
            </div> 
          }
          {
            saved && <SelectInput label="Assignee" inline={ false } options={ team }/>
          }
          { !saved && <PageSubTitle title="Create claim request"/> }
          { saved && !shared && !rejected && <p className="mb-0 pending">New</p> }
          { saved && shared && !rejected && !settled && <p className="mb-0 sent">sent to insurer</p> }
          { saved && rejected && !settled && <p className="mb-0 rejected">rejected</p> }
          { settled && <p className="mb-0 received">settled</p> }
          <ButtonContainer 
            saved = { saved }
            rejected = { rejected }
            settled = { settled }
            shared = { shared }
            approved = { approved }
            setModalShow = { setModalShow }
            setModalRejectShow = { setModalRejectShow }
            setModalApproved = { setModalApproved }
            setModalSettled = { setModalSettled }
            navigate = { navigate }
          />
        </div>
        
        <div className="pt-4">
          <div>
            <div className="mb-2">
              <TextInput label="reference number" inline={ true } type="text" readOnly={ saved }/>
            </div>
            <div className="mb-2">
              <SearchInput label="policy number" placeholder="Please select" readOnly={ saved }/>
            </div>
            <div className="mb-2">
              <SearchInput label="insured" placeholder="Please select" readOnly={ saved }/>
            </div>
            <div className="mb-2">
              <SelectInput label="insurer" options={ options } readOnly={ saved }/>
            </div>
            <div className="mb-2">
              <SelectInput label="main class" options={ options } readOnly={ saved }/>
            </div>
            <div className="mb-2">
              <SelectInput label="product" options={ options } readOnly={ saved }/>
            </div>
            <div className="mb-2">
              <DateInput label="claim request date" placeholder="DD-MM-YYYY" readOnly={ saved }/>
            </div>
            <div className="row justify-content-between align-items-center mb-4">
              <p className="mb-0 col-3 label">
                claim channel
              </p>
              <div className="col-1">:</div>
              <div className="d-flex justify-content-between align-items-center col-8">
                <div>
                  <input type="radio" id="App" name="Claim Channel" value="App"/>
                  <label htmlFor="App" className="ps-2">App</label>
                </div>

                <div>
                  <input type="radio" id="Email" name="Claim Channel" value="Email"/>
                  <label htmlFor="Email" className="ps-2">Email</label>
                </div>

                <div>
                  <input type="radio" id="Physical Document" name="Claim Channel" value="Physical Document"/>
                  <label htmlFor="Physical Document" className="ps-2">Physical Document</label>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <input type="radio" id="Physical Document" name="Claim Channel" value="Physical Document"/>
                  <div className="ms-2">
                    <TextInput type="text" label="Other" noLabel={ true } inline={ false } placeholder="Other (Please Type)" readOnly={ saved }/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={ `${s.seperator} container mt-4 mb-4` }>
          </div>
          <div>
            <div className="mb-2">
              <SelectInput label="claim type" options={ options } readOnly={ saved }/>
            </div>
            <div className="mb-2">
              <TextInput type="number" label="claim request amount" placeholder="Please enter" readOnly={ saved }/>
            </div>
            <div className="mb-2">
              <AttachmentInput label="endorsement request letter" readOnly={ saved }/>
            </div>
            <div className="mb-2">
              <AttachmentInput label="supporting documents" readOnly={ saved }/>
            </div>
          </div>
          <div className="pt-2">
            <Button 
              text="save" 
              icon="save" 
              className="btn-primary ms-auto mt-4" 
              handler={ ()=>setSaved(true) } 
              readOnly={ saved }
            />
          </div>
        </div>
      </div>

      { /* MODALS */ }
      <>
        { /* MODAL INSURER */ }
        <Insurer 
          show={ modalShow } 
          onHide={ closePopup } 
          handle={ sendToIsurer } 
          emailsPassed={ emailsPassed }
          setEmailsPassed={ setEmailsPassed }
          deadline = { deadline }
          setDeadline = { setDeadline }
          message = { message }
          setMessage = { setMessage }
        />

        { /* MODAL REJECT */ }
        <Reject show={ modalRejectShow } onHide={ closePopup } handle={ handleReject }/>

        { /* MODAL CLAIM SHARED */ }
        <ClaimShared 
          show={ modalClaimShared } 
          onHide={ closePopup } 
          title="Claim Request shared successfully" 
          subTitle={ `Claim request has been shared successfully with ${"HNB General"}` } 
          handle = { ()=>{
            setModalClaimShared(false);
            setShared(true);
          } }
        />

        { /* MODAL CLAIM SHARED */ }
        <Approved 
          show={ modalApproved } 
          onHide={ closePopup } 
          handle={ markAsApproved } 
          finalDoc={ finalDoc } 
          setFinalDoc={ setFinalDoc }
          approvedAmount={ approvedAmount }
          setApprovedAmount={ setApprovedAmount }
          approvedDate={ approvedDate }
          setApprovedDate={ setApprovedDate }
        />

        { /* MODAL CLAIM Approved */ }
        <ClaimShared 
          show={ modalApprovedMessage } 
          onHide={ closePopup } 
          title="Claim Request approved successfully" 
          handle = { ()=>{
            setModalApprovedMessage(false);
            setApproved(true);
          } }
        />

        { /* MODAL CLAIM SETTLE? */ }
        <ClaimSharedSettled 
          show={ modalSettled } 
          onHide={ ()=>setModalSettled(false) } 
          title="Mark Claim as settled" 
          subTitle="Please enter the information below to mark this claim as settled"
          handle = { handleSettle }
          supportingDoc = { supportingDoc } 
          setSupportingDoc = { setSupportingDoc }
          paymentMethod = { paymentMethod } 
          setPaymentMethod = { setPaymentMethod }
          paidDate = { paidDate } 
          setPaidDate = { setPaidDate }
          paymentReference = { paymentReference } 
          setPaymentReference = { setPaymentReference }
        />

        { /* MODAL CLAIM SETTLED */ }
        <ClaimShared
          show={ modalSettledSuccess } 
          onHide={ ()=>setModalSettledSuccess(false) } 
          title="Claim Settled successfully" 
          handle = { ()=>{
            setModalSettledSuccess(false);
            setSettled(true);
          } }
        />
      </>
    </>
  );
};

const ButtonContainer = (
  {
    saved,
    rejected,
    settled,
    shared,
    approved,
    setModalShow,
    setModalRejectShow,
    setModalApproved,
    setModalSettled,
    navigate
  }
)=>{
  return(
    <div className="d-flex align-items-center">
      { !saved && <Button text="back"  icon="back" className="btn-secondary me-2" handler={ ()=>navigate(-1) }/> }
      { 
        saved && !rejected && !settled &&
          <Button
            text="edit claim request"
            icon="edit"
            className="btn-secondary me-2"
            handler={ ()=>{
              navigate(`/claims/${"yourIDGoesHere"}/edit`);
            } }
          /> 
      }
      { 
        saved && !shared && !rejected && !settled &&
          <Button 
            text="send to insurer"  
            icon="paper-plane" 
            className="btn-secondary me-2" 
            handler={ ()=>setModalShow(true) }
          /> 
      }
      { 
        saved && !rejected && !settled && !approved &&
          <Button 
            text="reject"  
            icon="close" 
            className="btn-outline-danger" 
            handler={ ()=>setModalRejectShow(true) }
          /> 
      }
      { 
        saved && shared && !rejected && !settled && !approved &&
          <Button 
            text="mark claim as approved"  
            icon="check-circ" 
            className="btn-primary ms-2" 
            handler={ ()=>setModalApproved(true) }
          /> 
      }
      { 
        saved && shared && !rejected && !settled && approved &&
          <Button 
            text="mark claim as settled"  
            icon="check-circ" 
            className="btn-primary ms-2" 
            handler={ ()=>setModalSettled(true) }
          /> 
      }
      { 
        settled && 
          <Button 
            text="settled"  
            icon="check-circ" 
            className="btn-success" 
            disabled={ true }
          /> 
      }
    </div>
  );
};

const Insurer = ({
  show,
  onHide,
  handle,
  emailsPassed,
  setEmailsPassed,
  deadline,
  setDeadline,
  message,
  setMessage
})=>{
  const [emails, setEmails] = useState([]);

  const handleChange = (e) => {
    setEmailsPassed({
      ...emailsPassed,
      [e.target.name] : e.target.value
    });
  };

  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <span className="modal-icon mb-4">
        <FiUserPlus/>
      </span>
      <div className="mb-4">
        <h4>
          Share with insurers
        </h4>
        <p>
          Please add the email adresses below to share  
        </p>
      </div>
      <form action="" method="post" onSubmit={ (e) => handle(e) }>
        <div className="mb-4">
          <InputLayout label="Email addresses" inline={ false }>
            <input 
              type = "email" 
              name = "ea1" 
              placeholder="you@untitledui.com" 
              value={ emailsPassed["ea1"] }
              onChange={ handleChange }
              required={ true }
            />
          </InputLayout>
          <div className="my-2">
            <InputLayout inline={ false } noLabel = { true }>
              <input 
                type = "email" 
                name = "ea2" 
                placeholder="you@untitledui.com" 
                value={ emailsPassed["ea2"] }
                onChange={ handleChange }
                required={ true }
              />
            </InputLayout>
          </div>
          {
            emails.map((email , idx)=>{
              return <div className="my-2" key={ idx }> 
                <InputLayout key={ idx } noLabel = { true } inline={ false } >
                  <input 
                    type = "email" 
                    name = { `ea${idx+3}` } 
                    value={ emailsPassed[`ea${idx+3}`] }
                    onChange={ handleChange }
                    placeholder="you@untitledui.com" 
                  />
                </InputLayout> 
              </div>; 
            })
          }
          <p 
            role="presentation"
            // eslint-disable-next-line max-len
            onClick={ ()=>setEmails([
              ...emails, 
              <InputLayout key="" noLabel = { true } inline={ false } >
                <input 
                  type = "email" 
                  label = "Email addresses" 
                  placeholder="you@untitledui.com" 
                />
              </InputLayout>
            ]) }
            className="add-email"
          >
            + Add another email address
          </p>
        </div>
        <div className="mb-4">
          <DateInput 
            label = "Deadline to process quotation" 
            inline={ false } 
            placeholder="Please select deadline"
            value={ deadline }
            setValue={ setDeadline }
          />
        </div>
        <div className="mb-4">
          <TextInput 
            label = "Message" 
            type="textarea"  
            inline={ false } 
            rows={ 4 } 
            placeholder="Please enter your message"
            value={ message }
            setValue={ setMessage }
          />
        </div>
        <div className="d-flex justify-content-between">
          <Button 
            handler={ onHide } 
            text="cancel" 
            className="btn-secondary me-2 w-100 pt-3 pb-3"
          />
          <Button 
            text="Share" 
            className="btn-primary w-100 pt-3 pb-3"
            type="submit"
          />
        </div>
      </form>
    </ModalComponent>
  );
};

const Reject = ({ show, onHide, handle })=>{
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div className="confirmation-popup">
        <span className="modal-icon reject mb-4">
          <FiAlertTriangle/>
        </span>
        <div className="mb-4">
          <h4>
            Please enter reject reason
          </h4>
        </div>
        <form action="" method="post" onSubmit={ (e)=>handle(e) }>
          <div className="mb-4">
            <TextInput type = "text" placeholder="Please type reject reason" inline={ false }/>
          </div>
          <div className="d-flex justify-content-between">
            <Button 
              text="ok" 
              className="btn-primary w-100 pt-3 pb-3"
              type="submit"
            />
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};

const ClaimShared = ({ show, onHide, title = "", subTitle = "", handle=()=>{} })=>{
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div className="confirmation-popup">
        <span className="modal-icon success mb-4">
          <BsCheck2Circle/>
        </span>
        <div className="mb-4">
          <h4>
            { title }
          </h4>
          <p>
            { subTitle }
          </p>
        </div>
        <Button 
          text="ok" 
          className="btn-primary w-100 pt-3 pb-3"
          handler={ handle }
        />
      </div>
    </ModalComponent>
  );
};

const Approved = ({ 
  show, 
  onHide, 
  handle, 
  finalDoc, 
  setFinalDoc,
  approvedAmount, 
  setApprovedAmount, 
  approvedDate, 
  setApprovedDate 
}) => {
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <span className={ "modal-icon mb-4" }>
        <AiOutlineCheck/>
      </span>
      <div className="mb-4">
        <h4>
          Mark Claim as Approved
        </h4>
        <p>
          Please enter the information below to mark this claim as approved
        </p>
      </div>
      <form action="" method="post">
        <div className="mb-4">
          <TextInput 
            type = "number" 
            label = "Approved Amount (LKR)" 
            placeholder="220,000.00" 
            inline={ false }
            value={ approvedAmount }
            setValue={ setApprovedAmount }
          />
        </div>
        <div className="mb-4">
          <DateInput 
            label = "Approved Date" 
            inline={ false } 
            placeholder="DD-MM-YYYY"
            value={ approvedDate }
            setValue={ setApprovedDate }
          />
        </div>
        <div className="mb-4">
          <AttachmentInput 
            label = "Claim Approval Letter" 
            inline={ false } 
            value={ finalDoc } 
            setValue={ setFinalDoc }
          />
        </div>
        <div className="d-flex justify-content-between">
          <Button 
            handler={ onHide } 
            text="cancel" 
            className="btn-secondary me-2 w-100 pt-3 pb-3"
          />
          <Button 
            text="Mark as Approved"
            className="btn-primary w-100 pt-3 pb-3"
            handler={ handle }
          />
        </div>
      </form>
    </ModalComponent>
  );
};

const ClaimSharedSettled = ({ 
  show, 
  onHide, 
  title = "", 
  subTitle = "", 
  handle=()=>{},
  supportingDoc, 
  setSupportingDoc,
  paymentMethod, 
  setPaymentMethod,
  paidDate, 
  setPaidDate,
  paymentReference, 
  setPaymentReference 
})=>{
  const options = [
    {
      key: "",
      value: "Please Select"
    },
    {
      key: "Online",
      value: "Online"
    },
    {
      key: "Visit",
      value: "Visit"
    }
  ];
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div className="">
        <span className="modal-icon success mb-4">
          <BsCheck2Circle/>
        </span>
        <div className="mb-4">
          <h4>
            { title }
          </h4>
          <p>
            { subTitle }
          </p>
        </div>
        <form action="" method="post">
          <div className="mb-4">
            <DateInput 
              label = "Paid Date" 
              placeholder="DD-MM-YYY" 
              inline={ false }
              value={ paidDate }
              setValue={ setPaidDate }
            />
          </div>
          <div className="mb-4">
            <SelectInput 
              label = "Payment method" 
              inline={ false } 
              placeholder="DD-MM-YYYY"
              value={ paymentMethod }
              setValue={ setPaymentMethod }
              options={ options }
            />
          </div>
          <div className="mb-4">
            <TextInput 
              type = "text" 
              label = "Payment reference" 
              placeholder="Please enter payment reference" 
              inline={ false }
              value={ paymentReference }
              setValue={ setPaymentReference }
            />
          </div>
          <div className="mb-4">
            <AttachmentInput 
              label = "Supporting documents (optional)" 
              inline={ false } 
              value={ supportingDoc } 
              setValue={ setSupportingDoc }
            />
          </div>
        </form>
        <div className="d-flex justify-content-between">
          <Button 
            handler={ onHide } 
            text="cancel" 
            className="btn-secondary me-2 w-100 pt-3 pb-3"
          />
          <Button 
            text="Mark as settled" 
            className="btn-primary w-100 pt-3 pb-3"
            type="submit"
            handler={ handle }
          />
        </div>
      </div>
    </ModalComponent>
  );
};


export default ViewClaim;
