import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { StringParam, useQueryParam } from "use-query-params";
import Filter from "./Filter";
import s from "./index.module.scss";
import { cardData, collections, policy, settlements, tabs } from "./utils/cardData";
import DataCard from "../../../components/DataCard";
import PageSubNav from "../../../components/PageSubNav";
import PageSubTitle from "../../../components/PageSubTitle";
import PageTitle from "../../../components/PageTitle";
import TableComponent from "../../../components/Table";
import ReactTable from "../../../components/TableComponent";
import { useAuth } from "../../../contexts/Auth";

const PoliciesHomePage = () => {
  const [tabs, setTabs] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [client, setClient] = useState("");
  const [filteredData, setFilteredData] = useState({});
  const [filter, setFilter] = useState(false);

  const { api } = useAuth();

  const handleFilter = async () => {
    const res = await api ("policy/search", { client, startDate, endDate, params: "" }, "POST");
    setFilteredData(res);
    setFilter(true);
  };

  const { isLoading, error, data } = useQuery({
    queryFn: () =>
      api("policy/view"),
    queryKey: ["policy"]
  });

  console.log("POLICIES: ", data);

  useEffect(()=>{
    setTabs(get(data, "tabs", []));
    setCardData(get(data, "cardData", []));
  }, [data]);

  const [table, setTable] = useState(policy);
  const [type] = useQueryParam("type", StringParam);

  const tabSelect = (data) => {
    if(type === "policy" || type === undefined){
      setTable(get(data, "policy", {}));
    }else if(type === "settlements"){
      setTable(get(data, "settlements", {}));
    }else{
      setTable(get(data, "collections", {}));
    }
  };

  useEffect(()=>{
    if(filter){
      tabSelect(filteredData);
    }else{
      tabSelect(data);
    }
    setTabs(get(data, "tabs", []));
  }, [type, data, filteredData] );

  return (
    <div className={ `body-two ${s.policies_home}` }>
      <div className="mb-4">
        <PageTitle title="Policies"/>
      </div>

      <PageSubNav list={ tabs.map(item => ({ label: item.tabTitle, value: item.tab })) }/>

      {
        (!type || type === "policy") && card(cardData)
      }
      <div>
        {
          (type || !type !== "policy") &&
            <div
              className="mb-4">
              <PageSubTitle 
                title={ 
                  type === "collections" && get(tabs, "[1].listSubTitle", "") 
                  || 
                  type === "settlements" && get(tabs, "[2].listSubTitle", "") 
                } 
              />
            </div>
        }
        { /* FILTER FORM */ }
        <div className="mb-4">
          <Filter
            startDate={ startDate }
            setStartDate={ setStartDate }
            endDate={ endDate } 
            setEndDate={ setEndDate } 
            client= { client } 
            setClient= { setClient }
            handleFilter={ handleFilter }
          />
        </div>
        <ReactTable 
          columns={ get(table, "headings", []) } 
          data={ get(table, "body", []) } 
          popup = { type !== undefined && type !== "policy" }
        />
      </div>
    </div>
  );
};

const card = (cardData)=>{
  return(
    <div className="row justify-content-between align-items-start mb-5">
      {
        cardData.map((data, idx)=>{
          return(
            <div key = { idx } className="col-4">
              <DataCard 
                title={ get(data, "title", "") }
                value={ get(data, "value", "") }
                stats={ get(data, "showStat", false) }
                percentage={ get(data, "percentage", "") }
                increased={ get(data, "increased", false) }
              />
            </div>
          );
        })
      }
    </div>
  );
};


export default PoliciesHomePage;

