import { FiEdit3 } from "react-icons/fi";
import NewTab from "../../../../images/icons/new_tab.svg";


export const tabs = [
  {
    tab: "all",
    tabTitle: "All Team Members"
  },
  {
    tab: "create",
    tabTitle: "Create Team Member"
  }
];

export const team = {
  headings:[
    {
      Header: "Full Name",
      accessor: "name"
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      name:  "Duneeka", 
      email: "duneeka@insureme.lk", 
      status: <span className="pending">New</span>,
      cta: "team"
    },
    {
      name:  "Fname Lname", 
      email: "fname@insureme.lk", 
      status: <span className="pending">New</span>,
      cta: "team"
    },
    {
      name:  "Fname Lname", 
      email: "fname@insureme.lk", 
      status: <span className="pending">New</span>,
      cta: "team" 
    },
    {
      name:  "Fname Lname", 
      email: "fname@insureme.lk", 
      status: <span className="pending">New</span>,
      cta: "team"
    },
    {
      name:  "Fname Lname", 
      email: "fname@insureme.lk", 
      status: <span className="pending">New</span>,
      cta: "team"
    },
    {
      name:  "Fname Lname", 
      email: "fname@insureme.lk", 
      status: <span className="pending">New</span>,
      cta: "team"
    },
    {
      name:  "Fname Lname", 
      email: "fname@insureme.lk", 
      status: <span className="pending">New</span>,
      cta: "team"
    },
    {
      name:  "Fname Lname", 
      email: "fname@insureme.lk", 
      status: <span className="pending">New</span>,
      cta: "team" 
    },
    {
      name:  "Fname Lname", 
      email: "fname@insureme.lk", 
      status: <span className="pending">New</span>,
      cta: "team"
    },
    {
      name:  "Fname Lname", 
      email: "fname@insureme.lk", 
      status: <span className="pending">New</span>,
      cta: "team" 
    },
    {
      name:  "Fname Lname", 
      email: "fname@insureme.lk", 
      status: <span className="pending">New</span>,
      cta: "team"
    },
    {
      name:  "Fname Lname", 
      email: "fname@insureme.lk", 
      status: <span className="pending">New</span>,
      cta: "team"
    }
  ]
};
