import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { usePagination, useTable } from "react-table";
import s from "./index.module.scss";
import NewTab from "../../images/icons/new_tab.svg";
import Button from "../Button";
import AttachmentInput from "../Inputs/AttachmentInput";
import DateInput from "../Inputs/DateInput";
import TextInput from "../Inputs/TextInput";
import ModalComponent from "../Modal";
import Toggle from "../Toggle";

function ReactTable({ 
  rows = 7, 
  columns=[], 
  data=[], 
  popup = false, 
  newTab = true, 
  edit = false, 
  toggle = false, 
  text = false, 
  viewMore = false, 
  claim = false, 
  endorsements = false 
}) {
  const [index, setIndex] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [popupType, setPopupType] = useState("");
  const navigate = useNavigate();

  const closePopup = () => {
    setModalShow(false);
  };

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    usePagination
  );

  const paginateLength = Array.from({ length: pageCount }, (_) => {
    return "";
  });

  const showPopup = (popupString) => {
    setModalShow(true);
    setPopupType(popupString);
  };

  useEffect(()=>{
    setPageSize(rows);
  }, []);
  
  useEffect(()=>{
    setIndex(0);
  }, [data]);

  // Render the UI for your table
  console.log("TABLE DATA: ", data);
  return (
    <article className={ s.table_container }>
      <div className={ s.table_wrapper }>
        { /* TABLE */ }
        <table { ...getTableProps() }>
          <thead>
            { headerGroups.map((headerGroup, idx) => (
              <tr { ...headerGroup.getHeaderGroupProps() } key={ idx }>
                { headerGroup.headers.map((column, idx) => {
                  if(column.Header === "Blank" || column.Header === "Toggle"){
                    return <th key={ idx }></th>;
                  }
                  return <th { ...column.getHeaderProps() } key={ idx }>
                    { column.render("Header") }
                  </th>;
                }) }
              </tr>
            )) }
          </thead>
          <tbody { ...getTableBodyProps() }>
            { page.map((row, index) => {
              prepareRow(row);
              let toggled;
              return (
                <tr { ...row.getRowProps() } key={ index } id={ data?.[row.index]?.reqKey }>
                  { row.cells.map((cell, idx) => {
                    if(cell.column.Header === "Toggle"){
                      toggled = cell.value;
                    }
                    if(cell.column.Header === "" || cell.column.Header === "View" ){
                      return(
                        <td key={ idx } id={ data?.[index]?.reqKey }>
                          <div className={ `d-flex align-items-center justify-content-center ms-auto ${toggle? s.cta_container : ""}` }>
                            { popup && <Button 
                              text={ cell.value === "policy collections" ? "premium received" : "premium settled" }
                              className="btn-primary me-2"
                              handler={ () => showPopup(cell.value) }
                            /> }
                            {
                              claim && <Button
                                text = "Add claim"
                                className="btn-secondary py-3 me-1"
                                handler={ ()=>navigate("/claims/new") }
                              />
                            }
                            {
                              endorsements && <Button
                                text = "Add endorsement"
                                className="btn-secondary py-3 me-2"
                                handler={ ()=>navigate("/endorsements/new") }
                              />
                            }
                            {
                              toggle && <div className="me-3">
                                <Toggle products={ cell.value.toString().includes("products") } value={ toggled }/>
                              </div> 
                            }
                            {
                              newTab && cell.value !== "no view" && <img 
                                src={ NewTab } 
                                alt="New tab"
                                role="presentation"
                                className="me-3"
                                onClick={ ()=>{
                                  cell.value.toString().includes("policy") && navigate(`/policies/view/${data?.[row.index]?.req_key}`);
                                  cell.value.toString().includes("endorsements") && navigate("/endorsements/yourIDGoesHere");
                                  cell.value.toString().includes("claims") && navigate("/claims/yourIDGoesHere/");
                                  cell.value.toString().includes("quotations") && navigate(`/quotations/${data?.[row.index]?.reqKey}`);
                                  cell.value.toString().includes("clients") && navigate("/clients/yourIDGoesHere");
                                  cell.value.toString().includes("insurers") && navigate("/insurers/yourIDGoesHere");
                                } }
                              />
                            }
                            {
                              edit && 
                                <span
                                  className="table-icon"
                                  role="presentation"
                                  onClick={ ()=>{
                                    cell.value.toString().includes("team") && navigate("/team/view");
                                    cell.value.toString().includes("products") && navigate("/yourIDGoesHere/edit");
                                    cell.value.toString().includes("clients") && navigate("/clients/yourIDGoesHere/edit");
                                    cell.value.toString().includes("insurers") && navigate("/insurers/yourIDGoesHere/edit");
                                  } }
                                >
                                  <FiEdit3/>
                                </span>
                            }
                            {
                              text && cell.value
                            }
                            {
                              viewMore && <Button icon="next" className="ms-2" text="view more" handler={ ()=>{
                                cell.value.toString().includes("products") && navigate("/products/yourIDGoesHere");
                              } }/>
                            }
                          </div>
                        </td>
                      );
                    }
                    return <td { ...cell.getCellProps() } key={ idx }>
                      { cell.render("Cell") }
                    </td>;
                  }) }
                </tr>
              );
            }) }
          </tbody>
        </table>
        
        { /* PAGINATION */ }
        { pageCount > 1 && <div className="pagination">
          <div className="d-flex justify-content-between align-items-center p-2">
            <Button 
              icon="back" 
              text="Previous" 
              className="btn-secondary" 
              handler={ ()=>{
                setIndex(index - 1);
                previousPage();
              } } 
              disabled={ !canPreviousPage }
            />
            <div className="d-flex justify-content-center align-items-center" >
              {
                paginateLength.map((_, idx) => {
                  return <button 
                    key={ idx } 
                    className={ `mb-0 mx-1 py-2 px-3 ${s.paginate_btn} ${ index === idx ? s.active : "" }` }
                    onClick={ () => {
                      setIndex(idx);  
                      gotoPage(idx);
                    } }
                  >
                    { idx + 1 }
                  </button>;
                })
              }
            </div>
            <Button 
              icon="next" 
              text="Next" 
              className="btn-secondary" 
              handler={ ()=>{
                setIndex(index + 1);
                nextPage();
              } } 
              disabled={ !canNextPage }
            />
          </div>
        </div> }

        { /* MODAL */ }
        <ModalComponent
          show = { modalShow }
          onHide = { () => setModalShow(false) }
        >
          <span className={ `${s.checkmark} mb-4` }>
            <AiOutlineCheck/>
          </span>
          <div className="mb-4">
            <h4>
              { popupType === "policy collections" ? "Mark as Premium Received" : "Mark as Premium Settled" }
            </h4>
            <p>
              Please enter the information below to mark this premium as settled
            </p>
          </div>
          <form action="" method="post">
            <div className="mb-4">
              <TextInput type = "number" label = "Premium Amount (LKR)" placeholder="220,000.00"/>
            </div>
            <div className="mb-4">
              <DateInput label = "Received Date" inline={ false }/>
            </div>
            <div className="mb-4">
              <AttachmentInput label = "Supporting" inline={ false }/>
            </div>
            <div className="d-flex justify-content-between">
              <Button 
                handler={ closePopup } 
                text="cancel" 
                className="btn-secondary me-2 w-100 pt-3 pb-3"
              />
              <Button 
                text={ popupType === "policy collections" ? "Mark as Received" : "Mark as Settled" } 
                className="btn-primary w-100 pt-3 pb-3"
                type="submit"
              />
            </div>
          </form>
        </ModalComponent>
      </div>
    </article>
  );
}

export default ReactTable;
