import cx from "classnames";
import get from "lodash/get";
import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import s from "./index.module.scss";
import { useAuth } from "../../../contexts/Auth";
import Logo from "../../../images/logo.png";

// eslint-disable-next-line react/display-name
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  const { user } = useAuth();
  return (
    <button
      ref={ ref }
      className={ "btn" }
      onClick={ (e) => {
        e.preventDefault();
        onClick(e);
      } }
    >
      <div className={ s.user_menu_button }>
        <div>
          <p>{ get(user, "name", "User") }</p>
          <p>{ get(user, "email", "") }</p>
        </div>
        { get(user, "image", false) && <img src={ get(user, "image", "") } alt=""/> }
      </div>
      { children }
    </button>
  );
});

const Header = () => {
  const { user, logout } = useAuth();

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout();
  };
  return (
    <Navbar bg="trasparent" expand="lg" sticky="top" className={ cx("py-3", [s.top_nav]) }>
      <Container fluid>
        <Navbar.Brand as="div">
          <Link to={ "/" }>
            <img src={ Logo } alt="" height={ 38 }/>
          </Link>
        </Navbar.Brand>
        <Navbar.Collapse>
          <Nav className="ms-auto">
            <NavDropdown className={ s.user_menu } id="user-menu-dropdown" align="end" title={ <CustomToggle /> } >
              <NavDropdown.Item as={ "li" }>
                <Link to={ "/user" }>View Profile</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as={ "li" }>
                <Link to={ "/user/settings" }>Settings</Link>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as="li">
                <a href="#logout" onClick={ handleLogout }>Logout</a>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
