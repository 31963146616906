import cx from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import s from "./index.module.scss";

import { clients, corporate, insurers, operations, productManagement, reports, team } from "./links";


const MainMenu = () => {
  return (
    <div className={ s.main_menu }>
      <h4>Corporate</h4>
      <LinkList list={ corporate } />
      <hr/>
      <h5>Operations</h5>
      <LinkList list={ operations } />
      <hr/>
      <h5>Product Management</h5>
      <LinkList list={ productManagement } />
      <hr/>
      <LinkList list={ clients } />
      <hr/>
      <LinkList list={ insurers } />
      <hr/>
      <LinkList list={ team } />
      <hr/>
      <LinkList list={ reports } />

    </div>
  );
};

export default MainMenu;

const LinkList = ({ list = [] }) => {
  return <ul>
    { list.map(({ link, label, icon }, idx) => <li key={ idx }>
      <NavLink
        className={ ({ isActive }) => cx({ [s.isActiveMenuLink]: isActive }) }
        to={ link } end>
        <img src={ icon } alt={ label } width={ 24 }/>
        { label }
      </NavLink>
    </li>) }
  </ul>;
};
