import React from "react";
import InputLayout from "../Layout";

const SelectInput = ({ options = [], label = "", placeholder="", inline = true, readOnly = false, value="", setValue=()=>{}, required = true }) => {
  return (
    <InputLayout inline={ inline } label={ label }>
      <select 
        name={ label } 
        id={ label } 
        placeholder={ placeholder } 
        disabled = { readOnly }
        value={ value }
        onChange={ (e)=>{
          setValue(e.target.value);
        } }
        required = { required }
      >
        {
          options.map((option, idx)=>{
            return <option key={ idx } value={ option.key } >
              { option.value }
            </option>;
          })
        }
      </select>
    </InputLayout>
  );
};

export default SelectInput;
