export const tabs = [
  {
    tab: "new",
    tabTitle: "New"
  },
  {
    tab: "sent to insurer",
    tabTitle: "Sent to Insurer"
  },
  {
    tab: "confirmed",
    tabTitle: "Confirmed"
  },
  {
    tab: "sent to client",
    tabTitle: "Sent To Client"
  },
  {
    tab: "settled",
    tabTitle: "Settled"
  }
]
;

export const newEndorsement = {
  headings:[
    {
      Header: "Created Date",
      accessor: "createdDate"
    },
    {
      Header: "Requested Date",
      accessor: "requestedDate"
    },
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Effective Date",
      accessor: "effectiveDate"
    },
    {
      Header: "Transaction Date",
      accessor: "transactionDate"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      createdDate:  "31-05-2022", 
      requestedDate: "31-05-2022", 
      insured: "Dialog Axiata PLC (DAP)...",  
      product: "WORKMENS COMPENSATION",
      type: "Inclusion",
      effectiveDate: "31-05-2022",
      transactionDate: "31-05-2022",
      status: <div className="pending">new</div>,
      cta: "endorsements"
    }
  ]
};

export const sentToInsurer = {
  headings:[
    {
      Header: "Created Date",
      accessor: "createdDate"
    },
    {
      Header: "Requested Date",
      accessor: "requestedDate"
    },
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Effective Date",
      accessor: "effectiveDate"
    },
    {
      Header: "Transaction Date",
      accessor: "transactionDate"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      createdDate:  "31-05-2022", 
      requestedDate: "31-05-2022", 
      insured: "Dialog Axiata PLC (DAP)...",  
      product: "WORKMENS COMPENSATION",
      type: "Inclusion",
      effectiveDate: "31-05-2022",
      transactionDate: "31-05-2022",
      status: <div className="sent">sent to insurer</div>,
      cta: "endorsements"
    }
  ]
};

export const confirmed = {
  headings:[
    {
      Header: "Created Date",
      accessor: "createdDate"
    },
    {
      Header: "Requested Date",
      accessor: "requestedDate"
    },
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Effective Date",
      accessor: "effectiveDate"
    },
    {
      Header: "Transaction Date",
      accessor: "transactionDate"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      createdDate:  "31-05-2022", 
      requestedDate: "31-05-2022", 
      insured: "Dialog Axiata PLC (DAP)...",  
      product: "WORKMENS COMPENSATION",
      type: "Inclusion",
      effectiveDate: "31-05-2022",
      transactionDate: "31-05-2022",
      status: <div className="sent">confirmed</div>,
      cta: "endorsements"
    }
  ]
};

export const sentToClient = {
  headings:[
    {
      Header: "Created Date",
      accessor: "createdDate"
    },
    {
      Header: "Requested Date",
      accessor: "requestedDate"
    },
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Effective Date",
      accessor: "effectiveDate"
    },
    {
      Header: "Transaction Date",
      accessor: "transactionDate"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      createdDate:  "31-05-2022", 
      requestedDate: "31-05-2022", 
      insured: "Dialog Axiata PLC (DAP)...",  
      product: "WORKMENS COMPENSATION",
      type: "Inclusion",
      effectiveDate: "31-05-2022",
      transactionDate: "31-05-2022",
      status: <div className="sent">sent to client</div>,
      cta: "endorsements"
    }
  ]
};

export const settled = {
  headings:[
    {
      Header: "Created Date",
      accessor: "createdDate"
    },
    {
      Header: "Requested Date",
      accessor: "requestedDate"
    },
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Effective Date",
      accessor: "effectiveDate"
    },
    {
      Header: "Transaction Date",
      accessor: "transactionDate"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      createdDate:  "31-05-2022", 
      requestedDate: "31-05-2022", 
      insured: "Dialog Axiata PLC (DAP)...",  
      product: "WORKMENS COMPENSATION",
      type: "Inclusion",
      effectiveDate: "31-05-2022",
      transactionDate: "31-05-2022",
      status: <div className="received">settled</div>,
      cta: "endorsements"
    }
  ]
};
