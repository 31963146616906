import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsCheck2Circle } from "react-icons/bs";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import s from "./index.module.scss";
import Button from "../../../components/Button";
import AttachmentInput from "../../../components/Inputs/AttachmentInput";
import DateInput from "../../../components/Inputs/DateInput";
import SearchInput from "../../../components/Inputs/SearchInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import ModalComponent from "../../../components/Modal";
import PageSubTitle from "../../../components/PageSubTitle";
import PageTitle from "../../../components/PageTitle";
import { useAuth } from "../../../contexts/Auth";

const NewClaim = () => {
  const [draft, setDraft] = useState(false);
  const [shared, setShared] = useState(false);
  const [modalApproved, setModalApproved] = useState(false);
  const [modalApprovedMessage, setModalApprovedMessage] = useState(false);
  const [modalClaimShared, setModalClaimShared] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalRejectShow, setModalRejectShow] = useState(false);
  const navigate = useNavigate();

  const [policyNumber, setPolicyNumber] = useState("");
  const [main, setMain] = useState([]);
  const [products, setProducts] = useState([]);
  const [insured, setInsured] = useState("");
  const [insurer, setInsurer] = useState("");
  const [mainClassId, setmainClassId] = useState(0);
  const [product, setProduct] = useState(0);
  const [requestDate, setRequestDate] = useState("");
  const [channel, setChannel] = useState("");
  const [claimType, setClaimType] = useState("");
  const [requestAmount, setRequestAmount] = useState("");
  const [requestLetter, setRequestLetter] = useState({});
  const [supportingDocs, setSupportingDocs] = useState({});

  const [claim, setClaim] = useState({});

  const mainData = useQuery({
    queryFn: () =>
      api("product/main-class"),
    queryKey: ["productsMainClass"]
  });

  const list = useQuery({
    queryFn: () =>
      api("product/details"),
    queryKey: ["products"]
  });

  const { api, docApi } = useAuth();

  useEffect(()=>{
    if(!list?.data){
      return;
    }
    const propertyNames = Object.keys(list?.data);
    const productsArr = [];
    propertyNames.forEach((property)=>{
      if(property !== "tabs"){
        productsArr.push(list?.data?.[property]);
      }
    });
    setMain(productsArr);
  }, [list?.data]);

  useEffect(()=>{ 
    let selectedItem = mainData?.data?.filter((item=>item.main_class_id === parseInt(mainClassId)));
    main.map(item=>{
      if(item.title === selectedItem?.[0]?.main_class_name){
        setProducts(item);
      }
    });
  },[mainClassId]);

  const handleDraft = async () => {
    const refNum = await api("claim/generate-claim-ref", {}, "POST");

    if(refNum?.referenceNumber){
      await docApi("claim/document-upload", { file: requestLetter, referenceNumber: refNum?.referenceNumber, docId: 5 } ,"POST"); 
      await docApi("claim/document-upload", { file: supportingDocs, referenceNumber: refNum?.referenceNumber, docId: 7 } ,"POST"); 

      const create = await api(
        "claim/create",
        {
          channel,
          claimReferenceNumber: refNum?.referenceNumber,
          claimType,
          insured: parseInt(insured),
          insurer: parseInt(insurer),
          mainClassId: parseInt(mainClassId),
          policyNumber,
          productId: parseInt(product),
          requestAmount: parseInt(requestAmount),
          requestDate
        }, 
        "POST"
      );
        
      if(create?.claimId){
        setChannel("");
        setClaimType("");
        setInsured("");
        setInsurer("");
        setmainClassId(0);
        setPolicyNumber("");
        setProduct(0);
        setRequestAmount("");
        setRequestDate("");
      }
      setDraft(false);
    }

  };
  const options = [
    {
      key: "",
      value: "Please select"
    },
    {
      key: 1,
      value: "One"
    },
    {
      key: 2,
      value: "Two"
    }
  ];
  
  const team = [
    {
      key: "",
      value: "Select Assignee"
    },
    {
      key: "John Smith",
      value: "John Smith"
    },
    {
      key: "Duneeka",
      value: "Duneeka"
    }
  ];
  
  const status = [
    {
      key: "",
      value: "Status"
    },
    {
      key: "Sent to Insurer",
      value: "Sent to Insurer"
    },
    {
      key: "Approved",
      value: "Approved"
    }
  ];

  return (
    <>
      <div className="ps-4 pe-4 pb-4">
        {
          draft && <div className="mb-4">
            <div className="d-flex justify-content-between align-items-center pb-4 pt-2">
              <div>
                <PageTitle title={ `Claim Request: ${"REF1234567890"}` }/>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <Button text="back" icon="back" handler={ ()=>navigate(-1) }/>
                <p className="mb-0 mx-4">
                  { `Last updated on: ${"2022-03-23 10:45 AM"}` }
                </p>
              
                <SelectInput inline={ false } options={ status }/>
              </div>
            </div>
            <hr/>
          </div>
        }
        <div className="mb-4 mt-4 d-flex justify-content-between align-items-center">
          { 
            draft && <div className="desc me-4">
              <p className="mb-2">Created by: Duneeka</p>
              <p>Created at: 12-09-2022 at 10:00 AM</p>
            </div> 
          }
          {
            draft && <SelectInput label="Assignee" inline={ false } options={ team }/>
          }
          { !draft && <PageSubTitle title="Create claim request"/> }
          { draft && !shared && <p className="mb-0 pending">New</p> }
          { draft && shared && <p className="mb-0 sent">sent to insurer</p> }
          <div className="d-flex align-items-center">
            { !draft && <Button text="back"  icon="back" className="btn-secondary me-2" handler={ ()=>navigate(-1) }/> }
            { !draft && <Button text="save"  icon="save" className="btn-primary" handler={ handleDraft }/> }
            { 
              draft && 
                <Button
                  text="edit claim request"
                  icon="edit"
                  className="btn-secondary me-2"
                  handler={ ()=>{
                    setDraft(false);
                    setShared(false);
                  } }
                /> 
            }
            { 
              draft && !shared && 
                <Button 
                  text="send to insurer"  
                  icon="edit" 
                  className="btn-secondary me-2" 
                  handler={ ()=>setModalShow(true) }
                /> 
            }
            { 
              draft && !shared &&
                <Button 
                  text="reject"  
                  icon="close" 
                  className="btn-secondary" 
                  handler={ ()=>setModalRejectShow(true) }
                /> 
            }
            { 
              draft && shared &&
                <Button 
                  text="mark claim as approved"  
                  icon="check-circ" 
                  className="btn-primary" 
                  handler={ ()=>setModalApproved(true) }
                /> 
            }
          </div>
        </div>
        <div className="pt-4">
          <div>
            <div className="mb-2">
              <TextInput label="reference number" inline={ true } type="text" readOnly={ draft }/>
            </div>
            <div className="mb-2">
              <SearchInput 
                label="policy number" 
                placeholder="Please select" 
                readOnly={ draft }
                value={ policyNumber }
                setValue={ setPolicyNumber }
              />
            </div>
            <div className="mb-2">
              <SearchInput 
                label="insured" 
                placeholder="Please select" 
                readOnly={ draft }
                value={ insured }
                setValue={ setInsured }
              />
            </div>
            <div className="mb-2">
              <SelectInput 
                label="insurer" 
                options={ options } 
                readOnly={ draft }
                value={ insurer }
                setValue={ setInsurer }
              />
            </div>
            <div className="mb-2">
              <SelectInput 
                label="main class" 
                options={ [
                  { key: "", value: "Please select" }, 
                  ...get(mainData, "data", []).map(({ main_class_id: key, main_class_name: value })=>({ key, value }))
                ] }
                readOnly={ draft }
                value={ mainClassId }
                setValue={ setmainClassId }
              />
            </div>
            <div className="mb-2">
              <SelectInput 
                label="product" 
                options={ [
                  { key: "", value: "Please select" }, 
                  ...get(products, "body", []).map(({ product_id: key, product_name: value })=>({ key, value }))
                ] } 
                readOnly={ draft }
                value={ product }
                setValue={ setProduct }
              />
            </div>``
            <div className="mb-2">
              <DateInput 
                label="claim request date" 
                placeholder="DD-MM-YYYY" 
                readOnly={ draft }
                value={ requestDate }
                setValue={ setRequestDate }
              />
            </div>
            <div className="row justify-content-between align-items-center mb-4">
              <p className="mb-0 col-3 label">
                claim channel
              </p>
              <div className="col-1">:</div>
              <div className="d-flex justify-content-between align-items-center col-8" onChange={ (e)=>{setChannel(e.target.value);} }>
                <div>
                  <input 
                    type="radio" 
                    id="App" 
                    name="Claim Channel" 
                    value="App"
                  />
                  <label htmlFor="App" className="ps-2">App</label>
                </div>

                <div>
                  <input type="radio" id="Email" name="Claim Channel" value="Email"/>
                  <label htmlFor="Email" className="ps-2">Email</label>
                </div>

                <div>
                  <input type="radio" id="Physical Document" name="Claim Channel" value="Physical Document"/>
                  <label htmlFor="Physical Document" className="ps-2">Physical Document</label>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <input type="radio" id="Other" name="Claim Channel" value="Other"/>
                  <div className="ms-2">
                    <TextInput type="text" label="Other" noLabel={ true } inline={ false } placeholder="Other (Please Type)"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={ `${s.seperator} container mt-4 mb-4` }>
          </div>
          <div>
            <div className="mb-2">
              <SelectInput 
                label="claim type" 
                options={ options } 
                readOnly={ draft }
                value={ claimType }
                setValue={ setClaimType }
              />
            </div>
            <div className="mb-2">
              <TextInput 
                type="number" 
                label="claim request amount" 
                placeholder="Please enter" 
                readOnly={ draft }
                value={ requestAmount }
                setValue={ setRequestAmount }
              />
            </div>
            <div className="mb-2">
              <AttachmentInput 
                label="endorsement request letter" 
                readOnly={ draft } 
                value={ requestLetter } 
                setValue={ setRequestLetter }
              />
            </div>
            <div className="mb-2">
              <AttachmentInput 
                label="supporting documents" 
                readOnly={ draft }
                value={ supportingDocs } 
                setValue={ setSupportingDocs }
              />
            </div>
          </div>
          <div className="pt-2">
            <Button 
              text="save" 
              icon="save" 
              className="btn-primary ms-auto mt-4" 
              handler={ ()=>handleDraft() } 
              readOnly={ draft }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewClaim;
