import React, { useState } from "react";
import { Button } from "react-bootstrap";
import TextInput from "../../components/Inputs/TextInput";
import Logo from "../../images/logo.png";

const LoginPage = ({ login = () => null }) => {
  const [email, setEmail] = useState(
    "user@gmail.com"
  );
  const [password, setPassword] = useState(
    "hello123"
  );

  const handleLogin = async (e) => {
    e.preventDefault();
    await login(email, password);
  };
  return (
    <div className="container my-5 py-5 text-center">
      <div className="row justify-content-center">
        <div className="col-md-9 col-lg-8 col-xl-6 col-xxl-5">
          <form action="#" onSubmit={ handleLogin } style={ { background: "#FAFAFA", borderRadius: "8px" } } className={ "p-5" }>
            <img src={ Logo } alt="Welcome to DigiNet" className={ "d-block mb-3 mx-auto" } width={ 120 }/>
            <TextInput
              label={ "Email" }
              type="email"
              className="form-control mb-3"
              placeholder="Email"
              value={ email }
              onChange={ setEmail }
            />
            <TextInput
              label={ "Password" }
              type="password"
              className="form-control mb-3"
              placeholder="Password"
              value={ password }
              onChange={ setPassword }
            />
            <Button type={ "submit" } className={ "mx-auto" }>Login</Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
