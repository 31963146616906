import React from "react";
import { Container } from "react-bootstrap";
import s from "./index.module.scss";

const PageBody = ({ children }) => {
  return (
    <div className={ s.page_wrapper }>
      <Container>
        { children }
      </Container>
    </div>
  );
};

export default PageBody;
