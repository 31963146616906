import Circooles from "../../../../images/icons/Circooles.svg";
import Dialog from "../../../../images/icons/Dialog.svg";

export const tabs = [
  {
    tab: "all insurers",
    tabTitle: "All Insurers"
  },
  {
    tab: "create insurer",
    tabTitle: "Create insurer"
  }
];

export const headings = [
  {
    Header: "Company",
    accessor: "company"
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "About",
    accessor: "about"
  },
  {
    Header: "Active Policies",
    accessor: "active"
  },
  {
    Header: "GWP (LKR)",
    accessor: "gwp"
  },
  {
    Header: "Net Commision",
    accessor: "commision"
  },
  {
    Header: "Toggle",
    accessor : "toggled"
  },
  {
    Header: "",
    accessor : "cta"
  }
];

export const body = [
  {
    company: <div className="company d-flex justify-content-start align-items-center">
      <img src={ Dialog } alt="Dialog" className="me-2"/>
      <p>Dialog PLC</p>
    </div>, 
    status: <span className="received">Customer</span>,
    about: <div className="desc">
      <p>Telecommunication Company</p>
      <p>Dialog is sri lanka's largest tt  telecommunication company.</p>
    </div>,
    active: "12",
    gwp: "3,543,856.12",
    commision: "35,438.12",
    toggled: false,
    cta: "clients"
  },
  {
    company: <div className="company d-flex justify-content-start align-items-center">
      <img src={ Circooles } alt="Dialog"  className="me-2"/>
      <p>Circooles</p>
    </div>,
    status: <span className="pending">churned</span>,
    about: <div className="desc">
      <p>Design software</p>
      <p>Super lightweight design app</p>
    </div>,
    active: "12",
    gwp: "3,543,856.12",
    commision: "35,438.12",
    toggled: true,
    cta: "clients"
  }
];
