export default [
  {
    period: "Last 12 Months"
  },
  {
    period: "Last 3 Months"
  },
  {
    period: "This Month"
  },
  {
    period: "This Week"
  },
  {
    period: "Today"
  }
];
