import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Button from "../../../components/Button";
import AttachmentInput from "../../../components/Inputs/AttachmentInput";
import DateInput from "../../../components/Inputs/DateInput";
import SearchInput from "../../../components/Inputs/SearchInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import ModalComponent from "../../../components/Modal";
import PageTitle from "../../../components/PageTitle";
import { useAuth } from "../../../contexts/Auth";

const EditQuotation = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [reqKey] = useState(params.id);

  const { isLoading, error, data } = useQuery({
    queryFn: () =>
      api(`quotation/by-req-key?reqKey=${reqKey}`),
    queryKey: ["quotation"]
  });

  const [save, setSave] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const [main, setMain] = useState([]);

  const [products, setProducts] = useState([]);
  const [mainClassId, setMainClassId] = useState(data?.fk_main_class_id);
  const [productId, setProductId] = useState(data?.fk_product_id);
  const [clientId, setClientId] = useState(data?.fk_client_id);
  const [sumInsured, setSumInsured] = useState(data?.sum_insured);
  const [policyStartDate, setPolicyStartDate] = useState(data?.policy_start_date);
  const [policyEndDate, setPolicyEndDate] = useState(data?.policy_end_date);
  const [bothDates, setBothDates] = useState(false);
  const [isBothDate, setIsBothDate] = useState(data?.both_date_inclusive);
  const [closingSlip, setClosingSlip] = useState({});
  const [otherDoc, setOtherDoc] = useState({});

  const { api, docApi } = useAuth();

  const mainData = useQuery({
    queryFn: () =>
      api("product/main-class"),
    queryKey: ["productsMainClass"]
  });

  const list = useQuery({
    queryFn: () =>
      api("product/details"),
    queryKey: ["products"]
  });

  const sendRequestData = () => {
    if(bothDates){
      setIsBothDate(1);
    }else{
      setIsBothDate(0);
    }
    return {
      reqKey,
      mainClassId: parseInt(mainClassId),
      productId: parseInt(productId),
      clientId: parseInt(clientId),
      sumInsured,
      policyStartDate,
      policyEndDate,
      createBy: 1,
      isBothDate,
      quoteInitId: 20,
      updatedBy: 1
    };
  };

  useEffect(()=>{
    setMainClassId(data?.fk_main_class_id);
    setProductId(data?.fk_product_id);
    setClientId(data?.fk_client_id);
    setSumInsured(data?.sum_insured);
    setPolicyStartDate(data?.policy_start_date);
    setPolicyEndDate(data?.policy_end_date);
    setIsBothDate(data?.both_date_inclusive);
    if(data?.both_date_inclusive === 0){
      setBothDates(false);
    }else{
      setBothDates(true);
    }
  },[data]);

  useEffect(()=>{
    if(!list?.data){
      return;
    }
    const propertyNames = Object.keys(list?.data);
    const productsArr = [];
    propertyNames.forEach((property)=>{
      if(property !== "tabs"){
        productsArr.push(list?.data?.[property]);
      }
    });
    setMain(productsArr);
  }, [list?.data, data]);

  useEffect(()=>{ 
    let selectedItem = mainData?.data?.filter((item=>item.main_class_id === parseInt(mainClassId)));
    main.map(item=>{
      if(item.title === selectedItem?.[0]?.main_class_name){
        setProducts(item);
      }
    });
  },[mainClassId, data]);
  
  const closePopup = () =>{
    setSave(false);
    setConfirm(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setSave(true);
  };
  
  const handleConfirm = async (e)=> {
    e.preventDefault();
    const dat = sendRequestData();
    const edit = await api("quotation/quotation-edit", dat, "POST");
    if(edit){
      setConfirm(true);
      setSave(false);
    }
  };

  return (
    <>
      <section className="body">
        <article className="d-flex justify-content-between align-items-end mb-4">
          <div>
            <PageTitle title="Request for quotation"/>
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <Button icon="back" text="Back" className="btn-secondary me-2" handler={ ()=>navigate(-1) }/>
            <Button icon="save" text="Save" className="btn-primary" handler={ ()=>setSave(true) }/>
          </div>
        </article>
        <article>
          <form action="" onSubmit={ (e)=>handleSave(e) }>
            <div>
              <SelectInput 
                options={ [
                  { key: "", value: "Please select" }, 
                  ...get(mainData, "data", []).map(({ main_class_id: key, main_class_name: value })=>({ key, value }))
                ] }
                label="Main Class"
                value={ mainClassId }
                setValue={ setMainClassId }
              />
            </div>
            <div>
              <SelectInput 
                options={ [
                  { key: "", value: "Please select" }, 
                  ...get(products, "body", []).map(({ product_id: key, product_name: value })=>({ key, value }))
                ] } 
                label="Product"
                value={ productId }
                setValue={ setProductId }
              />
            </div>
            <div>
              <SearchInput 
                placeholder="Please select" 
                label="Insured"
                value={ clientId }
                setValue={ setClientId }
              />
            </div>
            <div>
              <TextInput 
                type="number" 
                placeholder="Please enter" 
                label="Sum Insured"
                value={ sumInsured }
                setValue={ setSumInsured }
              />
            </div>
            <div className="row justify-content-between align-items-center mb-4">
              <p className="mb-0 col-3 label">
                period of insurance
              </p>
              <div className="col-1">:</div>
              <div className="d-flex justify-content-between align-items-center col-8">
                <DateInput 
                  inline={ false } 
                  label="Start Date" 
                  noLabel={ true } 
                  placeholder="Start Date"
                  uFValue={ policyStartDate }
                  setValue={ setPolicyStartDate }
                />
                <DateInput 
                  inline={ false } 
                  label="Start Date" 
                  noLabel={ true } 
                  placeholder="End Date"
                  uFValue={ policyEndDate }
                  setValue={ setPolicyEndDate }
                />
                <TextInput 
                  type="checkbox" 
                  inline={ false } 
                  label="Both days inclusive"
                  value={ bothDates }
                  setValue={ setBothDates }
                />
              </div>
            </div>
            <div>
              <AttachmentInput 
                label="Closing Slip" 
                value={ closingSlip } 
                setValue={ setClosingSlip }
              />
            </div>
            <div>
              <AttachmentInput 
                label="Other Documents" 
                value={ otherDoc } 
                setValue={ setOtherDoc }
              />
            </div>
            <div className="mt-4 py-4">
              <Button icon="save" type="submit" text="Save" className="btn-primary ms-auto"/>
            </div>
          </form>
        </article>
      </section>

      { /* MODALS */ }
      <Save show={ save } onHide={ closePopup } handle={ handleConfirm }/>;
      <Approve show = { confirm } onHide = { closePopup }/>
    </>
  );
};

const Save = ({ show, onHide, handle }) => {
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div className="confirmation-popup">
        <span className={ "modal-icon success mb-4" }>
          <AiOutlineCheck/>
        </span>
        <div className="mb-4">
          <p>
            Slip created successfully
          </p>
          <h4>
            Do you want to send this slip for approval?
          </h4>
        </div>
        <form action="" onSubmit={ (e)=>handle(e) }>
          <div className="d-flex justify-content-between">
            <Button 
              handler={ onHide } 
              text="I'll do this later" 
              className="btn-secondary me-2 w-100 pt-3 pb-3"
            />
            <Button 
              text="send for approval"
              className="btn-primary w-100 pt-3 pb-3"
              type="submit"
            />
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};

const Approve = ({ show, onHide }) => {
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div className="confirmation-popup">
        <span className={ "modal-icon success mb-4" }>
          <AiOutlineCheck/>
        </span>
        <div className="mb-4">
          <h4>
            Slip sent for approval
          </h4>
        </div>
        <div className="d-flex justify-content-between">
          <Button 
            text="OK"
            className="btn-primary w-100 pt-3 pb-3"
            handler={ ()=>onHide() }
          />
        </div>
      </div>
    </ModalComponent>
  );
};

export default EditQuotation;
