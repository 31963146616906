import React from "react";
import { Route, Routes } from "react-router-dom";
import TeamPage from "../../pages/Team/List";
import ViewTeamMember from "../../pages/Team/View";

const TeamRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={ <TeamPage /> } />
      <Route path="/:id" element = { <ViewTeamMember/> } />
    </Routes>
  );
};

export default TeamRoutes;
