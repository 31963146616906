import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import Filter from "./Filter";
import { 
  approved, 
  cardData, 
  drafts, 
  lostBusiness, 
  newQuotations, 
  pendingApprovals, 
  premiumsReceived, 
  rejected, 
  sentToInsurers, 
  tabs } from "./utils/cardData";
import Button from "../../../components/Button";
import DataCard from "../../../components/DataCard";
import PageSubNav from "../../../components/PageSubNav";
import PageTitle from "../../../components/PageTitle";
import ReactTable from "../../../components/TableComponent";
import { useAuth } from "../../../contexts/Auth";

const QuotationsPage = () => {
  const navigate = useNavigate();
  const { isLoading, error, data } = useQuery({
    queryFn: () =>
      api("quotation/list-quotations", {}, "POST"),
    queryKey: ["quotation"]
  });
  const { api } = useAuth();

  const [table, setTable] = useState(drafts);
  const [type] = useQueryParam("type", StringParam);

  useEffect(()=>{
    if(type === "drafts" || type === undefined){
      setTable(drafts);
    }else if(type === "new-quotations"){
      setTable(data?.["New Quotation"] || {});
    }else if(type === "pending-approvals"){
      // setTable(get(data?.["Pending Approval"], {}) || {});
      setTable(get(data, "['Pending Approval']", {}));
    } else if(type === "approved"){
      setTable(data?.["Approved"] || {});
    } else if(type === "rejected"){
      setTable(rejected);
      // setTable(data?.["Rejected"] || {});
    } else if(type === "sent-to-insurers"){
      setTable(data?.["Sent To Insurers"] || {});
    } else if(type === "premiums-received"){
      setTable(data?.["Premium Received"] || {});
    } else{
      setTable(lostBusiness);
    }
  }, [type, data]);

  return (
    <div className={ "body-two" }>
      <div className="mb-4">
        <PageTitle title="Quotations"/>
      </div>
      <div>
        <PageSubNav list={ data?.tabs?.map(item => ({ label: item.tabTitle, value: item.tab })) }/>

        {
          (!type || type === "drafts") && card(data?.cardData || [])
        }

        <div>
          <div className="d-flex">
            <Button
              icon="plus"
              text="New Quotation"
              className="btn-primary ms-auto me-2"
              handler={ () => navigate("/quotations/new") }
            />
          </div>
          <div className="my-3">
            <Filter />
          </div>
          <ReactTable columns={ table?.headings } data={ table?.body }/>
        </div>
      </div>
    </div>
  );
};

const card = (cardData)=>{
  return(
    <div className="row justify-content-between align-items-start mb-5">
      {
        cardData.map((data, idx)=>{
          return(
            <div key = { idx } className="col-4">
              <DataCard
                title={ get(data, "title", "") }
                value={ get(data, "value", "") }
                stats={ get(data, "showStat", false) }
                percentage={ get(data, "percentage", "") }
                increased={ get(data, "increased", false) }
              />
            </div>
          );
        })
      }
    </div>
  );
};



export default QuotationsPage;

