import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import SelectInput from "../../../components/Inputs/SelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import PageSubNav from "../../../components/PageSubNav";
import PageSubTitle from "../../../components/PageSubTitle";
import PageTitle from "../../../components/PageTitle";
import { tabs } from "../List/utils/data";


const CreateClient = () => {
  const navigate = useNavigate();

  const options = [
    {
      key: "",
      value: "Please select"
    },
    {
      key: "one",
      value: "One"
    },
    {
      key: "two",
      value: "Two"
    }
  ];
  return(
    <section className="body">
      <div className="mb-4">
        <PageTitle title="Products"/>
      </div>
      <div className="mb-4">
        <PageSubNav list={ tabs.map(item => ({ label: item.tabTitle, value: item.tab })) }/>
      </div>
      <div className="d-flex justify-content-between align-items-end mb-5">
        <div>
          <PageSubTitle title="Create new product"/>
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <Button icon="back" text="Back" className="btn-secondary me-2" handler={ ()=>navigate(-1) }/>
          <Button icon="save" text="Save" className="btn-primary"/>
        </div>
      </div>
      <div className="form">
        <div className="mb-2">
          <SelectInput label="Main class" options={ options } inline={ true }/>
        </div>
        <div className="mb-2">
          <TextInput label="Product name" placeholder="Please enter" inline={ true }/>
        </div>
        <div>
          <TextInput label="Sub class" placeholder="Please enter" inline={ true }/>
        </div>
      </div>
    </section>
  );
};

export default CreateClient;
