import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import ClaimRoutes from "./ClaimRoutes";
import ClientRoutes from "./ClientRoutes";
import EndorsementRoutes from "./EndorsementRoutes";
import InsurerRoutes from "./InsurerRoutes";
import PolicyRoutes from "./PolicyRoutes";
import ProductRoutes from "./ProductRoutes";
import QuotationRoutes from "./QuotationRoutes";
import TeamRoutes from "./TeamRoutes";
import { AuthProvider } from "../../contexts/Auth";
import MemberLayout from "../../layouts/Member";
import HomePage from "../../pages/HomePage";

const DashboardRoutes = () => {
  return (
    <AuthProvider>
      <MemberLayout>
        <Routes>
          <Route path="/" element={ <HomePage /> } />
          <Route path="/quotations/*" element={ <QuotationRoutes /> } />
          <Route path="/policies/*" element={ <PolicyRoutes /> } />
          <Route path="/endorsements/*" element={ <EndorsementRoutes /> } />
          <Route path="/claims/*" element={ <ClaimRoutes /> } />
          <Route path="/products/*" element={ <ProductRoutes /> } />
          <Route path="/clients/*" element={ <ClientRoutes /> } />
          <Route path="/insurers/*" element={ <InsurerRoutes /> } />
          <Route path="/team/*" element={ <TeamRoutes /> } />
          <Route path="*" element={ <NotFound /> } />
        </Routes>
      </MemberLayout>
    </AuthProvider>
  );
};

export default DashboardRoutes;

const NotFound = () => {
  return <div className="text-center mt-5">
    <h4>Invalid page.</h4>
    <Link to={ "/" } className={ "btn btn-primary d-inline-block" }>Back to home page</Link>
  </div>;
};
