import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import SearchInput from "../../../components/Inputs/SearchInput";
import TextInput from "../../../components/Inputs/TextInput";
import PageSubNav from "../../../components/PageSubNav";
import PageTitle from "../../../components/PageTitle";
import { tabs } from "../List/utils/data";


const ViewTeamMember = () => {
  const navigate = useNavigate();

  return(
    <section className="body">
      <PageTitle title="Team"/>
      <div className="pt-4">
        <div className="mb-4">
          <PageSubNav list={ tabs.map(item => ({ label: item.tabTitle, value: item.tab })) }/>
        </div>
        <form action="">
          <div className="mb-2">
            <TextInput type="text" label="full name" placeholder="Please enter" inline={ true }/>
          </div>
          <div className="mb-2">
            <TextInput type="email" label="full name" placeholder="Please enter" inline={ true }/>
          </div>
          <div className="mb-2">
            <SearchInput label="manager" placeholder="Please Select" inline={ true }/>
          </div>
          <div className="pt-3">
            <Button text="Update Team Member" type="submit" className="btn-primary ms-auto"/>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ViewTeamMember;
