import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import s from "./index.module.scss";
import Button from "../../../components/Button";
import AttachmentInput from "../../../components/Inputs/AttachmentInput";
import DateInput from "../../../components/Inputs/DateInput";
import SearchInput from "../../../components/Inputs/SearchInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import PageSubTitle from "../../../components/PageSubTitle";
import PageTitle from "../../../components/PageTitle";

const EditClaim = () => {
  const [edit, setEdit] = useState(true);
  const [shared, setShared] = useState(false);
  const [rejected, setRejected] = useState(false);
  const navigate = useNavigate();

  const options = [
    {
      key: "",
      value: "Please select"
    },
    {
      key: "one",
      value: "One"
    },
    {
      key: "two",
      value: "Two"
    }
  ];
  
  const team = [
    {
      key: "",
      value: "Select Assignee"
    },
    {
      key: "John Smith",
      value: "John Smith"
    },
    {
      key: "Duneeka",
      value: "Duneeka"
    }
  ];
  
  const status = [
    {
      key: "",
      value: "Status"
    },
    {
      key: "Sent to Insurer",
      value: "Sent to Insurer"
    },
    {
      key: "Approved",
      value: "Approved"
    }
  ];

  return (
    <>
      <div className="ps-4 pe-4 pb-4">
        {
          edit && <div className="mb-4">
            <div className="d-flex justify-content-between align-items-center pb-4 pt-2">
              <div>
                <PageTitle title={ `Claim Request: ${"REF1234567890"}` }/>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <Button text="back" icon="back" handler={ ()=>navigate(-1) }/>
                <p className="mb-0 mx-4">
                  { `Last updated on: ${"2022-03-23 10:45 AM"}` }
                </p>
              
                <SelectInput inline={ false } options={ status }/>
              </div>
            </div>
            <hr/>
          </div>
        }
        <div className="mb-4 mt-4 d-flex justify-content-between align-items-center">
          { 
            edit && <div className="desc me-4">
              <p className="mb-2">Created by: Duneeka</p>
              <p>Created at: 12-09-2022 at 10:00 AM</p>
            </div> 
          }
          {
            edit && <SelectInput label="Assignee" inline={ false } options={ team }/>
          }
          { !edit && <PageSubTitle title="Create claim request"/> }
          { edit && !shared && !rejected && <p className="mb-0 pending">New</p> }
          { edit && shared && !rejected && <p className="mb-0 sent">sent to insurer</p> }
          { edit && rejected && <p className="mb-0 rejected">rejected</p> }
          <div className="d-flex align-items-center">
            { 
              edit && !rejected && 
                <Button
                  text="save"
                  icon="edit"
                  className="btn-primary"
                  handler={ ()=>{
                  } }
                /> 
            }
          </div>
        </div>
        <div className="pt-4">
          <div>
            <div className="mb-2">
              <TextInput label="reference number" inline={ true } type="text" readOnly={ edit }/>
            </div>
            <div className="mb-2">
              <SearchInput label="policy number" placeholder="Please select"/>
            </div>
            <div className="mb-2">
              <SearchInput label="insured" placeholder="Please select"/>
            </div>
            <div className="mb-2">
              <SelectInput label="insurer" options={ options }/>
            </div>
            <div className="mb-2">
              <SelectInput label="main class" options={ options }/>
            </div>
            <div className="mb-2">
              <SelectInput label="product" options={ options }/>
            </div>
            <div className="mb-2">
              <DateInput label="claim request date" placeholder="DD-MM-YYYY"/>
            </div>
            <div className="row justify-content-between align-items-center mb-4">
              <p className="mb-0 col-3 label">
                claim channel
              </p>
              <div className="col-1">:</div>
              <div className="d-flex justify-content-between align-items-center col-8">
                <div>
                  <input type="radio" id="App" name="Claim Channel" value="App"/>
                  <label htmlFor="App" className="ps-2">App</label>
                </div>

                <div>
                  <input type="radio" id="Email" name="Claim Channel" value="Email"/>
                  <label htmlFor="Email" className="ps-2">Email</label>
                </div>

                <div>
                  <input type="radio" id="Physical Document" name="Claim Channel" value="Physical Document"/>
                  <label htmlFor="Physical Document" className="ps-2">Physical Document</label>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <input type="radio" id="Physical Document" name="Claim Channel" value="Physical Document"/>
                  <div className="ms-2">
                    <TextInput type="text" label="Other" noLabel={ true } inline={ false } placeholder="Other (Please Type)"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={ `${s.seperator} container mt-4 mb-4` }>
          </div>
          <div>
            <div className="mb-2">
              <SelectInput label="claim type" options={ options }/>
            </div>
            <div className="mb-2">
              <TextInput type="number" label="claim request amount" placeholder="Please enter"/>
            </div>
            <div className="mb-2">
              <AttachmentInput label="endorsement request letter"/>
            </div>
            <div className="mb-2">
              <AttachmentInput label="supporting documents"/>
            </div>
          </div>
          <div className="pt-2">
            <Button 
              text="save" 
              icon="edit" 
              className="btn-primary ms-auto mt-4" 
              handler={ ()=>{} } 
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditClaim;
