import React from "react";

const NotFoundPage = () => {
  return (
    <div>
      Page not found!
    </div>
  );
};

export default NotFoundPage;
