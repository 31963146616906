import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { approved, newClaim, rejected, sentToInsurer, settled, tabs } from "./utils/data";
import Button from "../../../components/Button";
import SearchInput from "../../../components/Inputs/SearchInput";
import PageSubNav from "../../../components/PageSubNav";
import PageTitle from "../../../components/PageTitle";
import ReactTable from "../../../components/TableComponent";
import { useAuth } from "../../../contexts/Auth";

const ClaimsHomePage = () => {
  const [tabs, setTabs] = useState([]);
  const [table, setTable] = useState({ newClaim });
  const [type] = useQueryParam("type", StringParam);
  const navigate = useNavigate();
  const { isLoading, error, data } = useQuery({
    queryFn: () =>
      api("claim/list"),
    queryKey: ["claims"]
  });

  console.log("CLAIMS: ", data);

  const { api } = useAuth();

  useEffect(()=>{
    if(type === "New Claim" || type === undefined){
      setTable(data?.["New"] || {});
      // setTable(get(data, "New", {}));
    }else if(type === "Sent to Insurer"){
      setTable(data?.["Sent to Insurer"] || {});
      // setTable(sentToInsurer);
    }else if(type === "approved"){
      setTable(approved);
    }else if(type === "Reject"){
      setTable(data?.["Rejected"] || {});
    }else{
      setTable(settled);
    }
  }, [type, isLoading]);

  useEffect(()=>{
    setTabs(get(data, "tabs", []));
  }, [isLoading]);

  return (
    <div className="body-two">
      <div className="mb-4">
        <PageTitle title="Claims"/>
      </div>

      <div className="mb-4">
        <PageSubNav list={ tabs.map(item => ({ label: item.tabTitle, value: item.tab })) }/>
      </div>

      { /* TABLE FILTER */ }
      <div className="mb-4 row align-items-end justify-content-between">
        <div className="col-4">
          <SearchInput placeholder="Search" inline={ false }/>
        </div>
        <div className="col-4">
          <Button 
            icon="plus" 
            text="New Claim" 
            className="btn-primary ms-auto"
            handler={ () => navigate("/claims/new") }
          />
        </div>
      </div>
      {
        // <TableComponent headings={ table.headings } body={ table.body } cta={ cta }/>
      }
      <ReactTable columns={ table?.heading || [] } data={ table?.body || [] }/>
    </div>
  );
};

export default ClaimsHomePage;
