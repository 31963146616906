import React, { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import s from "./index.module.scss";
import { premium } from "./utils/data";
import Button from "../../../components/Button";
import AttachmentInput from "../../../components/Inputs/AttachmentInput";
import DateInput from "../../../components/Inputs/DateInput";
import SearchInput from "../../../components/Inputs/SearchInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import ModalComponent from "../../../components/Modal";
import PageTitle from "../../../components/PageTitle";
import ReactTable from "../../../components/TableComponent";
import { useAuth } from "../../../contexts/Auth";

const ConvertToPolicy = () => {
  const navigate = useNavigate();
  const [saved, setSaved] = useState(true);

  const [policyDoc, setPolicyDoc] = useState({});
  const [policyNumber, setPolicyNumber] = useState("");
  const [commission, setCommission] = useState("");

  const [approve, setApprove] = useState(false);
  const [approved, setApproved] = useState(false);

  const { api, docApi } = useAuth();
  const params = useParams();
  const [reqKey] = useState(params.id);
  
  const handleMainForm = async(e) => {
    e.preventDefault();
    const policy = await docApi(
      "document/upload", 
      { 
        file: policyDoc,
        reqKey,
        docId: 3,
        userId: 5  
      },
      "POST"
    );
    if(policy){
      const convert = await api(
        "policy/create", 
        {
          reqKey,
          policyNumber,
          commission: parseInt(commission)
        }, 
        "POST"
      );
      if(convert){
        setApprove(false);
        setApproved(true);
      }
    }
  };

  const options = [
    {
      key: "",
      value: "Please Select"
    },
    {
      key: "one",
      value: "One"
    },
    {
      key: "two",
      value: "Two"
    }
  ];

  return (
    <>
      <section className="body">
        <article className="d-flex justify-content-between align-items-end mb-4">
          <div>
            <PageTitle title={ "Convert To Policy" } /> 
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <Button icon="back" text="Back" className="btn-secondary me-2" handler={ ()=>navigate(-1) }/> 
            <Button 
              icon="check-shield" 
              text="convert to policy" 
              className="btn-secondary me-2"  
              handler={ ()=>setApprove(true) }
            />
          </div>
        </article>
        <article>
          <form action="" onSubmit={ handleMainForm }>
            <div>
              <div>
                <TextInput label="Reference"/>
              </div>
              <div>
                <TextInput 
                  label="Policy Number"
                  value={ policyNumber }
                  setValue={ setPolicyNumber }
                />
              </div>
              <div>
                <TextInput 
                  label="Commission %"
                  value={ commission }
                  setValue={ setCommission }
                />
              </div>
              <div>
                <AttachmentInput 
                  label="Policy Document"
                  value={ policyDoc }
                  setValue={ setPolicyDoc }
                />
              </div>
            </div>

            <h2 className="mt-4 mb-2">
              Quotation
            </h2>

            <div>
              <SelectInput options={ options } label="Main Class" readOnly={ saved }/>
            </div>
            <div>
              <SelectInput options={ options } label="Product" readOnly={ saved }/>
            </div>
            <div>
              <SearchInput placeholder="Please select" label="Insured" readOnly={ saved }/>
            </div>
            <div>
              <TextInput type="number" placeholder="Please enter" label="Sum Insured" readOnly={ saved }/>
            </div>
            <div className="row justify-content-between align-items-center mb-4">
              <p className="mb-0 col-3 label">
                period of insurance
              </p>
              <div className="col-1">:</div>
              <div className="d-flex justify-content-between align-items-center col-8">
                <DateInput inline={ false } label="Start Date" noLabel={ true } placeholder="Start Date" readOnly={ saved }/>
                <DateInput inline={ false } label="Start Date" noLabel={ true } placeholder="End Date" readOnly={ saved }/>
                <TextInput type="checkbox" inline={ false } label="Both days inclusive" readOnly={ saved }/>
              </div>
            </div>
            <div>
              <AttachmentInput label="Closing Slip" readOnly={ saved }/>
            </div>
            <div>
              <AttachmentInput label="Other Documents" readOnly={ saved }/>
            </div>
            <div className={ `${s.single_table} mb-5 mt-5` }>
              <div className={ `px-4 pt-4 pb-2 ${s.header} d-flex justify-content-between align-itesm-center` }>
                <h3 className="d-flex justify-content-start align-items-center">
                  { premium.title }
                </h3>
              </div>
              <div>
                <ReactTable columns={ premium.headings } data={ premium.body }/>
              </div>
            </div>
          </form>
        </article>

        { /* MODALS */ }
        <>
          <ApproveCreate 
            desc = "Do you want to convert this quotation to policy?" 
            show = { approve }
            onHide = { ()=>{
              setApprove(false);
            } }
            handle = { handleMainForm }
          />

          <Approved 
            title="Quotation approved successfully"
            show={ approved } 
            onHide={ ()=>{
              setApproved(false);
            } } 
          />
        </> 
      </section>
    </>
  );
};

export default ConvertToPolicy;

const ApproveCreate = ({ show, onHide, handle, title, desc }) => {
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div>
        <span className={ "modal-icon success mb-4" }>
          <AiOutlineCheck/>
        </span>
        <div className="mb-4">
          <h4>
            { title }
          </h4>
          <p>
            {
              desc
            }
          </p>
        </div>
        <form action="" method="post" onSubmit={ (e)=>handle(e) }>
          <div className="d-flex justify-content-between">
            <Button 
              handler={ onHide } 
              text="cancel" 
              className="btn-secondary me-2 w-100 pt-3 pb-3"
            />
            <Button 
              text="convert to policy"
              className="btn-primary w-100 pt-3 pb-3"
              type="submit"
            />
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};


const Approved = ({ show, onHide, title }) => {
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div>
        <span className={ "modal-icon success mb-4" }>
          <AiOutlineCheck/>
        </span>
        <div className="mb-4">
          <h4>
            { title }
          </h4>
        </div>
        <Button 
          text="OK"
          className="btn-primary w-100 pt-3 pb-3"
          type="submit"
          handler={ onHide }
        />
      </div>
    </ModalComponent>
  );
};
