import NewTab from "../../../../images/icons/new_tab.svg";

export const tabs = [
  {
    tab: "products",
    tabTitle: "Products"
  }
];

export const engineering = {
  title: "Engineering",
  headings: [
    {
      Header: "Product Name",
      accessor: "name"
    },
    {
      Header: "Sub Class",
      accessor: "class"
    },
    {
      Header: "Toggle",
      accessor : "toggled"
    },
    {
      Header: "",
      accessor : "cta"
    }
  ],
  body: [
    {
      name: "Boiler", 
      class:"BPV",
      toggled: true,
      cta: "products"
    },
    {
      name: "Contractors Plant & Machinery", 
      class:"CAR",
      toggled: true,
      cta: "products"
    },
    {
      name: "Diteriotiration of Stock", 
      class:"DOS",
      toggled: false,
      cta: "products"
    },
    {
      name: "Erection All Risk", 
      class:"EAR",
      toggled: true,
      cta: "products"
    },
    {
      name: "Electronic All risk", 
      class:"EEI",
      toggled: true,
      cta: "products"
    },
    {
      name: "Machinery Breakdown", 
      class:"MBD",
      toggled: false,
      cta: "products"
    },
    {
      name: "Machinery Breakdown", 
      class:"CPM",
      toggled: false,
      cta: "products"
    }
  ]
};

export const fire = {
  title: "Fire",
  headings: [
    {
      Header: "Product Name",
      accessor: "name"
    },
    {
      Header: "Sub Class",
      accessor: "class"
    },
    {
      Header: "Toggle",
      accessor : "toggled"
    },
    {
      Header: "",
      accessor : "cta"
    }
  ],
  body: [
    {
      name: "Boiler", 
      class:"BPV",
      toggled: true,
      cta: "products"
    },
    {
      name: "Contractors Plant & Machinery", 
      class:"CAR",
      toggled: true,
      cta: "products"
    },
    {
      name: "Diteriotiration of Stock", 
      class:"DOS",
      toggled: false,
      cta: "products"
    },
    {
      name: "Erection All Risk", 
      class:"EAR",
      toggled: true,
      cta: "products"
    },
    {
      name: "Electronic All risk", 
      class:"EEI",
      toggled: false,
      cta: "products"
    },
    {
      name: "Machinery Breakdown", 
      class:"MBD",
      toggled: true,
      cta: "products"
    }
  ]
};

export const liability = {
  title: "Liablity",
  headings: [
    {
      Header: "Product Name",
      accessor: "name"
    },
    {
      Header: "Sub Class",
      accessor: "class"
    },
    {
      Header: "Toggle",
      accessor : "toggled"
    },
    {
      Header: "",
      accessor : "cta"
    }
  ],
  body: [
    {
      name: "Boiler", 
      class:"BPV",
      toggled: false,
      cta: "products"
    },
    {
      name: "Contractors Plant & Machinery", 
      class:"CAR",
      toggled: false,
      cta: "products"
    },
    {
      name: "Diteriotiration of Stock", 
      class:"DOS",
      toggled: true,
      cta: "products"
    },
    {
      name: "Erection All Risk", 
      class:"EAR",
      toggled: true,
      cta: "products"
    },
    {
      name: "Electronic All risk", 
      class:"EEI",
      toggled: true,
      cta: "products"
    },
    {
      name: "Machinery Breakdown", 
      class:"MBD",
      toggled: true,
      cta: "products"
    },
    {
      name: "Machinery Breakdown", 
      class:"CPM",
      toggled: false,
      cta: "products"
    },
    {
      name: "Electronic All risk", 
      class:"EEI",
      toggled: true,
      cta: "products"
    },
    {
      name: "Machinery Breakdown", 
      class:"MBD",
      toggled: true,
      cta: "products"
    },
    {
      name: "Machinery Breakdown", 
      class:"CPM",
      toggled: true,
      cta: "products"
    }
  ]
};

export const life = {
  title: "Life",
  headings: [
    {
      Header: "Product Name",
      accessor: "name"
    },
    {
      Header: "Sub Class",
      accessor: "class"
    },
    {
      Header: "Toggle",
      accessor : "toggled"
    },
    {
      Header: "",
      accessor : "cta"
    }
  ],
  body: [
    {
      name: "Boiler", 
      class:"BPV",
      toggled: true,
      cta: "products"
    },
    {
      name: "Contractors Plant & Machinery", 
      class:"CAR",
      toggled: false,
      cta: "products"
    }
  ]
};

export const marine = {
  title: "Marine",
  headings: [
    {
      Header: "Product Name",
      accessor: "name"
    },
    {
      Header: "Sub Class",
      accessor: "class"
    },
    {
      Header: "Toggle",
      accessor : "toggled"
    },
    {
      Header: "",
      accessor : "cta"
    }
  ],
  body: [
    {
      name: "Boiler", 
      class:"BPV",
      toggled: true,
      cta: "products"
    },
    {
      name: "Contractors Plant & Machinery", 
      class:"CAR",
      toggled: false,
      cta: "products"
    },
    {
      name: "Diteriotiration of Stock", 
      class:"DOS",
      toggled: false,
      cta: "products"
    }
  ]
};

export const medical = {
  title: "Medical",
  headings: [
    {
      Header: "Product Name",
      accessor: "name"
    },
    {
      Header: "Sub Class",
      accessor: "class"
    },
    {
      Header: "Toggle",
      accessor : "toggled"
    },
    {
      Header: "",
      accessor : "cta"
    }
  ],
  body: [
    {
      name: "Boiler", 
      class:"BPV",
      toggled: false,
      cta: "products"
    },
    {
      name: "Boiler", 
      class:"BPV",
      toggled: true,
      cta: "products"
    },
    {
      name: "Boiler", 
      class:"BPV",
      toggled: true,
      cta: "products"
    },
    {
      name: "Boiler", 
      class:"BPV",
      toggled: true,
      cta: "products"
    },
    {
      name: "Boiler", 
      class:"BPV",
      toggled: false,
      cta: "products"
    }
  ]
};

export const misc = {
  title: "Misc",
  headings: [
    {
      Header: "Product Name",
      accessor: "name"
    },
    {
      Header: "Sub Class",
      accessor: "class"
    },
    {
      Header: "Toggle",
      accessor : "toggled"
    },
    {
      Header: "",
      accessor : "cta"
    }
  ],
  body: [
    {
      name: "Boiler", 
      class:"BPV",
      toggled: true,
      cta: "products"
    },
    {
      name: "Contractors Plant & Machinery", 
      class:"CAR",
      toggled: true,
      cta: "products"
    },
    {
      name: "Diteriotiration of Stock", 
      class:"DOS",
      toggled: true,
      cta: "products"
    },
    {
      name: "Erection All Risk", 
      class:"EAR",
      toggled: true,
      cta: "products"
    },
    {
      name: "Electronic All risk", 
      class:"EEI",
      toggled: false,
      cta: "products"
    },
    {
      name: "Machinery Breakdown", 
      class:"MBD",
      toggled: false,
      cta: "products"
    },
    {
      name: "Machinery Breakdown", 
      class:"CPM",
      toggled: false,
      cta: "products"
    },
    {
      name: "Machinery Breakdown", 
      class:"CPM",
      toggled: false,
      cta: "products"
    },
    {
      name: "Machinery Breakdown", 
      class:"CPM",
      toggled: false,
      cta: "products"
    },
    {
      name: "Machinery Breakdown", 
      class:"CPM",
      toggled: true,
      cta: "products"
    }
  ]
};

export const motor = {
  title: "Motor",
  headings: [
    {
      Header: "Product Name",
      accessor: "name"
    },
    {
      Header: "Sub Class",
      accessor: "class"
    },
    {
      Header: "Toggle",
      accessor : "toggled"
    },
    {
      Header: "",
      accessor : "cta"
    }
  ],
  body: [
    {
      name: "Boiler", 
      class:"BPV",
      toggled: true,
      cta: "products"
    }
  ]
};

