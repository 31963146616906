import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineFile } from "react-icons/ai";
import { FiUploadCloud } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import s from "./index.module.scss";
import InputLayout from "../Layout";

const AttachmentInput = ({ value={}, setValue=()=>{}, label = "", placeholder="", inline = true, readOnly = false, noLabel = false }) => {
  const [fileDropped, setFileDropped] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file) => {
      setValue(file);
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = (e) => {
      };
      setFileDropped(true);
    });
  }, []);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({ 
    accept: "image/jpeg, image/png, .pdf",
    disabled: readOnly, 
    multiple: true,
    onDrop
  });

  const acceptFiles = acceptedFiles.map(file => (
    <li key={ file.path }>
      <p className="mb-0">
        { file.path } 
      </p>
      <p className="mb-0">
        { Math.round(file.size / 1024) } KB
        <span className="d-block">uploaded</span>
      </p>
    </li>
  ));

  const rejectFiles = fileRejections.map(({ file, errors }) => (
    <li key={ file.path }>
      { file.path } { file.size } bytes
      <ul>
        { errors.map(e => (
          <li key={ e.code }>{ e.message }</li>
        )) }
      </ul>
    </li>
  ));

  return (
    <InputLayout label={ label } inline = { inline } noLabel={ noLabel }>
      <div { ...getRootProps({ className: "dropzone drag-n-drop" }) } className={ s.attachment_wrapper }>
        <input { ...getInputProps() } id={ label } name={ label } readOnly = { readOnly }/>
        { fileDropped ? <HasInput acceptFiles={ acceptFiles } rejectFiles={ rejectFiles }/> : <NoInput/> }
      </div>
    </InputLayout>
  );
};

export default AttachmentInput;

const NoInput = () => {
  return(
    <div className={ s.no_input }>
      <span> <FiUploadCloud/> </span> 
      <p>Click to upload or drag and drop</p>
      <p>PDF, Word, SVG, PNG, JPG</p>
    </div>
  );
};

const HasInput = (  { acceptFiles, rejectFiles } )=>{
  return(
    <div className={ s.has_input }>
      <aside>
        {
          acceptFiles ? <article className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <span className="d-flex justify-content-center align-items-center"><AiOutlineFile/></span>
              <ul className="mb-0">{ acceptFiles }</ul>
            </div>
            <div>
              <RiDeleteBinLine/>
            </div>
          </article>  :
            rejectFiles ? <ul>Rejected { rejectFiles }</ul> : 
              null
        }
      </aside>
    </div>
  );
};
