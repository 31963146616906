import React from "react";
import { Route, Routes } from "react-router-dom";
import EditEndorsement from "../../pages/Endorsments/Edit";
import EndorsementsHomePage from "../../pages/Endorsments/List";
import NewEndorsement from "../../pages/Endorsments/New";
import ViewEndorsement from "../../pages/Endorsments/View";

const EndorsementRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={ <EndorsementsHomePage /> } />
      <Route path="/new" element={ <NewEndorsement /> } />
      <Route path="/:id" element={ <ViewEndorsement /> } />
      <Route path="/:id/edit" element={ <EditEndorsement /> } />
    </Routes>
  );
};

export default EndorsementRoutes;
