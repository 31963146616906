import React, { useState } from "react";
import s from "./index.module.scss";
import timePeriod from "./utils/timePeriod";

const TimePeriodFilter = ({ noQuarter = false }) => {
  const [filterIndex, setFilterIndex] = useState(0);

  return (
    <div className={ `${s.date_filter_wrapper} d-flex justify-content-center-align-items-center` }>
      {
        timePeriod.map(({ period }, idx)=>{  
          if(noQuarter && period === "Last 3 Months"){
            return null;
          }
          return (
            <button 
              key={ idx }
              className = { `mb-0 py-2 px-0 text-center ${idx === filterIndex ? s.active : ""}` }
              onClick={ ()=> setFilterIndex(idx) }
            >
              { period }
            </button>
          );
        })
      }
    </div>
  );
};

export default TimePeriodFilter;
