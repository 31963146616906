import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateInsurers from "../../pages/Insurers/Create";
import EditInsurer from "../../pages/Insurers/Edit";
import InsurersPage from "../../pages/Insurers/List";
import ViewInsurer from "../../pages/Insurers/View";

const InsurerRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={ <InsurersPage /> } />
      <Route path="/create" element={ <CreateInsurers /> } />
      <Route path="/:id" element={ <ViewInsurer /> } />
      <Route path="/:id//edit" element={ <CreateInsurers /> } />
    </Routes>
  );
};

export default InsurerRoutes;
