export const policies = {
  title: "Policies",  
  headings:[
    {
      Header: "Policy Number",
      accessor: "policyNumber"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Period",
      accessor: "period"
    },
    {
      Header: "GWP",
      accessor: "gwp"
    },
    {
      Header: "Net Comm",
      accessor: "netComm"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      period: <div className="desc">
        <p>2022-01-01</p>
        <p>2022-12-31</p>
      </div>, 
      gwp: "123,450",
      netComm: "1.234",
      status: <span className="received">active</span>,
      cta: "policies"
    },
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      period: <div className="desc">
        <p>2022-01-01</p>
        <p>2022-12-31</p>
      </div>, 
      gwp: "123,450",
      netComm: "1.234",
      status: <span className="received">active</span>,
      cta: "policies"
    },
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      period: <div className="desc">
        <p>2022-01-01</p>
        <p>2022-12-31</p>
      </div>, 
      gwp: "123,450",
      netComm: "1.234",
      status: <span className="received">active</span>,
      cta: "policies"
    },
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      period: <div className="desc">
        <p>2022-01-01</p>
        <p>2022-12-31</p>
      </div>, 
      gwp: "123,450",
      netComm: "1.234",
      status: <span className="received">active</span>,
      cta: "policies"
    },
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      period: <div className="desc">
        <p>2022-01-01</p>
        <p>2022-12-31</p>
      </div>, 
      gwp: "123,450",
      netComm: "1.234",
      status: <span className="received">active</span>,
      cta: "policies"
    }
  ]
};
  
export const claims = {
  title: "Claims",
  headings:[
    {
      Header: "Policy Number",
      accessor: "policyNumber"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Claim Type",
      accessor: "claimType"
    },
    {
      Header: "Claim Channel",
      accessor: "claimChannel"
    },
    {
      Header: "Request Amount",
      accessor: "requestAmount"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      claimType: "<type1>",
      claimChannel: "App",
      requestAmount: "1,234",
      status: <span className="received">paid</span>,
      cta: "claims"
    },
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      claimType: "<type1>",
      claimChannel: "App",
      requestAmount: "1,234",
      status: <span className="received">paid</span>,
      cta: "claims"
    },
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      claimType: "<type1>",
      claimChannel: "App",
      requestAmount: "1,234",
      status: <span className="received">paid</span>,
      cta: "claims"
    },
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      claimType: "<type1>",
      claimChannel: "App",
      requestAmount: "1,234",
      status: <span className="received">paid</span>,
      cta: "claims"
    },
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      claimType: "<type1>",
      claimChannel: "App",
      requestAmount: "1,234",
      status: <span className="received">paid</span>,
      cta: "claims"
    }
  ]
};
  
export const endorsements = {
  title: "Endorsements",
  headings:[
    {
      Header: "Policy Number",
      accessor: "policyNumber"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Channel",
      accessor: "channel"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      type: "<type1>",
      channel: "App",
      requestAmount: "1,234",
      status: <span className="received">paid</span>,
      cta: "claims"
    },
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      type: "<type1>",
      channel: "App",
      requestAmount: "1,234",
      status: <span className="received">paid</span>,
      cta: "claims"
    },
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      type: "<type1>",
      channel: "App",
      requestAmount: "1,234",
      status: <span className="received">paid</span>,
      cta: "claims"
    },
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      type: "<type1>",
      channel: "App",
      requestAmount: "1,234",
      status: <span className="received">paid</span>,
      cta: "claims"
    },
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      type: "<type1>",
      channel: "App",
      requestAmount: "1,234",
      status: <span className="received">paid</span>,
      cta: "claims"
    },
    {
      policyNumber:  "REF1234567890", 
      product: <div className="desc">
        <p>Fire</p>
        <p>Business Interruption</p>
      </div>,
      type: "<type1>",
      channel: "App",
      requestAmount: "1,234",
      status: <span className="received">paid</span>,
      cta: "claims"
    }
  ]
};
  
