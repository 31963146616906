import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";


const PoliciesManage = () => {
  let { id, action } = useParams();

  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    if (!id) {
      setPageTitle("Create Policy");
      // display blank form
      return;
    }
    if (id && !action) {
      setPageTitle(`View Policy - ${id}`);
      // fetch the item and view only
      return;
    }
    if (id && action === "edit") {
      setPageTitle(`Edit Policy - ${id}`);
      // fetch the item and display edit
    }
  }, []);


  return (
    <div>
      <h1>{ pageTitle }</h1>
    </div>
  );
};

export default PoliciesManage;
