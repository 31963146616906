import React from "react";
import { Route, Routes } from "react-router-dom";
import PoliciesHomePage from "../../pages/Policies/List";
import PoliciesManage from "../../pages/Policies/Manage";
import ViewPolicy from "../../pages/Policies/View";

const PolicyRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={ <PoliciesHomePage /> } />
      <Route path="/create" element={ <PoliciesManage /> } exact />
      <Route path="/view/:id" element={ <ViewPolicy /> } exact />
      <Route path="/:id" element={ <PoliciesManage /> } exact />
      <Route path="/:id/:action" element={ <PoliciesManage /> } exact />
    </Routes>
  );
};

export default PolicyRoutes;
