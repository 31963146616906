import React, { PureComponent } from "react";
import { Bar, BarChart,CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import s from "./index.module.scss";

const data = [
  {
    name: "Jan",
    GWP: 4000,
    NetCommission: 3200
  },
  {
    name: "Feb",
    GWP: 4000,
    NetCommission: 1600
  },
  {
    name: "Mar",
    GWP: 4000,
    NetCommission: 2600
  },
  {
    name: "April",
    GWP: 4000,
    NetCommission: 1600
  },
  {
    name: "May",
    GWP: 4800,
    NetCommission: 2800
  },
  {
    name: "Jun",
    GWP: 4000,
    NetCommission: 2400
  },
  {
    name: "Jul",
    GWP: 4000,
    NetCommission: 1600
  },
  {
    name: "Aug",
    GWP: 4000,
    NetCommission: 2400
  },
  {
    name: "Sep",
    GWP: 4000,
    NetCommission: 1600
  },
  {
    name: "Oct",
    GWP: 4000,
    NetCommission: 1600
  },
  {
    name: "Nov",
    GWP: 4000,
    NetCommission: 2400
  },
  {
    name: "Dec",
    GWP: 4800,
    NetCommission: 2800
  }
];

const BarChartStats = () => {
  return (
    <div className={ `${s.bar_chart_container}` }>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={ 500 }
          height={ 300 }
          data={ data }
          barSize = { 32 }
          margin={ {
            top: 20,
            right: 30,
            left: 20,
            bottom: 5
          } }
        >
          <CartesianGrid vertical={ false } horizontal={ true } strokeDasharray="2 1 2" legendType="cross"/>
          <XAxis dataKey="name" />
          <Tooltip />
          <Legend align="right" verticalAlign="top" iconType="circle"/>
          <Bar dataKey="GWP" stackId="a" fill="#9E77ED" />
          <Bar dataKey="NetCommission" stackId="a" fill="#D6BBFB" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};


export default BarChartStats;
