import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateClient from "../../pages/Clients/Create";
import EditClient from "../../pages/Clients/Edit";
import ClientsPage from "../../pages/Clients/List";
import ViewClient from "../../pages/Clients/View";

const ClientRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={ <ClientsPage /> } />
      <Route path="/create" element={ <CreateClient /> } />
      <Route path="/:id" element={ <ViewClient /> } />
      <Route path="/:id/edit" element={ <EditClient /> } />
    </Routes>
  );
};

export default ClientRoutes;
