import React from "react";
import { Route, Routes } from "react-router-dom";
import EditClaim from "../../pages/Claims/Edit";
import ClaimsHomePage from "../../pages/Claims/List";
import NewClaim from "../../pages/Claims/New";
import ViewClaim from "../../pages/Claims/View";

const ClaimRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={ <ClaimsHomePage /> } />
      <Route path="/new" element={ <NewClaim /> } />
      <Route path="/:id" element={ <ViewClaim /> } />
      <Route path="/:id/edit" element={ <EditClaim /> } />
    </Routes>
  );
};

export default ClaimRoutes;
