import React from "react";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import {  Line, LineChart, ResponsiveContainer } from "recharts";

import s from "./index.module.scss";


const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
];

const DataCard = ({ title = "", stats = false, increased = true, value = "", percentage = "" }) => {
  return (
    <div className={ s.data_card }>
      <div>
        <div className={ `${s.title} mb-4` }>
          <h3>
            { title }
          </h3>
        </div>
        <div className="row justify-content-between">
          <div className="col-6">
            <div className={ s.body_text }>
              <h3>
                { value }
              </h3>
              { stats && <p className="mb-0 d-flex align-items-end">
                <span  className={ `d-flex justify-content-center align-items-end me-1 
                  ${increased ? s.increased : s.decreased}` }
                >
                  {
                    increased ?
                      <span>
                        <AiOutlineArrowUp/>
                      </span> :
                      <span>
                        <AiOutlineArrowDown/>
                      </span>
                  }
                  { percentage }%
                </span> vs last month
              </p> }
            </div>
          </div>
          <div className="col-5">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={ 400 }
                height={ 400 }
                data={ data }
              >
                <Line
                  type="monotone"
                  dataKey="uv"
                  stroke={ increased ? "#12B76A" : "#F04438" }
                  yAxisId={ 0 }
                  dot={ false }/>
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataCard;
