import React from "react";
import SearchInput from "../../../../components/Inputs/SearchInput";
import TimePeriodFilter from "../../../../components/TimePeriodFilter";

const Filter = ({ noQuarter = false }) => {
  return (
    <div className="row justify-content-between align-items-center">
      <div className="col-4">
        <SearchInput placeholder="Search" inline={ false }/>
      </div> 
      <div className="col-6">
        <TimePeriodFilter noQuarter={ noQuarter }/>
      </div> 
    </div>
  );

};

export default Filter;
