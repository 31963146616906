import NewTab from "../../../../images/icons/new_tab.svg";

export const cardData = [
  {
    title: "Pending Approvals",
    value: "23",
    percentage: 40,
    increased: true,
    showStat: true
  },
  {
    title: "Pending Quotations",
    value: "32",
    percentage: 10,
    increased: true,
    showStat: true
  },
  {
    title: "Number of Lost Businesses",
    value: "3",
    percentage: 20,
    increased: false,
    showStat: true
  }
]
;

export const tabs = [
  {
    noQuarter: false,
    tab: "drafts",
    tabTitle: "Drafts"
  },
  {
    noQuarter: false,
    tab: "new-quotations",
    tabTitle: "New Quotations"
  },
  {
    noQuarter: true,
    tab: "pending-approvals",
    tabTitle: "Pending Approvals"
  },
  {
    noQuarter: true,
    tab: "approved",
    tabTitle: "Approved"
  },
  {
    noQuarter: true,
    tab: "rejected",
    tabTitle: "Rejected"
  },
  {
    noQuarter: true,
    tab: "sent-to-insurers",
    tabTitle: "Sent to Insurers"
  },
  {
    noQuarter: true,
    tab: "premiums-received",
    tabTitle: "Premiums Received"
  },
  {
    noQuarter: true,
    tab: "lost-business",
    tabTitle: "Lost Business"
  }
];

export const drafts = {
  headings:[
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Main Class",
      accessor: "mainClass"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Period",
      accessor: "period"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      mainClass: "FIRE", 
      product: "WORKMENS COMPENSATION",  
      period: <div className="desc">
        <p>01-06-2021 to 31-05-2022</p>
        <p>Both days inclusive</p>
      </div>,
      status: <div className="pending">draft</div>,
      type: "New Business",
      cta: "quotations"
    }
  ]
};

export const newQuotations = {
  headings:[
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Main Class",
      accessor: "mainClass"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Period",
      accessor: "period"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      mainClass: "FIRE", 
      product: "WORKMENS COMPENSATION",  
      period: <div className="desc">
        <p>01-06-2021 to 31-05-2022</p>
        <p>Both days inclusive</p>
      </div>,
      status: <div className="pending">new</div>,
      type: "New Business",
      cta: "quotations"
    }
  ]
};

export const pendingApprovals = {
  headings:[
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Main Class",
      accessor: "mainClass"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Period",
      accessor: "period"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      mainClass: "FIRE", 
      product: "WORKMENS COMPENSATION",  
      period: <div className="desc">
        <p>01-06-2021 to 31-05-2022</p>
        <p>Both days inclusive</p>
      </div>,
      status: <div className="pending">pending approval</div>,
      type: "New Business",
      cta: "quotations"
    }
  ]
};

export const approved = {
  headings:[
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Main Class",
      accessor: "mainClass"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Period",
      accessor: "period"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      mainClass: "FIRE", 
      product: "WORKMENS COMPENSATION",  
      period: <div className="desc">
        <p>01-06-2021 to 31-05-2022</p>
        <p>Both days inclusive</p>
      </div>,
      status: <div className="received">approved</div>,
      type: "New Business",
      cta: "quotations"
    }
  ]
};

export const rejected = {
  headings:[
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Main Class",
      accessor: "mainClass"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Period",
      accessor: "period"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      mainClass: "FIRE", 
      product: "WORKMENS COMPENSATION",  
      period: <div className="desc">
        <p>01-06-2021 to 31-05-2022</p>
        <p>Both days inclusive</p>
      </div>,
      status: <div className="rejected">rejected</div>,
      type: "New Business",
      cta: "quotations"
    }
  ]
};

export const sentToInsurers = {
  headings:[
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Main Class",
      accessor: "mainClass"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Period",
      accessor: "period"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      mainClass: "FIRE", 
      product: "WORKMENS COMPENSATION",  
      period: <div className="desc">
        <p>01-06-2021 to 31-05-2022</p>
        <p>Both days inclusive</p>
      </div>,
      status: <div className="sent">Sent to Insurer</div>,
      type: "New Business",
      cta: "quotations"
    }
  ]
};

export const premiumsReceived = {
  headings:[
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Main Class",
      accessor: "mainClass"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Period",
      accessor: "period"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      mainClass: "FIRE", 
      product: "WORKMENS COMPENSATION",  
      period: <div className="desc">
        <p>01-06-2021 to 31-05-2022</p>
        <p>Both days inclusive</p>
      </div>,
      status: <div className="p-received">premium received</div>,
      type: "New Business",
      cta: "quotations"
    }
  ]
};

export const lostBusiness = {
  headings:[
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Main Class",
      accessor: "mainClass"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Period",
      accessor: "period"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      mainClass: "FIRE", 
      product: "WORKMENS COMPENSATION",  
      period: <div className="desc">
        <p>01-06-2021 to 31-05-2022</p>
        <p>Both days inclusive</p>
      </div>,
      status: <div className="pending">Lost</div>,
      type: "New Business",
      cta: "quotations"
    }
  ]
};
