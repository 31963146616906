import NewTab from "../../../images/icons/new_tab.svg";

export const cardData = [
  {
    title: "Total GWP (LKR mn)",
    value: "102.42",
    percentage: 40,
    increased: true,
    showStat: true
  },
  {
    title: "Net Commission (LKR mn)",
    value: "10.3",
    percentage: 10,
    increased: true,
    showStat: true
  },
  {
    title: "Average GWP (LKR)",
    value: "89,450",
    percentage: 20,
    increased: false,
    showStat: true
  }
];

export const headings = [
  {
    heading: "Company"
  },
  {
    heading: "Toal GWP (LKR)"
  },
  {
    heading: "Net Commission (LKR)"
  },
  {
    heading: "Assignee"
  }
];

export const body = [
  [
    "Dialog Axiata PLC (DAP)...", "3,530,890", "150,450",  "John Smith"
  ],
  [
    "Dialog Axiata PLC (DAP)...", "3,530,890", "150,450",  "John Smith"
  ],
  [
    "Dialog Axiata PLC (DAP)...", "3,530,890", "150,450",  "John Smith"
  ],
  [
    "Dialog Axiata PLC (DAP)...", "3,530,890", "150,450",  "John Smith"
  ],
  [
    "Dialog Axiata PLC (DAP)...", "3,530,890", "150,450",  "John Smith"
  ],
  [
    "Dialog Axiata PLC (DAP)...", "3,530,890", "150,450",  "John Smith"
  ],
  [
    "Dialog Axiata PLC (DAP)...", "3,530,890", "150,450",  "John Smith"
  ],
  [
    "Dialog Axiata PLC (DAP)...", "3,530,890", "150,450",  "John Smith"
  ],
  [
    "Dialog Axiata PLC (DAP)...", "3,530,890", "150,450",  "John Smith"
  ],
  [
    "Dialog Axiata PLC (DAP)...", "3,530,890", "150,450",  "John Smith"
  ],
  [
    "Dialog Axiata PLC (DAP)...", "3,530,890", "150,450",  "John Smith"
  ],
  [
    "Dialog Axiata PLC (DAP)...", "3,530,890", "150,450",  "John Smith"
  ]
];

export const headingsTwo = [
  {
    Header: "Company",
    accessor: "company"
  },
  {
    Header: "Toal GWP (LKR)",
    accessor: "gwp"
  },
  {
    Header: "Net Commission (LKR)",
    accessor: "commission"
  },
  {
    Header: "Assignee",
    accessor: "assignee"
  },
  {
    Header: "",
    accessor: "cta"
  }

];

export const bodyTwo = [
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  },
  {
    company:  "Dialog Axiata PLC (DAP)...", 
    gwp: "3,530,890", 
    commission: "150,450",  
    assignee: "John Smith",
    cta: <div>
      <img src={ NewTab } alt="New tab" className="me-2"/>
    </div>
  }
];
