import * as React from "react";
import {  Route, Routes } from "react-router-dom";
import DashboardRoutes from "./DashboardRoutes";
import LoginPage from "../pages/LoginPage";
import NotFoundPage from "../pages/NotFoundPage";

export default function RouteList() {
  return (
    <>
      <Routes>
        <Route path="/login" element={ <LoginPage /> } />
        <Route path="/*" element={ <DashboardRoutes /> } />
        <Route path="*" element={ <NotFoundPage /> } />
      </Routes>
    </>
  );
}

