import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { confirmed, newEndorsement, sentToClient, sentToInsurer, settled, tabs } from "./utils/data";
import Button from "../../../components/Button";
import SearchInput from "../../../components/Inputs/SearchInput";
import PageSubNav from "../../../components/PageSubNav";
import PageTitle from "../../../components/PageTitle";
import ReactTable from "../../../components/TableComponent";

const EndorsementsHomePage = () => {
  const [table, setTable] = useState(newEndorsement);
  const [type] = useQueryParam("type", StringParam);
  const navigate = useNavigate();

  useEffect(()=>{
    if(type === "new" || type === undefined){
      setTable(newEndorsement);
    }else if(type === "sent to insurer"){
      setTable(sentToInsurer);
    }else if(type === "confirmed"){
      setTable(confirmed);
    }else if(type === "sent to client"){
      setTable(sentToClient);
    }else{
      setTable(settled);
    }
  }, [type]);

  return (
    <div className="body-two">
      <div className="mb-4">
        <PageTitle title="Endorsements"/>
      </div>
      <div className="mb-4">
        <PageSubNav list={ tabs.map(item => ({ label: item.tabTitle, value: item.tab })) }/>
      </div>
      { /* TABLE FILTER */ }
      <div className="mb-4 row align-items-end justify-content-between">
        <div className="col-4">
          <SearchInput placeholder="Search" inline={ false }/>
        </div>
        <div className="col-4">
          <Button 
            icon="plus" 
            text="New Endorsement" 
            className="btn-primary ms-auto"
            handler={ () => navigate("/endorsements/new") }
          />
        </div>
      </div>
      <ReactTable columns={ table.headings } data={ table.body }/>
    </div>
  );
};

export default EndorsementsHomePage;
