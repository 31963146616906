import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import s from "./index.module.scss";
import { claims, endorsements, premium } from "./utils/data";
import Button from "../../../components/Button";
import AttachmentInput from "../../../components/Inputs/AttachmentInput";
import DateInput from "../../../components/Inputs/DateInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import PageSubTitle from "../../../components/PageSubTitle";
import ReactTable from "../../../components/TableComponent";
import { useAuth } from "../../../contexts/Auth";
import NewTab from "../../../images/icons/new_tab.svg";

const ViewPolicy = () => {
  const [tables, setTables] = useState([
    { ...claims },
    { ...endorsements },
    { ...premium }
  ]);
  const [showFull, setShowFull] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();

  const { api } = useAuth();
  
  const { isLoading, error, data } = useQuery({
    queryFn: () =>
      api(`policy/get-by-req-key?reqKey=${id}`),
    queryKey: ["policy"]
  });
  console.log("POLICY DATA: ", data);

  const options = [
    {
      key: "",
      value: "Please Select"
    },
    {
      key: "one",
      value: "One"
    },
    {
      key: "two",
      value: "Two"
    }
  ];

  return (
    <section className="body">
      { /* HEADER */ }
      <article className="d-flex justify-content-between align-items-end mb-4">
        <div>
          <PageSubTitle title="View Policy"/>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          {
            !showFull && <Button 
              icon="back" 
              text="All Policies" 
              className="btn-secondary me-2" 
              handler={ ()=>navigate("/policies") }
            />
          }
          {
            showFull && <Button 
              icon="back" 
              text="Back" 
              className="btn-secondary me-2" 
              handler={ ()=>setShowFull(false) }
            />
          }
          {
            !showFull && <img 
              src={ NewTab } 
              alt="New tab"
              role="presentation"
              className="mx-3"
              onClick={ ()=>setShowFull(true) }
            />
          }
          <Button 
            icon="plus" 
            text="Add Endorsement" 
            className="btn-secondary me-2" 
            handler={ ()=>navigate("/endorsements/new") }
          />
          <Button 
            icon="plus" 
            text="Add Claim"  
            className="btn-secondary me-2" 
            handler={ ()=>navigate("/claims/new") }
          />
          <Button icon="refresh" text="Renew" className="btn-secondary"/>
        </div>
      </article>

      { /* FORMS */ }
      <article>
        <form action="">
          <article>
            <div>
              <TextInput 
                options={ options } 
                label="Reference" 
                placeholder="REF1234567890"
                value={ data?.fk_quote_ref }
                readOnly={ true }
              />
            </div>
            <div>
              <TextInput 
                options={ options } 
                label="Policy Number" 
                placeholder="PLCY234912"
                value={ data?.policy_number }
                readOnly={ true }
              />
            </div>
            <div>
              <AttachmentInput label="Policy Document" readOnly={ readOnly }/>
            </div>
            <div>
              <SelectInput 
                options={ [
                  {
                    key: "",
                    value: data?.mainClass
                  }
                ] } 
                label="Main Class"
                value={ data?.mainClass }
                placeholder={ data?.mainClass }
                readOnly={ true }
              />
            </div>
            <div>
              <SelectInput 
                options={ [
                  {
                    key: "",
                    value: data?.product
                  }
                ] } 
                label="Product"
                readOnly={ true }
              />
            </div>
            <div>
              <TextInput 
                placeholder="DIALOG" 
                label="THE INSURED"
                value={ data?.insured }
                readOnly={ true }
              />
            </div>
            <div>
              <TextInput 
                type="number" 
                placeholder="2,000,000.00" 
                label="Sum Insured"
                value={ data?.sum_insured }
                readOnly={ true }
              />
            </div>
            <div>
              <TextInput type="number" placeholder="220,000" label="GROSS WRITTEN PREMIUM"/>
            </div>
            <div className="row justify-content-between align-items-center mb-4">
              <p className="mb-0 col-3 label">
                period of insurance
              </p>
              <div className="col-1">:</div>
              <div className="d-flex justify-content-between align-items-center col-8">
                <DateInput inline={ false } label="Start Date" noLabel={ true } placeholder="Start Date"/>
                <DateInput inline={ false } label="Start Date" noLabel={ true } placeholder="End Date"/>
                <TextInput type="checkbox" inline={ false } label="Both days inclusive"/>
              </div>
            </div>
            <div>
              <SelectInput 
                options={ [
                  {
                    key: "",
                    value: data?.insurer
                  }
                ] } 
                label="INSURER"
                readOnly={ true }
              />
            </div>
          </article>
          <article className="mt-4 p-4">
            <h3 className="mb-4">
              Commisions
            </h3>
            <div>
              <div>
                <TextInput 
                  options={ options } 
                  label="COMMISSION %"
                  placeholder="13.5"
                  value={ data?.commission }
                  readOnly={ true }
                />
              </div>
              <div>
                <TextInput 
                  type="number" 
                  placeholder="29,700" 
                  label="EXPECTED COMMISSION AMOUNT"
                  value={ data?.excepted_commission }
                  readOnly={ true }
                />
              </div>
              <div>
                <TextInput 
                  type="number" 
                  placeholder="29,700" 
                  label="ACTUAL COMMISSION RECEIVED"
                  value={ data?.commission_received }
                  readOnly={ true }
                />
              </div>
            </div>

            <article className="mt-4 p-4">
              <h4 className="mb-4">
                Commissions paid
              </h4>
              <div>
                <div>
                  <TextInput 
                    type="number" 
                    placeholder="29,700" 
                    label="COMMISSIONS PAID OUT"
                    value={ data?.commission_paid_out }
                    readOnly={ true }
                  />
                </div>
                <div>
                  <TextInput 
                    type="textarea" 
                    label="COMMISSION PAID TO"
                    value={ data?.commission_paid_to }
                    readOnly={ true }
                  />
                </div>
              </div>
            </article>
          </article>
        </form>
      </article>

      { /* FULL DETAILS */ }
      {
        showFull && tables.map((table, idx)=>{
          return(
            <div key={ idx } className={ `${s.single_table} mb-5` }>
              <div className={ `px-4 pt-4 pb-2 ${s.header} d-flex justify-content-between align-itesm-center` }>
                <h3 className="d-flex justify-content-start align-items-center">
                  { table.title }
                  {
                    table.title.toLowerCase() !== "premium" && <span className="ms-2 sent">
                      { table.total } { table.title.toLowerCase() === "endorsements" ? "Endorsements" : "Claims" }
                    </span>
                  }
                </h3>
                {
                  table.title.toLowerCase() !== "premium" && 
                    <Button 
                      icon ="next"
                      className="ms-2 sent btn-secondary mb-2" 
                      text = { 
                        table.title.toLowerCase() === "endorsements" ? "View all Endorsements" : "View all Claims" 
                      }
                      handler={ ()=>{
                        table.title.toLowerCase() === "endorsements" ? 
                          navigate("/endorsements") 
                          : 
                          navigate("/claims"); 
                      } }
                    />
                }
              </div>
              <div>
                {
                  <ReactTable columns={ table.headings } data={ table.body }/>
                }
              </div>
            </div>
          );
        })
      }
      {
        showFull && <article>
          <div>
            <h3 className="mb-4">
              Quotation details
            </h3>

            <div>
              <AttachmentInput label="closing slip"/>
              <div className="row justify-content-between align-items-center mb-4">
                <p className="mb-0 col-3 label">
                  other documents
                </p>
                <div className="col-1">:</div>
                <div className="d-flex justify-content-between align-items-center col-8">
                  <div className="me-1">
                    <AttachmentInput label="Other Document One" noLabel={ true } inline={ false }/>
                  </div>
                  <div className="me-1">
                    <AttachmentInput label="Other Document Two" noLabel={ true } inline={ false }/>
                  </div>
                  <div>
                    <AttachmentInput label="Other Document Three" noLabel={ true } inline={ false }/>
                  </div>
                </div>
              </div>
              <div className="mulitple-inputs">
              </div>
              <AttachmentInput label="final quotation"/>
            </div>
          </div>
        </article>
      }
      <div>

      </div>
    </section>
  );
};

export default ViewPolicy;
