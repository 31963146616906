import React from "react";
import { Route, Routes } from "react-router-dom";
import EditProduct from "../../pages/Products/Edit";
import ProductsPage from "../../pages/Products/List";
import NewProduct from "../../pages/Products/New";
import ViewProduct from "../../pages/Products/View";

const ProductRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={ <ProductsPage /> } />
      <Route path="/new" element={ <NewProduct /> } />
      <Route path="/:id" element={ <ViewProduct /> } />
      <Route path="/:id/edit" element={ <EditProduct /> } />
    </Routes>
  );
};

export default ProductRoutes;
