import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import s from "./index.module.scss";
import Button from "../../../components/Button";
import RadioInput from "../../../components/Inputs/RadioInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import PageTitle from "../../../components/PageTitle";


const ViewClient = () => {
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(false);

  const options=[
    {
      key: "",
      value: "Select responsible person"
    },
    {
      key: "one",
      value: "One"
    },
    {
      key: "two",
      value: "Two"
    }
  ];

  const radioOptions = [
    {
      key: "Yes",
      value: "Yes"
    },
    {
      key: "No",
      value: "No"
    }
  ];

  return(
    <div className="">
      <div className="mb-4">
        <PageTitle title="Clients"/>
      </div>
      <div className="mb-5">
        <Button text="back" icon="back" handler={ ()=>navigate(-1) }/>
      </div>
      <form action="" className={ `${s.create_client_form} ps-4 ms-4 pb-4` }>
        <div className="mb-2">
          <TextInput label="Client name" type="text" inline={ true } placeholder="Dialog PLC" readOnly={ readOnly }/>
        </div>
        <div className="mb-2">
          <RadioInput label="Master Client"  inline={ true } options={ radioOptions }/>
        </div>
        <div className="mb-2"> 
          <TextInput 
            label="Summary" 
            type="text" 
            inline={ true } 
            placeholder="Telecommunication Company" 
            readOnly={ readOnly }
          />
        </div>
        <div className="mb-2"> 
          <TextInput label="Description" type="text" inline={ true } readOnly={ readOnly } 
            placeholder="Dialog is sri lanka’s largest telecommunication company. "
          />
        </div>
        <div className="mb-2"> 
          <TextInput 
            label="Nature of business" 
            type="text" 
            inline={ true } 
            placeholder="Corporate" 
            readOnly={ readOnly }
          />
        </div>
        <div className="row justify-content-between align-items-center mb-4">
          <p className="mb-0 col-3 label">
            VAT Registered
          </p>
          <div className="col-1">:</div>
          <div className="d-flex justify-content-between align-items-center col-8">
            <div>
              <input type="radio" id="Yes" name="VAT Registered" value="Yes"/>
              <label htmlFor="Yes" className="ps-2">Yes</label>
            </div>

            <div>
              <input type="radio" id="No" name="VAT Registered" value="No"/>
              <label htmlFor="No" className="ps-2">No</label>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <input type="radio" id="VAT Number" name="VAT Number" value="VAT Number"/>
              <div className="ms-2">
                <TextInput type="text" label="VAT Number" noLabel={ true } inline={ false } placeholder="Enter VAT Number"/>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-2"> 
          <SelectInput label="Responsible Person" options={ options } inline={ true } readOnly={ readOnly }/>
        </div>
        <div className="mb-2"> 
          <TextInput 
            label="Client Address" 
            type="text" 
            inline={ true } 
            placeholder="Please enter" 
            readOnly={ readOnly }
          />
        </div>
        <div className="mb-2"> 
          <TextInput 
            label="Primary Contact Person Name" 
            type="text" 
            inline={ true } 
            placeholder="Please enter" 
            readOnly={ readOnly }
          />
        </div>
        <div className="mb-2"> 
          <TextInput 
            label="Primary Contact Person Email" 
            type="text" 
            inline={ true } 
            placeholder="Please enter" 
            readOnly={ readOnly }
          />
        </div>
        <div className="mb-2"> 
          <TextInput 
            label="Primary Contact Person Phone" 
            type="text" 
            inline={ true } 
            placeholder="Please enter" 
            readOnly={ readOnly }
          />
        </div>

        <div className="d-flex justify-content-end mt-4">
          <Button text="cancel" className="btn-secondary me-2"/>
          <Button text="update" type="submit" className="btn-primary"/>
        </div>
      </form>
    </div>
  );
};

export default ViewClient;
