import React, { useState } from "react";
import Button from "../../../../components/Button";
import DateInput from "../../../../components/Inputs/DateInput";
import SelectInput from "../../../../components/Inputs/SelectInput";

const Filter = ({ 
  startDate="", 
  setStartDate="", 
  endDate="", 
  setEndDate="", 
  client="", 
  setClient="",
  handleFilter=()=>{}
}) => {
  const [renewalFrom, setRenewalFrom] = useState("");
  const [renewalTo, setRenewalTo] = useState("");

  const options = [
    {
      key: "",
      value: "Filter by customer"
    },
    {
      key: 1,
      value: "One"
    }
  ];
  return (
    <div>
      <form action="" className="row justify-content-between align-items-end">
        <div className="col-4">
          <SelectInput 
            options={ options } 
            label="Customer" 
            inline={ false }
            value={ client }
            setValue={ setClient }
          />
        </div>
        <div className="col-3">
          <DateInput 
            label="Renewal date from" 
            placeholder="Please select" 
            inline={ false }
            value={ startDate }
            setValue={ setStartDate }
          />
        </div>
        <div className="col-3">
          <DateInput 
            label="Renewal date to" 
            placeholder="Please select" 
            inline={ false }
            value={ endDate }
            setValue={ setEndDate }
          />
        </div>
        <div className="col-2">
          <Button 
            text="search" 
            icon="search" 
            className="btn-primary w-100"
            handler={ handleFilter }
          />
        </div>
      </form>
    </div>
  );
};

export default Filter;
