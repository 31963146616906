import React, { useState } from "react";
import Button from "../../../components/Button";
import AttachmentInput from "../../../components/Inputs/AttachmentInput";
import SearchInput from "../../../components/Inputs/SearchInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import PageTitle from "../../../components/PageTitle";
import { useAuth } from "../../../contexts/Auth";

const CreateQuotation = () => {
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);

  const { api } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="body">
      <form action="">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <PageTitle title="Request for quotation"/>
          <div className="d-flex justify-content-center align-items-center">
            <Button icon="back" text="back" className="btn-secondary"/>
            <Button 
              icon="save" 
              text="save" 
              className="btn-primary ms-2"
              progress={ progress } 
              success={ success }
              handler={ handleSubmit }
            />
          </div>
        </div>
        <SelectInput options={ [{ key: "key", value: "value" }] } label="Main class" />
        <SelectInput options={ [{ key: "key", value: "value" }] } label="Product" />
        <SearchInput placeholder="Please select" label="Insured" />
        <TextInput type="number" placeholder ="Please enter" label="Sum insured"/>
        <AttachmentInput label="Closing slip"/>
        <AttachmentInput label="Other documents"/>
        <div className="w-100 text-end pt-4">
          <Button 
            icon="save" 
            type="submit" 
            className="btn-primary" 
            text="save" 
            progress={ progress } 
            success={ success }
            handler={ handleSubmit }
          />
        </div>
      </form>
    </div>
  );
};

export default CreateQuotation;
