import cx from "classnames";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiOutlineCheck, AiOutlineExclamation } from "react-icons/ai";
import { FiAlertTriangle, FiUserPlus } from "react-icons/fi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import s from "./index.module.scss";
import Button from "../../../components/Button";
import AttachmentInput from "../../../components/Inputs/AttachmentInput";
import DateInput from "../../../components/Inputs/DateInput";
import InputLayout from "../../../components/Inputs/Layout";
import SearchInput from "../../../components/Inputs/SearchInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import ModalComponent from "../../../components/Modal";
import PageTitle from "../../../components/PageTitle";
import ReactTable from "../../../components/TableComponent";
import { useAuth } from "../../../contexts/Auth";

const ViewQuotation = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [reqKey] = useState(params.id);

  const { isLoading, error, data } = useQuery({
    queryFn: () =>
      api(`quotation/by-req-key?reqKey=${reqKey}`),
    queryKey: ["quotation"]
  });

  const [fail, setFail] = useState(false);
  const [saved, setSaved] = useState(true);
  const [approveModal, setApproveModal] = useState(false);
  const [approve, setApprove] = useState(false);
  const [approved, setApproved] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [reject, setReject] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [sendModal, setSendModal] = useState(false);
  const [send, setSend] = useState(false);
  const [sent, setSent] = useState(false);
  const [closeQuotation, setCloseQuotation] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const [premiumReceived, setPremiumReceived] = useState(false);
  const [lost, setLost] = useState(true);
  const [insurer, setInsurer] = useState("");
  const [bothDays, setBothDays] = useState(false);
  const [finalDoc, setFinalDoc] = useState({});
  const emails = [];
  
  const { api, docApi } = useAuth();
  
  const [total, setTotal] = useState("");
  const [basicPremium, setBasicPremium] = useState("");
  const [srcc, setSrcc] = useState("");
  const [tc, setTc] = useState("");
  const [totalPremium, setTotalPremium] = useState("");
  const [totalTaxes, setTotalTaxes] = useState("");

  // SEND TO INSURER
  const [emailsPassed, setEmailsPassed] = useState({});
  const [deadline, setDeadline] = useState("");
  const [message, setMessage] = useState("");

  const premium = {
    title: "PREMIUM",
    total: total,
    headings: [
      {
        Header: "Blank",
        accessor: "type"
      },
      {
        Header: "Premium %",
        accessor: "premium"
      },
      {
        Header: <div className="text-center">
          Premium (LKR)
        </div>,
        accessor: "premiumLkr"
      }
    ],
    body: [
      {
        type: "Basic Premium", 
        premium :"",
        premiumLkr: <div className="table-input">
          <TextInput 
            label="Basic Premium" 
            inline={ false } 
            noLabel={ true } 
            readOnly={ !sent }
            value={ basicPremium }
            setValue={ setBasicPremium }
          />
        </div> 
      },
      {
        type: "Riot and Strike Premium", 
        premium :"",
        premiumLkr: <div className="table-input">
          <TextInput 
            label="Riot and Strike Premium" 
            inline={ false } 
            noLabel={ true } 
            readOnly={ !sent }
            value={ srcc }
            setValue={ setSrcc }
          />
        </div> 
      },
      {
        type: "Terrorism Premium", 
        premium :"",
        premiumLkr: <div className="table-input">
          <TextInput 
            label="Terrorism Premium" 
            inline={ false } 
            noLabel={ true } 
            readOnly={ !sent }  
            value={ tc }
            setValue={ setTc }        
          />
        </div> 
      },
      {
        type: "Total premium before Tax/Surcharges", 
        premium :"",
        premiumLkr: <div className="table-input">
          <TextInput 
            label="Total premium before Tax/Surcharges" 
            inline={ false } 
            noLabel={ true } 
            readOnly={ !sent }
            value={ totalPremium }
            setValue={ setTotalPremium }
          />
        </div> 
      },
      {
        type: "Total of Taxes/Surcharges", 
        premium :"",
        premiumLkr: <div className="table-input">
          <TextInput 
            label="Total of Taxes/Surcharges" 
            inline={ false } 
            noLabel={ true } 
            readOnly={ !sent }
            value={ totalTaxes }
            setValue={ setTotalTaxes }
          />
        </div> 
      },
      {
        type: <div className="fw-bold">
          Total premium after Taxes/Surcharges
        </div>,
        premium :"",
        premiumLkr: <div className="fw-bold text-center">
          { total }
        </div> 
      }
    ]
  };
  
  useEffect(()=>{
    if(data?.both_date_inclusive === 1){
      setBothDays(true);
    }else{
      setBothDays(false);
    }
    if(data?.fk_quote_status === 2){
      setApproved(true);
    }else if(data?.fk_quote_status === 4){
      setApproved(true);
      setSent(true);
    }

    setTotal(()=>{
      let temp = 0;
      if(basicPremium){
        temp = temp + parseInt(basicPremium);
      }
      if(srcc){
        temp = temp + parseInt(srcc);
      }
      if(tc){
        temp = temp + parseInt(tc);
      }
      if(totalPremium){
        temp = temp + parseInt(totalPremium);
      }
      if(totalTaxes){
        temp = temp + parseInt(totalTaxes);
      }
      return temp;
    }); 

  }, [
    data, 
    basicPremium,
    srcc,
    tc,
    totalPremium,
    totalTaxes
  ]);

  const approveQuote = async(e) => {
    e.preventDefault();
    const update = await api("quotation/update-status", { reqKey, statusId: 2 }, "POST");
    if(update){
      setApproveModal(false);
      setApprove(true);
    }else{
      setFail(true); 
    }
  };

  const handleReject = (e) => {
    e.preventDefault();
    setRejectModal(false);
    setReject(true);
  };

  const handleApproved = async () => {
    setApprove(false);
    setApproved(true);
  };

  const handleSendToInsurers = async (e) => {
    e.preventDefault();
    setSendModal(false);
    setSend(true);
  };

  const handleShareApprove = async (e) => {
    e.preventDefault();
    Object.values(emailsPassed).map((email)=>{
      emails.push(email);
    });
    const sendToInsurer = await api("quotation/send-to-insurer", { to: emails, reqKey, message, deadLinee: deadline }, "POST");
    if(sendToInsurer){
      const update = await api("quotation/update-status", { reqKey, statusId: 4 }, "POST");
      setSend(false);
      setSent(true);
    }else{
      setFail(true); 
    }
  };

  const handleCloseQuotaion = async(e) => {
    e.preventDefault();
    const closeDoc = await docApi(
      "document/upload", 
      { 
        file: finalDoc,
        reqKey,
        docId: 2,
        userId: 5  
      },
      "POST"
    );
    if(closeDoc){
      const close = await api (
        "quotation/close",
        {
          reqKey,
          insurerId: parseInt(insurer),
          basicPremium: parseInt(basicPremium),
          srcc: parseInt(srcc),
          tc: parseInt(tc)
        }
        ,"POST"
      );
      if(close?.quotationRef){
        setCloseQuotation(false);
        setConfirmClose(true);
        // const update = await api("quotation/update-status", { reqKey, statusId: 5 }, "POST");
        // if(update){
        // }
      }else{
        setFail(true); 
      }
    }
  };

  const handleMainForm = (e) => {
    e.preventDefault();
    setCloseQuotation(true);
  };

  const options = [
    {
      key: "",
      value: "Please Select"
    },
    {
      key: 1,
      value: "One"
    },
    {
      key: 2,
      value: "Two"
    }
  ];

  return (
    <>
      <section className="body">
        <article className="d-flex justify-content-between align-items-end mb-4">
          <div>
            <PageTitle title={ (approved || rejected)? "Closing Slip" : premiumReceived? "Quotation" : "Request for quotation" }/>
          </div>
          {
            saved && (approved || rejected) || <p className="pending">Pending aprroval</p>
          }
          <div className="d-flex justify-content-between align-items-end">
            { 
              (approved || rejected) || 
                <Button icon="back" text="Back" className="btn-secondary me-2" handler={ ()=>navigate(-1) }/> 
            }
            { !premiumReceived && <Button 
              icon="edit" 
              text="edit" 
              className="btn-secondary me-2"  
              handler={ ()=>navigate(`/quotations/${reqKey}/edit`) }
            /> }
            { 
              approved && !premiumReceived && 
                <Button 
                  icon="paper-plane" 
                  text="send" 
                  className="btn-secondary me-2" 
                  handler={ ()=>setSendModal(true) }
                /> 
            }
            { !premiumReceived && <Button 
              icon="close" 
              text={ rejected ? "rejected" : "reject" } 
              className={ `btn me-2 ${cx({ "btn-outline-danger": !rejected , "btn-danger" : rejected })}` } 
              handler={ ()=>{
                setRejectModal(true);
              } }
              disabled = { rejected }
            /> }
            {
              premiumReceived && <Button 
                icon="edit" 
                text="lost" 
                className="btn-secondary me-2"  
                handler={ ()=> setLost(true) }
              />
            }
            {
              premiumReceived && <Button 
                icon="check-shield" 
                text="convert to policy" 
                className="btn-secondary me-2"  
                handler={ ()=>navigate(`/quotations/${reqKey}/convert-to-policy`) }
              />
            }
            { 
              premiumReceived && <Button 
                icon="check" 
                text="premium received"
                className="btn-success me-2"
                disabled = { premiumReceived }
              /> 
            }
            { 
              !rejected && <Button 
                icon="check" 
                text={ approved? "approved" : "approve" }
                className={ `btn ${cx({ "btn-outline-success": !approved , "btn-success" : approved })}` } 
                handler={ ()=>{setApproveModal(true);} }
                disabled = { approved }
              /> 
            }
          </div>
        </article>
        <article>
          <form action="" onSubmit={ handleMainForm }>
            <div className="d-flex justify-content-end">
              { 
                sent  && !premiumReceived &&
                  <Button 
                    icon="check-circ" 
                    text="close quotation" 
                    type="submit"
                    className="btn-primary mb-4" 
                  /> 
              }
            </div>
            <div>
              <SelectInput options={ [{ key: "", value:data?.mainClass }] } label="Main Class" readOnly={ saved }/>
            </div>
            <div>
              <SelectInput options={ [{ key: "", value:data?.product }] } label="Product" readOnly={ saved }/>
            </div>
            <div>
              <SearchInput 
                placeholder="Please select" 
                label="Insured" 
                readOnly={ saved } 
                value={ data?.insured }
              />
            </div>
            <div>
              <TextInput 
                type="number" 
                placeholder="Please enter" 
                label="Sum Insured" 
                readOnly={ saved } 
                value={ data?.sum_insured }
              />
            </div>
            <div className="row justify-content-between align-items-center mb-4">
              <p className="mb-0 col-3 label">
                period of insurance
              </p>
              <div className="col-1">:</div>
              <div className="d-flex justify-content-between align-items-center col-8">
                <DateInput 
                  inline={ false } 
                  label="Start Date" 
                  noLabel={ true } 
                  placeholder="Start Date" 
                  readOnly={ saved }
                  uFValue={ data?.policy_start_date }
                />
                <DateInput 
                  inline={ false } 
                  label="End Date" 
                  noLabel={ true } 
                  placeholder="End Date" 
                  readOnly={ saved }
                  uFValue={ data?.policy_end_date }
                />
                <TextInput 
                  type="checkbox" 
                  inline={ false } 
                  label="Both days inclusive" 
                  readOnly={ saved } 
                  value={ bothDays }
                />
              </div>
            </div>
            <div>
              <div>
                <AttachmentInput 
                  label="Closing Slip" 
                  readOnly={ saved }
                />
              </div>
              <div>
                <AttachmentInput 
                  label="Other Documents" 
                  readOnly={ saved }
                />
              </div>
            </div>
            {
              approved && 
                <div>
                  <SelectInput
                    label="Insurer"
                    inline={ true }
                    value={ insurer }
                    setValue={ setInsurer }
                    options={ options }
                  />
                  <AttachmentInput 
                    label="Final Documents" 
                    value={ finalDoc }
                    setValue={ setFinalDoc }
                  />
                </div>
            }
            {
              approved && <div className={ `${s.single_table} mt-5` }>
                <div className={ `px-4 pt-4 pb-2 ${s.header} d-flex justify-content-between align-itesm-center` }>
                  <h3 className="d-flex justify-content-start align-items-center">
                    { premium.title }
                  </h3>
                </div>
                <div>
                  <ReactTable columns={ premium.headings } data={ premium.body }/>
                </div>
              </div>
            }
            { 
              sent  && !premiumReceived &&
                <Button 
                  icon="check-circ" 
                  text="close quotation" 
                  type="submit"
                  className="btn-primary mt-4 ms-auto" 
                /> 
            }
          </form>
        </article>
      </section>

      { /* MODALS */ }
      <>
        <Approve 
          title="Do you want to approve this quotation?"
          show={ approveModal } 
          onHide={ ()=>setApproveModal(false) } 
          handle={ approveQuote }/>
        
        <Approved 
          title="Quotation approved successfully"
          show={ approve } 
          onHide={ ()=>{
            handleApproved();
          } } 
        />

        <Reject
          show={ rejectModal }
          onHide={ ()=>{
            setRejectModal(false);
          } }
          handle={ handleReject }
        />

        <Rejected
          show = { reject }
          onHide = { ()=>{
            setReject(false);
            setRejected(true);
          } }
          rejectReason = { "Reject Reason" }
        />

        <Insurer
          show={ sendModal }
          onHide={ ()=>setSendModal(false) }
          handle = { handleSendToInsurers }
          emailsPassed={ emailsPassed }
          setEmailsPassed={ setEmailsPassed }
          deadline = { deadline }
          setDeadline = { setDeadline }
          message = { message }
          setMessage = { setMessage }
        />

        <ApproveShare 
          title = "Quotation shared successfully"
          desc = "Quotation has been shared successfully. Insureres will be able to edit the benefits and add premium details." 
          show = { send }
          onHide = { ()=>{
            setSend(false);
          } }
          handle = { handleShareApprove }
        />

        <CloseQuotation 
          title = "Do you want to close this quotation?"
          show = { closeQuotation }
          onHide = { ()=>{
            setCloseQuotation(false);
          } }
          handle = { handleCloseQuotaion }
        />

        <Approved 
          title="Quotation closed successfully"
          show={ confirmClose } 
          onHide={ ()=>{
            setPremiumReceived(true);
            setConfirmClose(false);
          } } 
        />

        <Unsuccessful 
          show = { fail } 
          onHide = { () => {
            setFail(false);
          } }
        />
      </> 
    </>
  );
};

const Approve = ({ show, onHide, handle, title }) => {
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div>
        <span className={ "modal-icon success mb-4" }>
          <AiOutlineCheck/>
        </span>
        <div className="mb-4">
          <h4>
            { title }
          </h4>
        </div>
        <form action="" method="post" onSubmit={ (e)=>handle(e) }>
          <div className="d-flex justify-content-between">
            <Button 
              handler={ onHide } 
              text="cancel" 
              className="btn-secondary me-2 w-100 pt-3 pb-3"
            />
            <Button 
              text="Approve"
              className="btn-primary w-100 pt-3 pb-3"
              type="submit"
            />
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};

const Approved = ({ show, onHide, title }) => {
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div>
        <span className={ "modal-icon success mb-4" }>
          <AiOutlineCheck/>
        </span>
        <div className="mb-4">
          <h4>
            { title }
          </h4>
        </div>
        <Button 
          text="OK"
          className="btn-primary w-100 pt-3 pb-3"
          type="submit"
          handler={ onHide }
        />
      </div>
    </ModalComponent>
  );
};

const Reject = ({ show, onHide, handle })=>{
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div className="min-width-form">
        <span className="modal-icon reject mb-4">
          <FiAlertTriangle/>
        </span>
        <div className="mb-4">
          <h4>
            Reject Quotation
          </h4>
          <p>
            Please enter the reject reason
          </p>
        </div>
        <form action="" method="post" onSubmit={ (e)=>handle(e) }>
          <div className="mb-4">
            <TextInput type = "text" placeholder="Reject reason" inline={ false }/>
          </div>
          <div className="d-flex justify-content-between">
            <Button 
              text="cancel" 
              className="btn-secondary w-100 pt-3 pb-3 me-2"
              handler={ onHide }
            />
            <Button 
              text="confirm" 
              className="btn-primary w-100 pt-3 pb-3"
              type="submit"
            />
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};

const Rejected = ({ show, onHide, rejectReason })=>{
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div className="min-width-form">
        <span className="modal-icon reject mb-4">
          <FiAlertTriangle/>
        </span>
        <div className="mb-4">
          <h4>
            Quotation rejected successfully with the following reason
          </h4>
          <p>
            { rejectReason }
          </p>
        </div>
        <Button 
          text="OK" 
          className="btn-primary w-100 pt-3 pb-3"
          handler={ onHide }
        />
      </div>
    </ModalComponent>
  );
};

const Insurer = ({
  show,
  onHide,
  handle,
  emailsPassed,
  setEmailsPassed,
  deadline,
  setDeadline,
  message,
  setMessage
})=>{

  const [emails, setEmails] = useState([]);

  const handleChange = (e) => {
    setEmailsPassed({
      ...emailsPassed,
      [e.target.name] : e.target.value
    });
  };

  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <span className="modal-icon mb-4">
        <FiUserPlus/>
      </span>
      <div className="mb-4">
        <h4>
          Share with insurers
        </h4>
        <p>
          Please add the email adresses below to share  
        </p>
      </div>
      <form action="" method="post" onSubmit={ (e) => handle(e) }>
        <div className="mb-4">
          <InputLayout label="Email addresses" inline={ false }>
            <input 
              type = "email" 
              name = "ea1" 
              placeholder="you@untitledui.com" 
              value={ emailsPassed["ea1"] }
              onChange={ handleChange }
              required={ true }
            />
          </InputLayout>
          <div className="my-2">
            <InputLayout inline={ false } noLabel = { true }>
              <input 
                type = "email" 
                name = "ea2" 
                placeholder="you@untitledui.com" 
                value={ emailsPassed["ea2"] }
                onChange={ handleChange }
                required={ false }
              />
            </InputLayout>
          </div>
          {
            emails.map((email , idx)=>{
              return <div className="my-2" key={ idx }> 
                <InputLayout key={ idx } noLabel = { true } inline={ false } >
                  <input 
                    type = "email" 
                    name = { `ea${idx+3}` } 
                    value={ emailsPassed[`ea${idx+3}`] }
                    onChange={ handleChange }
                    placeholder="you@untitledui.com" 
                  />
                </InputLayout> 
              </div>; 
            })
          }
          <p 
            role="presentation"
            // eslint-disable-next-line max-len
            onClick={ ()=>setEmails([
              ...emails, 
              <InputLayout key="" noLabel = { true } inline={ false } >
                <input 
                  type = "email" 
                  label = "Email addresses" 
                  placeholder="you@untitledui.com" 
                />
              </InputLayout>
            ]) }
            className="add-email"
          >
            + Add another email address
          </p>
        </div>
        <div className="mb-4">
          <DateInput 
            label = "Deadline to process quotation" 
            inline={ false } 
            placeholder="Please select deadline"
            value={ deadline }
            setValue={ setDeadline }
          />
        </div>
        <div className="mb-4">
          <TextInput 
            label = "Message" 
            type="textarea"  
            inline={ false } 
            rows={ 4 } 
            placeholder="Please enter your message"
            value={ message }
            setValue={ setMessage }
          />
        </div>
        <div className="d-flex justify-content-between">
          <Button 
            handler={ onHide } 
            text="cancel" 
            className="btn-secondary me-2 w-100 pt-3 pb-3"
          />
          <Button 
            text="Share" 
            className="btn-primary w-100 pt-3 pb-3"
            type="submit"
          />
        </div>
      </form>
    </ModalComponent>
  );
};

const ApproveShare = ({ show, onHide, handle, title, desc }) => {
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div>
        <span className={ "modal-icon success mb-4" }>
          <AiOutlineCheck/>
        </span>
        <div className="mb-4">
          <h4>
            { title }
          </h4>
          <p>
            {
              desc
            }
          </p>
        </div>
        <form action="" method="post" onSubmit={ (e)=>handle(e) }>
          <div className="d-flex justify-content-between">
            <Button 
              handler={ onHide } 
              text="cancel" 
              className="btn-secondary me-2 w-100 pt-3 pb-3"
            />
            <Button 
              text="confirm"
              className="btn-primary w-100 pt-3 pb-3"
              type="submit"
            />
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};

const CloseQuotation = ({ show, onHide, handle, title }) => {
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div>
        <span className={ "modal-icon success mb-4" }>
          <AiOutlineCheck/>
        </span>
        <div className="mb-4">
          <h4>
            { title }
          </h4>
        </div>
        <form action="" method="post" onSubmit={ (e)=>handle(e) }>
          <div className="d-flex justify-content-between">
            <Button 
              handler={ onHide } 
              text="I'll do this later" 
              className="btn-secondary me-2 w-100 pt-3 pb-3"
            />
            <Button 
              text="yes"
              className="btn-primary w-100 pt-3 pb-3"
              type="submit"
            />
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};

const Unsuccessful = ({ show, onHide }) => {
  return(
    <ModalComponent
      show = { show }
      onHide = { () => onHide() }
    >
      <div className="confirmation-popup">
        <span className={ "modal-icon reject mb-4" }>
          <AiOutlineExclamation/>
        </span>
        <div className="mb-4">
          <h4>
            Request Unsuccessful
          </h4>
        </div>
        <div className="d-flex justify-content-between">
          <Button 
            text="OK"
            className="btn-primary w-100 pt-3 pb-3"
            handler={ ()=>onHide() }
          />
        </div>
      </div>
    </ModalComponent>
  );
};

export default ViewQuotation;
