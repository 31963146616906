import React from "react";
import { Nav } from "react-bootstrap";
import { StringParam, useQueryParam } from "use-query-params";

const PageSubNav = ({ list = [] }) => {

  const [type, setType] = useQueryParam("type", StringParam);


  return (
    <div className={ "mb-4" }>
      <Nav variant="tabs" defaultActiveKey={ list?.[0]?.value || null }>
        { list.map(({ label, value }) => <Nav.Item key={ value }>
          <Nav.Link eventKey={ value } as={ "button" } onClick={ () => setType(value) }>{ label }</Nav.Link>
        </Nav.Item>) }

      </Nav>
    </div>
  );
};

export default PageSubNav;
