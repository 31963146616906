import React, { useState } from "react";
import s from "./index.module.scss";
import InputLayout from "../Layout";

const TextInput = ({ type = "text", label = "", placeholder="", inline = true, min="", max="", readOnly = false, rows = 3, value="", noLabel = false, setValue=()=>{}, required = false }) => {
  return (
    type === "number" ?
      <InputLayout inline={ inline } className={ `${s.sum_input}` } label={ label } noLabel={ noLabel }>
        <div>
          <span>LKR</span>
          <div className={ s.seperator }></div>
          <input type={ type } id={ label } name={ label } placeholder={ placeholder } readOnly={ readOnly }
            value={ value } onChange={ e => setValue(e.target.value) } />
        </div>
      </InputLayout>
      :
      type === "checkbox" ?
        <div className={ `d-flex align-items-center ${s.check_input}` }>
          <input type={ type } id={ label } name={ label } placeholder={ placeholder } readOnly={ readOnly } checked={ value }
            onChange={ e => {setValue(e.target.checked);} }/>
          <p className="mb-0 ms-2">{ label }</p>
        </div>
        :
        type === "textarea" ?
          <InputLayout inline={ inline } label={ label }>
            <textarea
              id={ label }
              name={ label }
              placeholder={ placeholder }
              rows={ rows }
              readOnly={ readOnly }
              value={ value }
              onChange={ e => setValue(e.target.value) }
              noLabel={ noLabel }
            >{ value }</textarea>
          </InputLayout>
          :
          <InputLayout inline={ inline } label={ label } noLabel={ noLabel }>
            <input 
              type={ type } 
              id={ label } 
              name={ label } 
              placeholder={ placeholder } 
              value = { value }
              noLabel={ noLabel }
              onChange = { (e)=>setValue(e.target.value) }
              required = { required }
            />
          </InputLayout>
  );
};

export default TextInput;
