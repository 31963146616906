import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import s from "./index.module.scss";
import { engineering, fire, liability, life, marine, medical, misc, motor, tabs } from "./utils/data";
import Button from "../../../components/Button";
import SearchInput from "../../../components/Inputs/SearchInput";
import PageSubNav from "../../../components/PageSubNav";
import PageTitle from "../../../components/PageTitle";
import ReactTable from "../../../components/TableComponent";
import { useAuth } from "../../../contexts/Auth";

const ProductsPage = () => {
  const [tabs, setTabs] = useState([]);
  const [tables, setTables] = useState([]);
  const [filter, setFilter] = useState("");

  const list = useQuery({
    queryFn: () =>
      api("product/details"),
    queryKey: ["products"]
  });

  const { api } = useAuth();

  const setTableData = (data) =>{
    if(data === undefined){
      return;
    }else{
      const propertyNames = Object.keys(data);
      const productsArr = [];
      propertyNames.forEach((property)=>{
        if(property !== "tabs"){
          productsArr.push(list?.data?.[property]);
        }
      });
      setTables(productsArr);
    }

  };

  useEffect(()=>{
    setTabs(get(list?.data, "tabs", []));

    if(list?.data != undefined){
      if(filter === ""){
        setTableData(get(list, "data", {}));
      }else{
        const fetchData = async()=>{
          const data = await api("product/filter", { param: filter }, "POST");
          setTableData(data);
        };
        fetchData();
      }
    }

  }, [list?.data, filter]);

  const navigate = useNavigate();

  return (
    <div className="body-two">
      <div className="mb-4">
        <PageTitle title="Products"/>
      </div>

      <div className="mb-4">
        <PageSubNav list={ tabs.map(item => ({ label: item.tabTitle, value: item.tab })) }/>
      </div>

      { /* TABLE FILTER */ }
      <div className="mb-4 row align-items-end justify-content-between">
        <div className="col-4">
          <Button 
            icon="plus" 
            text="Add new product" 
            className="btn-primary" 
            handler={ () => navigate("/products/new") }
          />
        </div>
        <div className="col-4">
          <SearchInput 
            placeholder="Search" 
            inline={ false }
            value={ filter }
            setValue={ setFilter }
          />
        </div>
      </div>
      {
        tables.map((table, idx)=>{
          return(
            <div key={ idx } className={ `${s.single_table} mb-5` }>
              <div className={ `px-4 pt-4 pb-2  ${s.header}` }>
                <h3 className="d-flex justify-content-start align-items-center">
                  { table.title }
                  <span className="ms-2">
                    { get(table, "body.length", 0) } {  get(table, "body.length", 0) === 1 ? "Product" : "Products" } 
                  </span>
                </h3>
              </div>
              <div>
                <ReactTable 
                  columns={ get(table, "headings", []) } 
                  data={ get(table, "body", []) } 
                  toggle={ true } 
                  edit={ true } 
                  newTab={ false }
                  viewMore={ true }
                />
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default ProductsPage;
