import React from "react";
import Modal from "react-bootstrap/Modal";
import s from "./index.module.scss";

const ModalComponent = (props) => {
  return (
    <Modal
      { ...props }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={ `${s.modal} p-4` }>
        { props.children }
      </Modal.Body>
    </Modal>
  );
};

export default ModalComponent;
