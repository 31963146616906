let total = "220,000";

export const claims = {
  title: "CLAIMS HISTORY",
  total: 8,
  headings: [
    {
      Header: "Underwriting Year",
      accessor: "underwritingYear"
    },
    {
      Header: "Number of Claims",
      accessor: "numberOfClaims"
    },
    {
      Header: "View",
      accessor: "view"
    }
  ],
  body: [
    {
      underwritingYear: "2018/2019", 
      numberOfClaims:"4 Claims",
      view: ""
    },
    {
      underwritingYear: "2019/2020", 
      numberOfClaims:"4 Claims",
      view: ""
    },
    {
      underwritingYear: "2020/2021", 
      numberOfClaims:"0 Claims",
      view: "no view"
    }
  ]
};

export const endorsements = {
  title: "ENDORSEMENTS",
  total: 2,
  headings: [
    {
      Header: "Underwriting Year",
      accessor: "underwritingYear"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "View",
      accessor: "view"
    }
  ],
  body: [
    {
      underwritingYear: "2018/2019", 
      type:"Addition",
      view: ""
    },
    {
      underwritingYear: "2019/2020", 
      type:"Addition",
      view: ""
    },
    {
      underwritingYear: "2020/2021", 
      type:"Deletion",
      view: ""
    }
  ]
};

export const premium = {
  title: "PREMIUM",
  total: total,
  headings: [
    {
      Header: "Blank",
      accessor: "type"
    },
    {
      Header: "Premium %",
      accessor: "premium"
    },
    {
      Header: "Premium (LKR)",
      accessor: "premiumLkr"
    }
  ],
  body: [
    {
      type: "Basic Premium", 
      premium :"95%",
      premiumLkr: "190,000"
    },
    {
      type: "Riot and Strike Premium", 
      premium :"3%",
      premiumLkr: "6,000"
    },
    {
      type: "Terrorism Premium", 
      premium :"2%",
      premiumLkr: "4,000"
    },
    {
      type: "Total premium before Tax/Surcharges", 
      premium :"",
      premiumLkr: "200,000"
    },
    {
      type: "Total of Taxes/Surcharges", 
      premium :"10%",
      premiumLkr: "20,000"
    },
    {
      type: <div className="fw-bold">
        Total premium after Taxes/Surcharges
      </div>,
      premium :"",
      premiumLkr: <div className="fw-bold">
        { total }
      </div> 
    }
  ]
};
  
