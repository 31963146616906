import Routes from "./Routes";
import "./styles/global/index.scss";

function App() {
  return (
    <>
      <div className="d-none d-xl-block">
        <Routes />
      </div>
      <div className="d-xl-none">
        <div className="container text-center">
          <p className="mt-5">Please visit from a larger browser to view the dashboard.</p>
        </div>
      </div>
    </>
  );
}

export default App;
