import get from "lodash/get";
import React from "react";
import { useNavigate } from "react-router-dom";
import BarChartStats from "./BarChart";
import s from "./index.module.scss";
import { body, bodyTwo, cardData, headings, headingsTwo } from "./utils/cardData";
import Button from "../../components/Button";
import DataCard from "../../components/DataCard";
import RichText from "../../components/Inputs/RichText";
import PageTitle from "../../components/PageTitle";
import ReactTable from "../../components/TableComponent";
import TimePeriodFilter from "../../components/TimePeriodFilter";

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="body-two">
      { /* HEADER */ }
      <div className="d-flex justify-content-between align-items-center my-4">
        <div className={ s.page_title }>
          <PageTitle title="Welcome back, John Smith"/>
          <h3>
            Track and manage your customers and policies.
          </h3>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <Button 
            icon="plus" 
            text="New endorsement" 
            className="btn-secondary me-2"
            handler={ () => navigate("/endorsements/new") }
          />
          <Button 
            icon="plus" 
            text="New claim" 
            className="btn-secondary me-2"
            handler={ () => navigate("/claims/new") }
          />
          <Button 
            icon="plus" 
            text="New quotation" 
            className="btn-primary" 
            handler={ () => navigate("/quotations/new") }
          />
        </div>
      </div>

      { /* CARDS */ }
      <div className="row justify-content-between align-items-start mb-5">
        {
          cardData.map((data, idx)=>{
            return(
              <div key = { idx } className="col-4">
                <DataCard 
                  title={ get(data, "title", "") }
                  value={ get(data, "value", "") }
                  stats={ get(data, "showStat", false) }
                  percentage={ get(data, "percentage", "") }
                  increased={ get(data, "increased", false) }
                />
              </div>
            );
          })
        }
      </div>

      { /* BAR CHART */ }
      <BarChartStats/>

      { /* TABLE */ }
      <div className={ s.table_container }>
        <div className={ `${s.table_header} row justify-content-between align-items-start px-4 pt-4 pb-2` }>
          <div className="col-4">
            <h3>
              Top 10 customers
            </h3>
            <p>
              These companies have reached the highest GWP 
            </p>
          </div>
          <div className="col-6">
            <TimePeriodFilter noQuarter={ true }/>
          </div>
        </div>
        <div className={ s.table_wrapper }>
          <ReactTable 
            columns={ headingsTwo } 
            data={ bodyTwo }
          />
        </div>
      </div>
    </div>
  );
};

const card = (cardData)=>{
  return(
    <div className="row justify-content-between align-items-start mb-5">
      {
        cardData.map((data, idx)=>{
          return(
            <div key = { idx } className="col-4">
              <DataCard 
                title={ get(data, "title", "") }
                value={ get(data, "value", "") }
                stats={ get(data, "showStat", false) }
                percentage={ get(data, "percentage", "") }
                increased={ get(data, "increased", false) }
              />
            </div>
          );
        })
      }
    </div>
  );
};

export default HomePage;



