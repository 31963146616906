import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import LoginPage from "../pages/LoginPage";

const defaultUser = {
  email: "test@test.com",
  name: "John Smith",
  image: "https://thumbs.dreamstime.com/b/woman-portrait-23496008.jpg",
  accessToken: null
};

const AuthContext = React.createContext();

console.log(process.env.REACT_APP_USER_API_KEY);
console.log(process.env.REACT_APP_API_URL);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(-1);
  const [user, setUser] = useState({
    accessToken: null
  });

  const logout = async () => {
    setIsLoggedIn(0);
  };

  const login = async (email, password) => {
    const authKeys = await axios
      .post(`${process.env.REACT_APP_API_URL}/auth/init`, {
        apiKey: process.env.REACT_APP_USER_API_KEY,
        user: "CORP_USER"
      })
      .then((r) => r?.data?.data)
      .catch((e) => {
        return e?.response?.data;
      });

    setUser({
      ...defaultUser,
      accessToken: authKeys?.token
    });
    setIsLoggedIn(1);
  };

  useEffect(() => {
    // Check if the user is already logged in
    setIsLoggedIn(0);
    // setUser(defaultUser);
  }, []);

  const api = async (endpoint, data = {}, method = "GET") => {
    const headers = {
      Accept: "application/json",
      "API-Key": user?.accessToken ? `Bearer ${user?.accessToken}` : null,
      "Content-Type": "application/json"
    };

    return axios({
      data,
      headers,
      method,
      url: `${process.env.REACT_APP_API_URL}/${endpoint}`
    })
      .then((r) => {
        console.log(r?.data);
        return r?.data?.data || null;
      })
      .catch((e) => {
        console.log(e);
        return e?.response?.data;
      });
  };
  const docApi = async (endpoint, data = {}, method = "GET") => {
    const headers = {
      Accept: "multipart/form-data",
      "API-Key": user?.accessToken ? `Bearer ${user?.accessToken}` : null,
      "Content-Type": "multipart/form-data"
    };
    return axios({
      data,
      headers,
      method,
      url: `${process.env.REACT_APP_API_URL}/${endpoint}`
    })
      .then((r) => {
        console.log(r?.data);
        return r?.data?.data || null;
      })
      .catch((e) => {
        console.log(e);
        return e?.response?.data;
      });
  };

  const value = useMemo(
    () => ({
      api,
      login,
      logout,
      docApi,
      user
    }),
    [user, isLoggedIn]
  );

  return (
    <AuthContext.Provider value={ value }>
      { isLoggedIn === -1 ? <Loading /> : null }
      { isLoggedIn === 0 ? <LoginPage login={ login } /> : null }
      { isLoggedIn === 1 ? children : null }
    </AuthContext.Provider>
  );
}

function Loading() {
  return (
    <div className="container text-center">
      <p className="my-5">Loading..</p>
    </div>
  );
}
