export const tabs = [
  {
    tab: "new",
    tabTitle: "New"
  },
  {
    tab: "sent to insurer",
    tabTitle: "Sent to Insurer"
  },
  {
    tab: "approved",
    tabTitle: "Approved"
  },
  {
    tab: "rejected",
    tabTitle: "Rejected"
  },
  {
    tab: "settled",
    tabTitle: "Settled"
  }
];

export const newClaim = {
  headings:[
    {
      Header: "Created Date",
      accessor: "createdDate"
    },
    {
      Header: "Requested Date",
      accessor: "requestedDate"
    },
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Claim Type",
      accessor: "claimType"
    },
    {
      Header: "Channel",
      accessor: "channel"
    },
    {
      Header: "Request Amount",
      accessor: "requestAmount"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      createdDate:  "31-05-2022", 
      requestedDate: "31-05-2022", 
      insured: "Dialog Axiata PLC (DAP)...",  
      product: "WORKMENS COMPENSATION",
      claimType: "<claim type>",
      channel: "App",
      requestAmount: "15,000",
      status: <div className="pending">new</div>,
      cta: "claims"
    }
  ]
};

export const sentToInsurer = {
  headings:[
    {
      Header: "Created Date",
      accessor: "createdDate"
    },
    {
      Header: "Requested Date",
      accessor: "requestedDate"
    },
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Claim Type",
      accessor: "claimType"
    },
    {
      Header: "Channel",
      accessor: "channel"
    },
    {
      Header: "Request Amount",
      accessor: "requestAmount"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      createdDate:  "31-05-2022", 
      requestedDate: "31-05-2022", 
      insured: "Dialog Axiata PLC (DAP)...",  
      product: "WORKMENS COMPENSATION",
      claimType: "<claim type>",
      channel: "App",
      requestAmount: "15,000",
      status: <div className="sent">sent to insurer</div>,
      cta: "claims"
    }
  ]
};

export const approved = {
  headings:[
    {
      Header: "Created Date",
      accessor: "createdDate"
    },
    {
      Header: "Requested Date",
      accessor: "requestedDate"
    },
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Claim Type",
      accessor: "claimType"
    },
    {
      Header: "Channel",
      accessor: "channel"
    },
    {
      Header: "Request Amount",
      accessor: "requestAmount"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      createdDate:  "31-05-2022", 
      requestedDate: "31-05-2022", 
      insured: "Dialog Axiata PLC (DAP)...",  
      product: "WORKMENS COMPENSATION",
      claimType: "<claim type>",
      channel: "App",
      requestAmount: "15,000",
      status: <div className="sent">approved</div>,
      cta: "claims"
    }
  ]
};

export const rejected = {
  headings:[
    {
      Header: "Created Date",
      accessor: "createdDate"
    },
    {
      Header: "Requested Date",
      accessor: "requestedDate"
    },
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Claim Type",
      accessor: "claimType"
    },
    {
      Header: "Channel",
      accessor: "channel"
    },
    {
      Header: "Request Amount",
      accessor: "requestAmount"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      createdDate:  "31-05-2022", 
      requestedDate: "31-05-2022", 
      insured: "Dialog Axiata PLC (DAP)...",  
      product: "WORKMENS COMPENSATION",
      claimType: "<claim type>",
      channel: "App",
      requestAmount: "15,000",
      status: <div className="rejected">rejected</div>,
      cta: "claims"
    }
  ]
};

export const settled = {
  headings:[
    {
      Header: "Created Date",
      accessor: "createdDate"
    },
    {
      Header: "Requested Date",
      accessor: "requestedDate"
    },
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Product",
      accessor: "product"
    },
    {
      Header: "Claim Type",
      accessor: "claimType"
    },
    {
      Header: "Channel",
      accessor: "channel"
    },
    {
      Header: "Request Amount",
      accessor: "requestAmount"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      createdDate:  "31-05-2022", 
      requestedDate: "31-05-2022", 
      insured: "Dialog Axiata PLC (DAP)...",  
      product: "WORKMENS COMPENSATION",
      claimType: "<claim type>",
      channel: "App",
      requestAmount: "15,000",
      status: <div className="received">settled</div>,
      cta: "claims"
    }
  ]
};
