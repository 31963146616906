import cx from "classnames";
import React from "react";
import s from "./index.module.scss";

/**
 *
 * cx("class1", "class2") => className="class1 class2"
 * cx("class1", {"class2": inline}) => if inline: className="class1 class2", else className="class1"
 */

const InputLayout = ({ inline = false, className = "", children, label = "", noLabel = false }) => {
  return (
    <div className={ className }>
      <div className={ `row justify-content-between align-items-start ${cx({ "mb-4": inline, "mb-0": !inline })} ` }>
        { label && !noLabel && <div className={ cx({ "col-3": inline, "col-12": !inline }) }>
          <label htmlFor={ label } className={ s.label }>{ label }
          </label>
        </div> }
        { inline && <div className="col-1">:</div> }
        <div className={ `${cx({ "col-8": inline, "col-12": !inline })} ${s.inputs}` }>
          { children }
        </div>
      </div>
    </div>
  );
};

export default InputLayout;
