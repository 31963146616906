import React from "react";
import s from "./index.module.scss";

const PageTitle = ({ title = "" }) => {
  return (
    <div>
      <h1 className={ s.page_title }>{ title }</h1>
    </div>
  );
};

export default PageTitle;
