import React, { useEffect, useState } from "react";
import Datepicker from "react-datepicker";
import { BiCalendarAlt } from "react-icons/bi";
import InputLayout from "../Layout";
import "react-datepicker/dist/react-datepicker.css";
import s from "./index.module.scss";


const DateInput = ({ label = "", placeholder="", inline = true, min="", max="", users = {}, setUsers = {}, noLabel = false, readOnly = false, value="", setValue=()=>{}, uFValue="", preSet="" }) => {
  console.log(preSet);
  const [ufValue, setUfValue] = useState(preSet);
  const formatDate = (date) => {
    const passDate = new Date(date);
    return `${passDate.getFullYear()}-${passDate.getMonth() + 1}-${passDate.getDate()}`.toString();
  };
  return (
    <InputLayout inline = { inline } label={ label } noLabel = { noLabel }>
      <div className={ s.date }>
        <span>
          <BiCalendarAlt/>
        </span>
        <Datepicker 
          id={ label } 
          name={ label } 
          placeholderText={ placeholder } 
          readOnly={ readOnly }
          dateFormat="yyyy/MM/dd"
          selected={ ufValue }
          onChange={ (value)=>{
            setUfValue(value);
            setValue(formatDate(value));
          } }
        />
      </div>
    </InputLayout>
  );
};

export default DateInput;
