import Endorsements from "../../../images/icons/copy.svg";
import Claims from "../../../images/icons/file-minus.svg";
import Quotations from "../../../images/icons/file.svg";
import Team from "../../../images/icons/grid.svg";
import Home from "../../../images/icons/home.svg";
import Products from "../../../images/icons/package.svg";
import Policies from "../../../images/icons/pocket.svg";
import Reports from "../../../images/icons/trending-up.svg";
import Clients from "../../../images/icons/users.svg";

export const corporate = [{ label: "Home", icon: Home, link: "/" }];
export const operations = [{ label: "Quotations", icon: Quotations, link: "/quotations" },
  { label: "Policies", icon: Policies, link: "/policies" },
  { label: "Endorsements", icon: Endorsements, link: "/endorsements" },
  { label: "Claims", icon: Claims, link: "/claims" }
];
export const productManagement = [{ label: "Products", icon: Products, link: "/products" }];
export const clients = [{ label: "Clients", icon: Clients, link: "/clients" }];
export const team = [{ label: "Team", icon: Team, link: "/team" }];
export const reports = [{ label: "Reports", icon: Reports, link: "/reports" }];

export const insurers = [{ label: "Insurers", icon: Reports, link: "/insurers" }];
