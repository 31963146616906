import Button from "../../../../components/Button";
import NewTab from "../../../../images/icons/new_tab.svg";

export const cardData = [
  {
    title: "GWP this month",
    value: "2,500,000",
    percentage: 40,
    increased: true,
    showStat: true
  },
  {
    title: "Expiring in next 7 days",
    value: "32",
    showStat: false
  },
  {
    title: "Expiring next month",
    value: "3",
    showStat: false
  }
]
;

export const tabs = [
  {
    listSubTitle: "",
    tab: "policy",
    tabTitle: "Policies"
  },
  {
    listSubTitle: "Pending Premium Collections",
    tab: "collections",
    tabTitle: "Policy Collections"
  },
  {
    listSubTitle: "Pending Premium Settlements",
    tab: "settlements",
    tabTitle: "Policy Settlements"
  }
];

export const policy = {
  headings:[
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Policy",
      accessor: "policy"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "GWP (LKR)",
      accessor: "gwp"
    },
    {
      Header: "Commission %",
      accessor: "commission"
    },
    {
      Header: "Net Commision",
      accessor: "netCommision"
    },
    {
      Header: "Renewal Date",
      accessor: "renewalDate"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "WORKMENS COMPENSATION",
      type: "New Business",
      gwp: "3,530,890", 
      commission: "10%",  
      netCommision: "15,000",
      renewalDate: "31-05-2022",
      cta: "policy"
    },
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "CORPORATE TRAVEL",
      type: "New Business",
      gwp: "3,530,890", 
      commission: "10%",  
      netCommision: "15,000",
      renewalDate: "31-05-2022",
      cta: "policy"
    },
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "INDDOR MEDICAL PROGRAMME",
      type: "New Business",
      gwp: "3,530,890", 
      commission: "10%",  
      netCommision: "15,000",
      renewalDate: "31-05-2022",
      cta: "policy"
    },
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "GROUP PERSONAL ACCIDENT",
      type: "New Business",
      gwp: "3,530,890", 
      commission: "10%",  
      netCommision: "15,000",
      renewalDate: "31-05-2022",
      cta: "policy"
    },
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "GROUP TERM LIFE ASSURANCE",
      type: "New Business",
      gwp: "3,530,890", 
      commission: "10%",  
      netCommision: "15,000",
      renewalDate: "31-05-2022",
      cta: "policy"
    },
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "WORKMENS COMPENSATION",
      type: "New Business",
      gwp: "3,530,890", 
      commission: "10%",  
      netCommision: "15,000",
      renewalDate: "31-05-2022",
      cta: "policy"
    },
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "GROUP TERM LIFE ASSURANCE",
      type: "New Business",
      gwp: "3,530,890", 
      commission: "10%",  
      netCommision: "15,000",
      renewalDate: "31-05-2022",
      cta: "policy"
    },
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "WORKMENS COMPENSATION",
      type: "New Business",
      gwp: "3,530,890", 
      commission: "10%",  
      netCommision: "15,000",
      renewalDate: "31-05-2022",
      cta: "policy"
    },
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "INDDOR MEDICAL PROGRAMME",
      type: "New Business",
      gwp: "3,530,890", 
      commission: "10%",  
      netCommision: "15,000",
      renewalDate: "31-05-2022",
      cta: "policy"
    },
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "WORKMENS COMPENSATION",
      type: "New Business",
      gwp: "3,530,890", 
      commission: "10%",  
      netCommision: "15,000",
      renewalDate: "31-05-2022",
      cta: "policy"
    },
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "WORKMENS COMPENSATION",
      type: "New Business",
      gwp: "3,530,890", 
      commission: "10%",  
      netCommision: "15,000",
      renewalDate: "31-05-2022",
      cta: "policy"
    }
  ]
};

export const collections = {
  headings:[
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Policy",
      accessor: "policy"
    },
    {
      Header: "Issued Date",
      accessor: "issuedDate"
    },
    {
      Header: "GWP (LKR)",
      accessor: "gwp"
    },
    {
      Header: "Due Date",
      accessor: "dueDate"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "WORKMENS COMPENSATION",
      issuedDate: "20-07-2022",
      gwp: "150,000", 
      dueDate: "20-09-2022", 
      status: <span className="pending">pending</span>,
      cta: "policy collections"
    },
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "WORKMENS COMPENSATION",
      issuedDate: "20-07-2022",
      gwp: "150,000", 
      dueDate: "20-09-2022", 
      status: <span className="received">received</span>,
      cta: "policy collections"
    }
  ]
};

export const settlements = {
  headings:[
    {
      Header: "Insured",
      accessor: "insured"
    },
    {
      Header: "Policy",
      accessor: "policy"
    },
    {
      Header: "Issued Date",
      accessor: "issuedDate"
    },
    {
      Header: "GWP (LKR)",
      accessor: "gwp"
    },
    {
      Header: "Due Date",
      accessor: "dueDate"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "cta"
    }
  ],
  body:[
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "WORKMENS COMPENSATION",
      issuedDate: "20-07-2022",
      gwp: "150,000", 
      dueDate: "20-09-2022", 
      status: <span className="received">received</span>,
      cta: "policy settlements"
    },
    {
      insured:  "Dialog Axiata PLC (DAP)...", 
      policy: "WORKMENS COMPENSATION",
      issuedDate: "20-07-2022",
      gwp: "150,000", 
      dueDate: "20-09-2022", 
      status: <span className="pending">pending</span>,
      cta: "policy settlements"
    }
  ]
};

