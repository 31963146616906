import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import s from "./index.module.scss";
import { body, headings, tabs } from "./utils/data";
import Button from "../../../components/Button";
import RadioInput from "../../../components/Inputs/RadioInput";
import SearchInput from "../../../components/Inputs/SearchInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import TextInput from "../../../components/Inputs/TextInput";
import PageSubNav from "../../../components/PageSubNav";
import PageTitle from "../../../components/PageTitle";
import ReactTable from "../../../components/TableComponent";

const InsurersPage = () => {
  const navigate = useNavigate();
  const [type] = useQueryParam("type", StringParam);
  const [homeTab, setHomeTab] = useState(false);

  useEffect(()=>{
    if(type === "all insurers" || type === undefined){
      setHomeTab(true);
    }else{
      setHomeTab(false);
    }
  }, [type]);

  return (
    <div className="body-two">
      <div className="mb-4">
        <PageTitle title="Products"/>
      </div>

      <div className="mb-4">
        <PageSubNav list={ tabs.map(item => ({ label: item.tabTitle, value: item.tab })) }/>
      </div>

      { /* TABLE FILTER */ }
      {
        homeTab ?
          <AllClients headings={ headings } body = { body } navigate={ navigate }/>
          :
          <CreateClient navigate={ navigate }/>
      }
    </div>
  );
};

const AllClients = ({ headings, body, navigate })=> {
  return(
    <div>
      <div className="mb-4 row align-items-end justify-content-end">
        <div className="col-2 pe-0">
          <Button 
            icon="plus" 
            text="Add new insurer" 
            className="btn-primary ms-auto" 
            handler={ () => {
              navigate("/insurers?type=create+insurer");
            } }
          />
        </div>
        <div className="col-2">
          <SearchInput placeholder="Search Insurers" inline={ false }/>
        </div>
      </div>
      <ReactTable columns={ headings } data={ body } toggle={ true } edit={ true }/>
    </div>
  );
};

const CreateClient = ({ navigate })=> {
  const options=[
    {
      key: "",
      value: "Select responsible person"
    },
    {
      key: "one",
      value: "One"
    },
    {
      key: "two",
      value: "Two"
    }
  ];

  const radioOptions = [
    {
      key: "Yes",
      value: "Yes"
    },
    {
      key: "No",
      value: "No"
    }
  ];
  return(
    <div className="">
      <div className="mb-5">
        <Button text="back" icon="back" handler={ ()=>navigate(-1) }/>
      </div>
      <form action="" className={ `${s.create_client_form} ps-4 ms-4` }>
        <div className="mb-2">
          <TextInput label="Client name" type="text" inline={ true } placeholder="Dialog PLC"/>
        </div>
        <div className="mb-2">
          <RadioInput label="Master Client"  inline={ true } options={ radioOptions }/>
        </div>
        <div className="mb-2"> 
          <TextInput label="Summary" type="text" inline={ true } placeholder="Telecommunication Company"/>
        </div>
        <div className="mb-2"> 
          <TextInput label="Description" type="text" inline={ true } 
            placeholder="Dialog is sri lanka’s largest telecommunication company. "
          />
        </div>
        <div className="mb-2"> 
          <TextInput label="Nature of business" type="text" inline={ true } placeholder="Corporate"/>
        </div>
        <div className="mb-2">
          <RadioInput label="VAT Registered"  inline={ true } options={ radioOptions }/>
        </div>
        <div className="mb-2">
          <RadioInput label="SVAT Registered"  inline={ true } options={ radioOptions }/>
        </div>
        <div className="mb-2"> 
          <SelectInput label="Responsible Person" options={ options } inline={ true }/>
        </div>
        <div className="mb-2"> 
          <TextInput label="Client Address" type="text" inline={ true } placeholder="Please enter"/>
        </div>
        <div className="mb-2"> 
          <TextInput label="Primary Contact Person Name" type="text" inline={ true } placeholder="Please enter"/>
        </div>
        <div className="mb-2"> 
          <TextInput label="Primary Contact Person Email" type="text" inline={ true } placeholder="Please enter"/>
        </div>
        <div className="mb-2"> 
          <TextInput label="Primary Contact Person Phone" type="text" inline={ true } placeholder="Please enter"/>
        </div>

        <div className="d-flex justify-content-end mt-4">
          <Button text="cancel" className="btn-secondary me-2"/>
          <Button text="save" type="submit" className="btn-primary"/>
        </div>
      </form>
    </div>
  );
};

export default InsurersPage;
