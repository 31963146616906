import React from "react";
import {  BiSearch } from "react-icons/bi";
import { BsCheck2,BsCheck2Circle, BsShieldCheck } from "react-icons/bs";
import { FaRegPaperPlane } from "react-icons/fa";
import { FiArrowLeft, FiArrowRight, FiEdit3, FiRefreshCw, FiSave } from "react-icons/fi";
import { IoIosAdd,IoMdClose } from "react-icons/io";


const Button = ({ 
  text = "", 
  icon = "", 
  className="", 
  progress = false, 
  type = "button", 
  disabled = false, 
  handler = ()=>{},
  success = false }) => {

  return (
    <button className={ `btn ${className}` } type={ type } disabled={ progress || disabled || success } onClick={ ()=>handler() }>
      { icon !== "" ? <Icon icon={ icon }/> : "" }
      <p className="mb-0">
        { text }
      </p>
    </button>
  );
  
};

export default Button;

const Icon = ({ icon = "" }) => {

  switch (icon) {
  case "search":
    return  <span><BiSearch/></span>;
  case "plus":
    return  <span><IoIosAdd/></span>;
  case "edit":
    return  <span><FiEdit3/></span>;
  case "check-circ":
    return  <span><BsCheck2Circle/></span>;
  case "check":
    return  <span><BsCheck2/></span>;
  case "close":
    return  <span><IoMdClose/></span>;
  case "back":
    return  <span><FiArrowLeft/></span>;
  case "next":
    return  <span style={ { order: 2 } }><FiArrowRight/></span>;
  case "save":
    return  <span><FiSave/></span>;
  case "check-shield":
    return  <span><BsShieldCheck/></span>;
  case "refresh":
    return  <span><FiRefreshCw/></span>;
  case "paper-plane":
    return  <span><FaRegPaperPlane/></span>;
  default:
    return  null;
  }

};
